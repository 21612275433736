import {LOAD_SERVICE_REQUESTS_FOR_APPROVAL, REMOVE_SERVICE_REQUEST} from "./actions";


export const serviceRequestsForApproval = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_SERVICE_REQUESTS_FOR_APPROVAL:
            return {...payload}

        case REMOVE_SERVICE_REQUEST:
            return {...state, results: state.results.filter(serviceRequest => serviceRequest.id !== payload.id)}

        default:
            return state;
    }
}