import React from "react";

const PerdiemWorkPlanTable = ({perdiemWorkPlans}) => {
  return (
    <table className="table table-hover">
      <thead>
        <tr>
          <th>Date</th>
          <th>Activity</th>
          <th>Remarks</th>
        </tr>
      </thead>
      <tbody>
        {perdiemWorkPlans && perdiemWorkPlans.map((workPlan, key) => (
          <tr key={key}>
            <td>
              <h6 className="m-0">
                {new Date(workPlan.date).toLocaleDateString("en-UK")}
              </h6>
            </td>
            <td>
              <h6 className="m-0">{workPlan.activity}</h6>
            </td>
            <td>
              <h6 className="m-0">{workPlan.remarks}</h6>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PerdiemWorkPlanTable;
