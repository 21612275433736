import React, { Suspense } from "react";
import NavTabs from "../../../../components/tabs/NavTabs";
import NavTab from "../../../../components/tabs/NavTab";
import TabContent from "../../../../components/tabs/TabContent";
import TabPane from "../../../../components/tabs/TabPane";
import ServiceRequestApprovalsTable from '../tables/ApprovedServiceRequestsTable';
import ApproveServiceRequestsTable from '../tables/ApproveServiceRequestsTable';

const ServiceRequestsApprovalTab = () => {
  return (
    <>
      <NavTabs>
        <NavTab name={"pending-approval"} color="blue" show={true}>
          Pending Approval
        </NavTab>
        <NavTab name={"approved"} color="green">
          Approval History
        </NavTab>
      </NavTabs>
      <TabContent>
        <TabPane name={"pending-approval"} show={true}>
           <Suspense fallback={<div>Loading Service Requests ...</div>}>
              <ApproveServiceRequestsTable/>
          </Suspense>
        </TabPane>
        <TabPane name={"approved"}>
           <Suspense fallback={<div>Loading Service Requests ...</div>}>
              <ServiceRequestApprovalsTable/>
          </Suspense>
        </TabPane>
      </TabContent>
    </>
  );
};

export default ServiceRequestsApprovalTab;
