export const LOAD_PERDIEM_REQUEST_DETAIL = "LOAD_PERDIEM_REQUEST_DETAIL"
export const loadPerdiemRequestDetailAction = perdiemRequest => ({
    type: LOAD_PERDIEM_REQUEST_DETAIL,
    payload: perdiemRequest})


export const REMOVE_PERDIEM_REQUEST_DETAIL = "REMOVE_PERDIEM_REQUEST_DETAIL"
export const removePerdiemRequestDetailAction = perdiemRequest => ({
    type: LOAD_PERDIEM_REQUEST_DETAIL,
    payload: {}
})

