import { createSelector } from "reselect";


export const getUserServiceRequests = (state) => state.serviceRequests;

export const getPendingUserServiceRequests = createSelector(
  getUserServiceRequests,
  (serviceRequests) =>
    serviceRequests.filter(
        (serviceRequest) => serviceRequest.ceo_approval === "Pending")
);

export const getApprovedUserServiceRequests = createSelector(
  getUserServiceRequests,
  (serviceRequests) =>
    serviceRequests.filter(
        (serviceRequest) => serviceRequest.ceo_approval === "Approved")
);

export const getRejectedUserServiceRequests = createSelector(
  getUserServiceRequests,
  (serviceRequests) =>
    serviceRequests.filter(
        (serviceRequest) => serviceRequest.ceo_approval === "Rejected")
);
