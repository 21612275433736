import React, {useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { downloadPerdiemRequestsCSV } from "../../store/perdiemRequestsForPayment/thunks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



const FilterForm = () =>  {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    
    const {        
        handleSubmit,
        register,
    } = useForm();
    const history = useHistory()

    const dispatch = useDispatch();  
    
      
    const onSubmit = (data) => {
        console.log("Form Data: ", data)
        dispatch(downloadPerdiemRequestsCSV(data));
        history.push("/pay_perdiem");
      
    };
    
    return (
        <div className="card">
            <div className="card-header">
            <h5>Filter</h5>
            </div>
            <div className="card-block">
                <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                    <div className="form-group col-md-4">
                        <label>From Date</label>        
                        <DatePicker 
                        className="form-control"
                        placeholder="From Date"            
                        selected={startDate}                         
                        onChange={(date) => setStartDate(date)}
                        {...register("start_date")}  
                        />               
                        
                    </div>
                    <div className="form-group col-md-4">
                        <label>To Date</label>        
                        <DatePicker 
                        className="form-control"
                        placeholder="To Date"              
                        selected={endDate} 
                        onChange={(date) => setEndDate(date)}
                        {...register("end_date")}
                        />                        
                    </div>

                    <div className="col-md-12">
                        <button type="submit" className="btn btn-primary">
                            Export CSV
                        </button>
                    </div>

                </form>                
            </div>
        </div>
    )

}

export default FilterForm