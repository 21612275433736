import React from "react";
import { RotatingLines } from 'react-loader-spinner';

const CardWithLoadingSpinner = ({title}) => {
  return (
    <div className="card">
      <div className="card-header">
        <h5>{title}</h5>
      </div>
      <div className="card-block">
        <div className="row d-flex align-items-center">
          <div style={{ display: "block", width: 100, margin: "auto" }}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWithLoadingSpinner;
