export const CREATE_PERDIEM_WORKPLAN = 'CREATE_PERDIEM_WORKPLAN';
export const createPerdiemWorkPlanAction = perdiemWorkPlan =>({
    type: CREATE_PERDIEM_WORKPLAN,
    payload: {perdiemWorkPlan}
});

export const LOAD_PERDIEM_WORKPLANS = "LOAD_PERDIEM_WORKPLANS"
export const loadPerdiemWorkPlansAction = perdiemWorkPlans => ({
   type: LOAD_PERDIEM_WORKPLANS,
   payload: perdiemWorkPlans
})

