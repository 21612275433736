import React, { Suspense } from "react";
import NavTabs from "../../../../components/tabs/NavTabs";
import NavTab from "../../../../components/tabs/NavTab";
import TabContent from "../../../../components/tabs/TabContent";
import TabPane from "../../../../components/tabs/TabPane";
import PayServiceRequestsTable from '../tables/PayServiceRequestsTable';
import PaidServiceRequestsTable from '../tables/PaidServiceRequestsTable';

const ServiceRequestsForPaymentTab = () => {
  return (
    <>
      <NavTabs>
        <NavTab name={"pending-payment"} color="blue" show={true}>
          Pending Payment
        </NavTab>
        <NavTab name={"paid"} color="green">
          Paid
        </NavTab>
      </NavTabs>
      <TabContent>
        <TabPane name={"pending-payment"} show={true}>
           <Suspense fallback={<div>Loading Service Requests ...</div>}>
              <PayServiceRequestsTable/>
          </Suspense>
        </TabPane>
        <TabPane name={"paid"}>
           <Suspense fallback={<div>Loading Service Requests ...</div>}>
              <PaidServiceRequestsTable/>
          </Suspense>
        </TabPane>
      </TabContent>
    </>
  );
};

export default ServiceRequestsForPaymentTab;
