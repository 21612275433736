import axiosInstance from "../../utils/axiosInstance"
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import { loadProjectsAction } from "./actions";

export const loadProjects = () => (dispatch)=>{
   dispatch(loadingStartedAction())
   axiosInstance.get("/api/integrations/projects").then(
       res => {
           if(res.status===200) dispatch(loadProjectsAction(res.data));
           dispatch(loadingEndedAction())
       }
   )
}
