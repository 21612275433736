import React from "react";
import { useForm } from "react-hook-form";
import { NotificationContainer } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGMUsers } from "../../../../store/GMusers/selectors";
import { getProjects } from "../../../../store/projects/selectors";
import { getWorkers } from "../../../../store/workers/selectors";
import { createItemRequest } from "../../../../store/itemRequests/thunks";
import SelectItemToRequestForm from "./SelectItemToRequestField";
import { toFormData } from "../../../../utils/toFormData";

const ItemRequestForWorkerForm = () => {
  const dispatch = useDispatch();
  const GMusers = useSelector(getGMUsers);
  const projects = useSelector(getProjects);
  const workers = useSelector(getWorkers);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const onSubmit = (data) => {
    const file = data.evidence[0];
    const form_data = toFormData(data);
    file ? form_data.append("evidence", file) : form_data.delete("evidence");
    dispatch(createItemRequest(form_data));
    history.push("/all_item_requests");
  
  };

  const optionChange = (itemOption) => {
    setValue("item", itemOption.value, { shouldValidate: true });
  };

  return (
    <>
      <SelectItemToRequestForm optionChange={optionChange} />
      <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
        <div className="form-group col-md-12">
          <input {...register("item", { required: true })} hidden />
          {errors.item && (
            <small className="error">Please select the item</small>
          )}
        </div>
        <div className="form-group col-md-12">
          <label>Worker</label>
          <select
            className="form-control"
            {...register("worker", { required: true })}
          >
            <option value="">-- SELECT WORKER ---</option>
            {workers.length > 0 &&
              workers.map((item, key) => (
                <option value={item.id} key={key}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group col-md-12">
          <label>Purpose</label>
          <textarea
            className="form-control"
            placeholder="Enter purpose"
            {...register("purpose", { required: true })}
          ></textarea>
          {errors.purpose && (
            <small className="error">Please enter the purpose</small>
          )}
        </div>
        <div className="form-group col-md-4">
          <label>Project</label>
          <select
            className="form-control"
            {...register("project", { required: true })}
          >
            <option value="">-- SELECT PROJECT ---</option>
            {projects.length > 0 &&
              projects.map((item, key) => (
                <option value={item.id} key={key}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group col-md-4">
          <label>Segment</label>
          <input
            className="form-control"
            placeholder="Enter Segment"
            {...register("segment")}
          />
        </div>
        <div className="form-group col-md-4">
          <label for="formFile" class="form-label">
            Evidence
          </label>
          <input
            class="form-control"
            accept=".jpg,.pdf,.png, .jpeg"
            type="file"
            {...register("evidence")}
          ></input>
        </div>

        <div className="form-group col-md-6">
          <label>Quantity</label>
          <input
            type={"number"}
            className="form-control"
            {...register("quantity", { required: true, min: 1 })}
          />
          {errors.quantity && (
            <small className="error">Please enter quantity</small>
          )}
        </div>
        <div className="form-group col-md-6">
          <label>Business Unit GM</label>
          <select
            className="form-control"
            {...register("business_unit_gm", { required: true })}
          >
            <option value="">-- SELECT GM ---</option>
            {GMusers &&
              GMusers.map((item, key) => (
                <option value={item.id} key={key}>
                  {item.first_name} {item.last_name}
                </option>
              ))}
          </select>
          {errors.business_unit_gm && (
            <small className="error">Please select your business unit GM</small>
          )}
        </div>

        <div className="col-md-12">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
        <NotificationContainer />
      </form>
    </>
  );
};

export default ItemRequestForWorkerForm;
