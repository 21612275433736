import React, { Suspense } from "react";
import NavTabs from "./NavTabs";
import NavTab from "./NavTab";
import TabContent from "./TabContent";
import TabPane from "./TabPane";
import PerdiemRequestApprovalsTable from '../tables/ApprovedPerdiemRequestsTable';
import ApprovePerdiemRequestsTable from '../tables/ApprovePerdiemRequestsTable';

const PerdiemRequestsForPaymentTab = () => {
  return (
    <>
      <NavTabs>
        <NavTab name={"pending-approval"} color="blue" show={true}>
          Pending Approval
        </NavTab>
        <NavTab name={"approved"} color="green">
          Approval History
        </NavTab>
      </NavTabs>
      <TabContent>
        <TabPane name={"pending-approval"} show={true}>
           <Suspense fallback={<div>Loading Perdiem Requests ...</div>}>
              <ApprovePerdiemRequestsTable/>
          </Suspense>
        </TabPane>
        <TabPane name={"approved"}>
           <Suspense fallback={<div>Loading Perdiem Requests ...</div>}>
              <PerdiemRequestApprovalsTable/>
          </Suspense>
        </TabPane>
      </TabContent>
    </>
  );
};

export default PerdiemRequestsForPaymentTab;
