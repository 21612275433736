import {LOAD_ITEM_REQUESTS_FOR_APPROVAL, REMOVE_ITEM_REQUEST_FOR_APPROVAL} from "./actions";


export const itemRequestsForApproval = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_ITEM_REQUESTS_FOR_APPROVAL:
            return {...payload}

        case REMOVE_ITEM_REQUEST_FOR_APPROVAL:
            return {...state, results: state.results.filter(itemRequest => itemRequest.id !== payload.id)}
        default:
            return state;
    }
}