import axiosInstance from "../../utils/axiosInstance"
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import { loadWorkersAction } from "./actions";

export const loadWorkers = () => (dispatch)=>{
   dispatch(loadingStartedAction())
   axiosInstance.get("/api/integrations/workers").then(
       res => {
           if(res.status===200) dispatch(loadWorkersAction(res.data));
           dispatch(loadingEndedAction());
       }
   )
}