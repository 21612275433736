
import { LOAD_PERDIEM_REQUEST_APPROVALS } from './actions';



export const perdiemRequestApprovals = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_PERDIEM_REQUEST_APPROVALS:
            return {...payload}
        default: 
            return state;
    }
}