import axiosInstance from "../../utils/axiosInstance";
import { loadingStartedAction, loadingEndedAction } from "../isLoading/actions";
import { loadUserAction } from "./actions";

export const loadUser = () => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance
    .get("/accounts/profile")
    .then((res) => {
      if (res.status === 200) dispatch(loadUserAction(res.data));
      dispatch(loadingEndedAction());
    })
    
};
