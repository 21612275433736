import React from "react";
import { Link, useHistory } from "react-router-dom";
import CashRequestForm from "./components/forms/CashRequestForm";
import useFetching from "../../hooks/useFetching";
import { loadProjects } from "../../store/projects/thunks";
import { loadGMUsers } from "../../store/GMusers/thunks";
import { toFormData } from "../../utils/toFormData";
import { useDispatch } from "react-redux";
import { createCashRequest } from "../../store/cashRequests/thunks";

const NewCashRequest = () => {
  useFetching(loadProjects);
  useFetching(loadGMUsers);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data) => {
    const file = data.evidence[0];
    const form_data = toFormData(data);
    file ? form_data.append("evidence", file) : form_data.delete("evidence");

    dispatch(createCashRequest(form_data, history));
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="page-header-title">
          <h5 className="m-b-10">Create New Cash Request</h5>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="feather icon-home"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">Create New Cash Request</li>
          </ul>
        </div>
        <div className="card">
          <div className="card-body">
            <CashRequestForm onSubmit={onSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCashRequest;
