
import axiosInstance from '../../utils/axiosInstance';
import { loadingEndedAction, loadingStartedAction } from '../isLoading/actions';
import { loadServiceRequestApprovalsAction } from './actions';

export const loadServiceRequestApprovals =
  (page = 0) =>
  (dispatch) => {
    dispatch(loadingStartedAction());
    const uri = "/perdiem_api/service_request_approvals";
    const endpoint = page ? `${uri}?page=${page + 1}` : uri;

    axiosInstance
      .get(endpoint)
      .then((res) => {
        if (res.status === 200)
          dispatch(loadServiceRequestApprovalsAction(res.data));
        dispatch(loadingEndedAction());
      })
      .catch((err) => {
        dispatch(loadingEndedAction());
      });
  };