import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCashRequestsForPayment,
} from "../../../../store/cashRequestsForPayment/thunks";
import Pagination from "../../../../components/Pagination";
import useFetching from '../../../../hooks/useFetching';
import { getCashRequestsForPayment } from '../../../../store/cashRequestsForPayment/selectors';


const PayCashRequestsTable = () => {
  useFetching(loadCashRequestsForPayment);
  const cashRequests = useSelector(getCashRequestsForPayment);
  const dispatch = useDispatch();
  const resultsCount = useSelector(
    (state) => state.cashRequestsForPayment.count
  );
  const handlePaginationChange = (e) => {
    dispatch(loadCashRequestsForPayment(e.selected));
  };


  return cashRequests?(
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Beneficiary</th>
            <th>Phone Number</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {cashRequests.map((cashRequest, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{cashRequest.id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(cashRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>{cashRequest.name}</td>
              <td>{cashRequest.phone_number}</td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: cashRequest.currency,
                  }).format(parseInt(cashRequest.amount))}
                </h6>
              </td>
              <td>
                <Link
                  to={"/pay_cash_request/" + cashRequest.id}
                  className="label theme-bg text-white f-12"
                >
                  Mark as Paid
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        resultsCount={resultsCount}
        handlePageClick={handlePaginationChange}
      />
    </div>
  ):(<div>No cash requests Available</div>);
};

export default PayCashRequestsTable;
