import React from "react";
import RejectedIcon from "../../../../components/RejectedIcon";
import PendingIcon from "../../../../components/PendingIcon";
import ApprovedIcon from "../../../../components/ApprovedIcon";


const PendingServiceRequestsTable = ({ serviceRequests }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Service</th>
            <th>Quantity</th>
            <th>Currency</th>
            <th>GM</th>
            <th>CFO</th>
            <th>CEO</th>
          </tr>
        </thead>
        <tbody>
          {serviceRequests.map((serviceRequest, key) => (
            <tr>
              <td>
                {serviceRequest.id}
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(serviceRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>
                <h6 className="m-0">
                  {serviceRequest.service}
                </h6>
              </td>
              <td>
                <h6 className="m-0">
                  {serviceRequest.quantity}
                </h6>
              </td>
              <td>
                <h6 className="m-0">
                  {serviceRequest.currency}
                </h6>
              </td>
              <td>
                {serviceRequest.business_unit_gm_approval === "Rejected" && (
                 <RejectedIcon/>
                )}
                {serviceRequest.business_unit_gm_approval === "Pending" && (
                  <PendingIcon/>
                )}
                {serviceRequest.business_unit_gm_approval === "Approved" && (
                  <ApprovedIcon/>
                )}
              </td>
              <td>
                {serviceRequest.cfo_approval === "Rejected" && (
                  <RejectedIcon/>
                )}
                {serviceRequest.cfo_approval === "Pending" && (
                  <PendingIcon/>
                )}
                {serviceRequest.cfo_approval === "Approved" && (
                  <ApprovedIcon/>
                )}
              </td>
              <td>
                {serviceRequest.ceo_approval === "Rejected" && (
                  <RejectedIcon/>
                )}
                {serviceRequest.ceo_approval === "Pending" && (
                  <PendingIcon/>
                )}
                {serviceRequest.ceo_approval === "Approved" && (
                  <ApprovedIcon/>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PendingServiceRequestsTable;
