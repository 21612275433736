import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useFetching = (someFetchActionCreator, arg="") => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(someFetchActionCreator(arg));
  },[dispatch,someFetchActionCreator, arg])
}

export default useFetching;