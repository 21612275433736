import React from "react";
import { useDispatch } from "react-redux";
import { clearNotifications } from "../store/notifications/thunks";
import NotificationListItem from "./NotificationListItem";

const NotificationCard = ({notifications}) => {
  const dispatch = useDispatch();
  const clearAll = ()=> {
    dispatch(clearNotifications());
  }

  return (
    <div className="dropdown-menu dropdown-menu-right notification">
      <div className="noti-head">
        <h6 className="d-inline-block m-b-0">Notifications</h6>
        <div className="float-right">
          <button className="text-btn" onClick={clearAll}>clear all</button>
        </div>
      </div>
      <ul className="noti-body">
        <li className="n-title">
          <p className="m-b-0">NEW</p>
        </li>
        {notifications &&
          notifications.map((notification, key) => (
            <NotificationListItem notification={notification} key={key} />
          ))}
      </ul>
    </div>
  );
};

export default NotificationCard;
