import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  loadPerdiemRequestDetail,
  removePerdiemRequestDetail,
} from "../store/perdiemRequestDetail/thunks";
import { getPerdiemRequestDetail } from "../store/perdiemRequestDetail/selectors";
import { RotatingLines } from "react-loader-spinner";

const PerdiemRequestDetails = () => {
  const dispatch = useDispatch()
  const { id } = useParams();

  useEffect(() => {
    dispatch(loadPerdiemRequestDetail(id));
    return dispatch(removePerdiemRequestDetail());
  }, [id, dispatch]);
    
  const perdiemRequest = useSelector(getPerdiemRequestDetail);

  console.log(perdiemRequest)
  return (
    
    <div className="col-xl-12 col-md-12 m-b-30">      
      {perdiemRequest.id ? (
        <>
      <div className="card">
        <div className="card-header">
          <h5>Perdiem Request</h5>
        </div>
        
        <div className="card-block">
          <div className="row d-flex align-items-center">
            <div className="col-auto">
              <i className="feather icon-user f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h3 className="f-w-300">{perdiemRequest.name}</h3>
              <span className="d-block text-uppercase">Beneficiary</span>
            </div>
            <div className="col-auto">
              <i className="feather icon-clipboard f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h4 className="f-w-300">{perdiemRequest.destination}</h4>
              <span className="d-block text-uppercase">Destination</span>
            </div>
            <div className="col-auto">
              <i className="feather icon-calendar f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h3 className="f-w-300">{
              new Date(
                perdiemRequest.travel_date
              ).toLocaleDateString()}</h3>
              
              <span className="d-block text-uppercase">Travel Date</span>
            </div>
          </div>
        </div>
        <div className="card-block border-bottom">
          <div className="row d-flex align-items-center">
            
            <div className="col-auto">
              <i className="feather icon-target f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h4 className="f-w-300">{(perdiemRequest.project_name) || "N/A"}</h4>
              <span className="d-block text-uppercase">Project</span>
            </div>
            <div className="col-auto">
              <i className="feather icon-link-2 f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h4 className="f-w-300">{perdiemRequest.segment || "N/A"}</h4>
              <span className="d-block text-uppercase">Segment</span>
            </div>
            <div className="col-auto">
              <i className="feather icon-message-square f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h6 className="f-w-300">{perdiemRequest.reason}</h6>
              <span className="d-block text-uppercase">Reason</span>
            </div>
          </div>
        </div>
        <div className="card-block border-bottom">
          <div className="row d-flex align-items-center">
            <div className="col-auto">
              <i className="feather icon-calendar f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h4 className="f-w-300">{perdiemRequest.no_of_days}</h4>
              <span className="d-block text-uppercase">No. of Days</span>
            </div>
            <div className="col-auto">
              <i className="feather icon-credit-card f-30 text-c-green"></i>
            </div>
            <div className="col">
            <h4 className="f-w-300">
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: perdiemRequest.currency,
                }).format(parseInt(perdiemRequest.daily_rate))}
              </h4>
              <span className="d-block text-uppercase">Daily Rate</span>
            </div>
            <div className="col-auto">
              <i className="feather icon-credit-card f-30 text-c-green"></i>
            </div>
            <div className="col">
            <h4 className="f-w-300">
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: perdiemRequest.currency,
                }).format(parseInt(perdiemRequest.accomodation_rate))}
              </h4>
              <span className="d-block text-uppercase">Accommodation Rate</span>
            </div>
          </div>
        </div>
        <div className="card-block border-bottom">
          <div className="row d-flex align-items-center">
            <div className="col-auto">
              <i className="feather icon-calendar f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h4 className="f-w-300">{perdiemRequest.no_of_nights}</h4>
              <span className="d-block text-uppercase">No. of Nights</span>
            </div>
            <div className="col-auto">
              <i className="feather icon-credit-card f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h4 className="f-w-300">
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: perdiemRequest.currency,
                }).format(parseInt(perdiemRequest.transport))}
              </h4>
              <span className="d-block text-uppercase">Transport</span>
            </div>
            <div className="col-auto">
              <i className="feather icon-dollar-sign f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h3 className="f-w-300">
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: perdiemRequest.currency,
                }).format(parseInt(perdiemRequest.total_amount))}
              </h3>
              <span className="d-block text-uppercase">Total Amount</span>
            </div>
          </div>
        </div>
        <div className="card-block border-bottom">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="feather icon-clock f-30 text-c-green"></i>
              </div>
                <div className="col">
                <h4 className="f-w-300">
                  {perdiemRequest.date
                    ? new Date(
                        perdiemRequest.date
                      ).toLocaleString()
                    : "N/A"}
                </h4>
                <span className="d-block text-uppercase">SUBMISSION TIME</span>
              </div>
              <div className="col">
                <h4 className="f-w-300">
                {perdiemRequest.business_unit_gm_name || "N/A"}
                </h4>
                <span>
                  GM NAME
                </span>                
              </div>
              <div className="col">
                <h4 className="f-w-300">
                  {perdiemRequest.business_unit_gm_approval_time
                    ? new Date(
                        perdiemRequest.business_unit_gm_approval_time
                      ).toLocaleString()
                    : "N/A"}
                </h4>
                <span className="d-block text-uppercase">GM APPROVAL TIME</span>
              </div>             
   
            </div>
        </div>   

    
          
      </div> 
      <div className="card">
        <div className="card-header">
          <h5>Work Plan</h5>
        </div>
        <div className="card-block">
          <div className="row d-flex align-items-center">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Activity</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                { perdiemRequest.work_plans && perdiemRequest.work_plans.map((plan, key) => (
                  <tr key={key}>
                    <td>
                      <h6 className="m-0">
                        { new Date(plan.date).toLocaleDateString("en-UK")}
                      </h6>
                    </td>
                    <td>
                      <h6 className="m-0">{plan.activity}</h6>
                    </td>
                    <td>
                      <h6 className="m-0">{plan.remarks}</h6>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
              
      </div>
    </>
    ) : (
      <div className="card">
      <div className="card-header">
        <h5>Perdiem Request ID {id}</h5>
      </div>
      <div className="card-block">
        <div className="row d-flex align-items-center">
          <div style={{ display: "block", width: 100, margin: "auto" }}>
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="100"
              visible={true}
            />
          </div>
        </div>
      </div>
    </div>
  )}
  </div>
      
  
  
  );
};

export default PerdiemRequestDetails;
