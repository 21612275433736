import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MainContent } from "../components/MainContent";
import SideBar from "../components/SideBar";
import ThemeLoader from "../components/ThemeLoader";
import TopBar from "../components/TopBar";
import useFetching from "../hooks/useFetching";
import { loadUser } from "../store/user/thunks";


const InsideLayout = ({ children, ...restProps }) => {
  const [isNavBarOpen, setIsNavBarOpen] = useState(false);
  const isLoading = useSelector(state=>state.isLoading);
  useFetching(loadUser);
  return (
    <>
      <ThemeLoader display={isLoading}/>
      <SideBar isNavBarOpen={isNavBarOpen} setIsNavBarOpen={setIsNavBarOpen}/>
      <TopBar setIsNavBarOpen={setIsNavBarOpen} isNavBarOpen={isNavBarOpen} />
      <MainContent>{children}</MainContent>
    </>
  );
};

export default InsideLayout;
