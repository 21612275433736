import { CREATE_PERDIEM_WORKPLAN, LOAD_PERDIEM_WORKPLANS } from "./actions";

export const perdiemWorkPlans = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case CREATE_PERDIEM_WORKPLAN:{
            const {perdiemWorkPlan} = payload;
            return [...state, perdiemWorkPlan]
        }
        case LOAD_PERDIEM_WORKPLANS:{
            return [...payload]
        }
        default:
            return state;
    }
}