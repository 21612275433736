import React from 'react'

export const MainContent = ({children}) => {
  return (
    <div className="pcoded-main-container">
        <div className="pcoded-wrapper">
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
        
                    <div className="main-body">
                        <div className="page-wrapper">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
