
import axiosInstance from '../../utils/axiosInstance';
import { loadingEndedAction, loadingStartedAction } from '../isLoading/actions';
import { loadTrackCashRequestApprovalsAction } from './actions';

export const loadTrackCashRequestApprovals =
  (page = 0, id) =>
  (dispatch) => {
    dispatch(loadingStartedAction());
    const uri = `api/track_cash_request_approvals/${id}`;
    const endpoint = page ? `${uri}?page=${page + 1}` : uri;

    axiosInstance
      .get(endpoint)
      .then((res) => {
        if (res.status === 200)
          dispatch(loadTrackCashRequestApprovalsAction(res.data));
        dispatch(loadingEndedAction());
      })
      .catch((err) => {
        console.log("Request Denied");
        dispatch(loadingEndedAction());
      });
  };