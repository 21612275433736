import React from "react";
import { Link } from "react-router-dom";

const PageTemplate = ({ title, children }) => {
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="page-header-title">
          <h5 className="m-b-10">{title}</h5>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">
                <i className="feather icon-home"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">{title}</li>
          </ul>
        </div>
        {children}
      </div>
    </div>
  );
};

export default PageTemplate;
