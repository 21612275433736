
import { LOAD_PERDIEM_REQUEST_APPROVAL_DETAILS } from './actions';



export const perdiemRequestApprovalDetails = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_PERDIEM_REQUEST_APPROVAL_DETAILS:
            return {...payload}
        default: 
            return state;
    }
}