import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  loadCashRequestDetail,
  removeCashRequestDetail,
} from "../../store/cashRequestDetail/thunks";
import { getCashRequestDetail } from "../../store/cashRequestDetail/selectors";
import ApprovalForm from "../../components/forms/ApprovalForm";
import { approveCashRequestThunk } from "../../store/cashRequestsForApproval/thunks";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CashRequestDetails from "./CashRequestDetails";

import { loadTrackCashRequestApprovals } from "../../store/trackCashRequestApprovals/thunks";
import { getTrackCashRequestApprovals } from "../../store/trackCashRequestApprovals/selectors";
import TrackCashRequestApprovalsCards from './components/cards/TrackCashRequestApprovalsCards';

const ApproveCashRequest = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory()

  const cashRequest = useSelector(getCashRequestDetail);

  const handleApprove = (data) => {
    dispatch(approveCashRequestThunk(cashRequest, data));
    history.push("/approve_cash_requests");
  };

  useEffect(() => {
    dispatch(loadCashRequestDetail(id));
    return dispatch(removeCashRequestDetail());
  }, [id, dispatch]);

  const cashRequestApprovals = useSelector(getTrackCashRequestApprovals);
  useEffect(() => {
    dispatch(loadTrackCashRequestApprovals(0, id));
  }, [id]);

  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Approve Cash Request</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/approve_cash_requests">Approve Cash Requests</Link>
          </li>
          <li className="breadcrumb-item">Approve Cash Request</li>
        </ul>
      </div>

      <CashRequestDetails />

      {cashRequestApprovals && cashRequestApprovals.length > 0 ? <TrackCashRequestApprovalsCards cashRequestApprovals={cashRequestApprovals}/>:
      <></>
      }    

      <div className="card">
        <div className="card-header">
          <h5>Approve/Reject Request</h5>
        </div>
        <div className="card-block">
          <ApprovalForm request={cashRequest} handleApprove={handleApprove}/>
        </div>
      </div>  
    </div>
  );
};

export default ApproveCashRequest;
