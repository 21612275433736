import { LOAD_GLACCOUNTS } from "./actions";

export const GLAccounts = (state = [], action) => {
  const { type, payload } = action;
  switch (type) {
    case LOAD_GLACCOUNTS: {
      return [...payload];
    }
    default:
      return state;
  }
};
