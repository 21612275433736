import React from "react";

const TabContent = ({children}) => {
  return (
    <div className="tab-content" id="myTabContent">
      {children}
    </div>
  );
};

export default TabContent;
