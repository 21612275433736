import React, {useState} from 'react'
import Pagination from '../../../../components/Pagination';
import { loadPaidCashRequests } from '../../../../store/paidCashRequests/thunks';
import useFetching from '../../../../hooks/useFetching';
import { useSelector } from 'react-redux';
import { getPaidCashRequests } from '../../../../store/paidCashRequests/selectors';
import { useDispatch } from 'react-redux';
import { downloadCashRequestPDF } from '../../../../store/cashRequestsForPayment/thunks';
import { Link } from 'react-router-dom';
import Form from "react-bootstrap/Form";

const PaidCashRequestsTable = () => {
  useFetching(loadPaidCashRequests);
  const cashRequests = useSelector(getPaidCashRequests);
  const dispatch = useDispatch();
  const resultsCount = useSelector(
    (state) => state.paidCashRequests.count
  );
  const handlePaginationChange = (e) => {
    dispatch(loadPaidCashRequests(e.selected));
  };

  const handleExportPDF = (cashRequest) => {
    dispatch(downloadCashRequestPDF(cashRequest));
  };
  
  const [search, setSearch] = useState('')

  return cashRequests?(
    <>
    <Form>
        <Form.Group className="form-group col-md-12"> 
          <Form.Control
            placeholder="Search by Beneficiary"        
            onChange={(e)=>setSearch(e.target.value)}
          />
        </Form.Group>
      </Form>  
    
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Beneficiary</th>
            <th>Phone Number</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {cashRequests.filter((cashRequest) => {
            return search.toLowerCase() === ''? cashRequest : 
            cashRequest.name.toLowerCase().includes(search);
          }).map((cashRequest, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{cashRequest.id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(cashRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>{cashRequest.name}</td>
              <td>{cashRequest.phone_number}</td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: cashRequest.currency,
                  }).format(parseInt(cashRequest.amount))}
                </h6>
              </td>
              <td>
                <Link
                  onClick={() => {
                    handleExportPDF(cashRequest);
                  }}
                  className="label theme-bg text-white f-12"
                >
                  Export PDF
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        resultsCount={resultsCount}
        handlePageClick={handlePaginationChange}
      />
    </div>
    </>
  ):(<div>No cash requests Available</div>);
}

export default PaidCashRequestsTable