import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  NotificationContainer,
} from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGMUsers } from "../../store/GMusers/selectors";
import { getProjects } from "../../store/projects/selectors";
import { createPerdiemRequest } from "../../store/perdiemRequests/thunks";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import "react-datepicker/dist/react-datepicker.css";
import { perdiemRequestSchema } from "../../utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";

const PerdiemRequestForm = ({
  perdiemRequest = {
    work_plans: [
      {
        "date": "",
        "activity": "",
        "remarks": ""
      }
    ],
  },
}) => {
  const GMusers = useSelector(getGMUsers);
  const projects = useSelector(getProjects);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    unregister,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: perdiemRequest,
    resolver: yupResolver(perdiemRequestSchema)
  });

  const [perdiemWorkPlanIndices, setPerdiemWorkPlanIndices] = useState(
    Object.keys(perdiemRequest.work_plans)
  );

  const addPerdiemWorkPlan = () => {
    setPerdiemWorkPlanIndices([
      ...perdiemWorkPlanIndices,
      perdiemWorkPlanIndices.length,
    ]);
  };

  const removePerdiemWorkPlan = (idx) => {
    if (perdiemWorkPlanIndices.length > 1) {
      setPerdiemWorkPlanIndices((current) =>

        current.filter((perdiemWorkPlanIndex) => perdiemWorkPlanIndex !== idx)
      );
      unregister(`work_plans[${idx}]`);
    }
  };

  const history = useHistory();

  const handleChange = (workPlan) => {
    setValue(`work_plans[${workPlan.id}][date]`, workPlan.value, {
      shouldValidate: true,
    });
    setValue(`work_plans[${workPlan.id}][activity]`, workPlan.value, {
      shouldValidate: true,
    });
    setValue(`work_plans[${workPlan.id}][remarks]`, workPlan.price, {
      shouldValidate: true,
    });
  };

  const payment_mode = watch("payment_mode")
  const category = watch("destination_category")

  const onSubmit = (formData) => {
    console.log("The data going into the form", formData);
    dispatch(createPerdiemRequest(formData, history));
  };



  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
      {console.log("The errors are here: ", errors)}
      <div className="form-group col-md-4">
        <label>Project</label>
        <select
          className="form-control"
          {...register("project", { required: true })}
        >
          <option value="">-- SELECT PROJECT ---</option>
          {projects.length > 0 &&
            projects.map((item, key) => (
              <option value={item.id} key={key}>
                {item.name}
              </option>
            ))}
        </select>
        {errors.project && (
          <small className="error">{errors.project.message}</small>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>Section</label>
        <input
          className="form-control"
          placeholder="Enter Section"
          {...register("section")}
        />
        {errors.section && (
          <small className="error">{errors.section.message}</small>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>Destination Category</label>
        <select
          className="form-control"
          {...register("destination_category", { required: true })}
        >
          <option value="">--- SELECT DESTINATION CATEGORY ---</option>
          <option value="1">
            Local (With in Uganda)
          </option>
          <option value="2">
            East Africa
          </option>
          <option value="3">
            Africa
          </option><option value="4">
            Others (UK, US, EUROPE and Asia)
          </option>
        </select>
        {errors.destination_category && (
          <small className="error">{errors.destination_category.message}</small>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>Destination</label>
        <input
          className="form-control"
          placeholder="Enter destination"
          {...register("destination", { required: true })}
        />
        {errors.destination && (
          <small className="error">Please enter the Destination</small>
        )}
      </div>

      <Form.Group className="form-group col-md-4">
        <Form.Label>Travel Date</Form.Label>
        <Form.Control
          type="date"
          placeholder="Date"
          {...register("date")}
          onValueChange={handleChange}
        />
        {errors.date && (
          <small className="error">{errors.date.message}</small>
        )}
      </Form.Group>
      <div className="form-group col-md-4">
        <label>No. of Days (Duration)</label>
        <input
          className="form-control"
          placeholder="Enter Duration"
          {...register("no_of_days", { required: true })}
        />
        {errors.no_of_days && (
          <small className="error">{errors.no_of_days.message}</small>
        )}
      </div>
      <div className="form-group col-md-12">
        <label>Reason for Travel</label>
        <textarea
          className="form-control"
          placeholder="Enter Reason for Travel"
          {...register("reason", { required: true })}
        ></textarea>
        {errors.reason && (
          <small className="error">{errors.reason.message}</small>
        )}
      </div>

      {category === "2" || category === "3" || category === "4" ? <>
        <div className="form-group col-md-4">
          <label>Accomodation Daily Rate</label>
          <input
            className="form-control"
            placeholder="Enter Accomodation Rate"
            {...register("accomodation_rate")}
          />
        </div>
        <div className="form-group col-md-4">
          <label>Transport</label>
          <input
            className="form-control"
            placeholder="Enter Transport"
            {...register("transport")}
          />
        </div>
        <div className="form-group col-md-4">
          <label>No. of Nights</label>
          <input
            className="form-control"
            placeholder="Enter Duration"
            {...register("no_of_nights")}
          />
        </div>
      </> :
        null
      }
      <div className="form-group col-md-4">
        <label>Payment Mode</label>
        <select
          name="payment_mode"
          className="form-control"

          {...register("payment_mode", { required: true })}
        >
          <option value="">--- SELECT PAYMENT MODE --- </option>
          <option value="Cash">Cash</option>
          <option value="MM">Mobile Money</option>
          <option value="Bank">Bank</option>
        </select>
        {errors.payment_mode && (
          <small className="error">{errors.payment_mode.message}</small>
        )}
      </div>
      {
        payment_mode === "Cash" ?
          <div className="form-group col-md-4">
            <label>Recieved By</label>
            <input
              className="form-control"
              placeholder="Enter Recieved By"
              {...register("recived_by", { required: true })}
            />
            {errors.recived_by && (
              <small className="error">Please enter the Recieved By</small>
            )}
          </div>
          :
          payment_mode === "MM" ?
            <div className="form-group col-md-4">
              <label>Phone Number</label>
              <input
                className="form-control"
                placeholder="Enter Phone Number"
                {...register("phone_number", { required: true })}
              />
              {errors.phone_number && (
                <small className="error">{errors.phone_number.message}</small>
              )}
            </div>
            : payment_mode === "Bank" ?
              <>
                <div className="form-group col-md-4">
                  <label>Bank Transfer</label>
                  <input
                    className="form-control"
                    placeholder="Enter Account Number"
                    {...register("account_no", { required: true })}
                  />
                  {errors.account_no && (
                    <small className="error">Please enter the Account No.</small>
                  )}
                </div>
                <div className="form-group col-md-4">
                  <label>Branch</label>
                  <input
                    className="form-control"
                    placeholder="Enter Branch"
                    {...register("branch", { required: true })}
                  />
                  {errors.branch && (
                    <small className="error">Please enter the branch</small>
                  )}
                </div>
              </>
              : null
      }
      <div className="form-group col-md-4">
        <label>Business Unit GM</label>
        <select
          className="form-control"
          {...register("business_unit_gm", { required: true })}
        >
          <option value="">-- SELECT GM ---</option>
          {GMusers &&
            GMusers.map((item, key) => (
              <option value={item.id} key={key}>
                {item.first_name} {item.last_name}
              </option>
            ))}
        </select>
        {errors.business_unit_gm && (
          <small className="error">Please select your business unit GM</small>
        )}
      </div>

      {/* Horizontal Rule */}
      <div className="form-group col-md-12">
        <hr className="horizontal-rule" />
        <span className="text">Work Plan</span>
        <hr className="horizontal-rule" />
      </div>

      {perdiemWorkPlanIndices.map((perdiemWorkPlanIndex, index) => (
        <React.Fragment key={perdiemWorkPlanIndex}>
          <Form.Group className="form-group col-md-3">
            <Form.Label>Date</Form.Label>
            <Form.Control
              type="date"
              placeholder="Date"
              {...register(`work_plans[${perdiemWorkPlanIndex}][date]`)}
              onValueChange={handleChange}
            />
           

          </Form.Group>
          <Form.Group className="form-group col-md-4">
            <Form.Label>Activity</Form.Label>
            <Form.Control
              placeholder="Activity"
              {...register(`work_plans[${perdiemWorkPlanIndex}][activity]`, {
                maxLength: {
                  value: 150,
                  message: "Too Many Characters",
                },
              })}
              onValueChange={handleChange}
            />
            {errors.segment && (
              <small className="error">{errors.segment.message}</small>
            )}
          </Form.Group>
          <Form.Group className="form-group col-md-4">
            <Form.Label>Remarks</Form.Label>
            <Form.Control
              placeholder="Remarks"
              {...register(`work_plans[${perdiemWorkPlanIndex}][remarks]`, {
                maxLength: {
                  value: 150,
                  message: "Too Many Characters",
                },
              })}
              onValueChange={handleChange}
            />
            {errors.segment && (
              <small className="error">{errors.segment.message}</small>
            )}
          </Form.Group>

          <Form.Group className="col-md-1 d-flex align-items-bottom">
            <Button
              variant="outline-danger"
              onClick={() => removePerdiemWorkPlan(perdiemWorkPlanIndex)}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </Button>
          </Form.Group>
        </React.Fragment>
      ))}

      <div className="col-md-12">
        <Button variant="outline-secondary" onClick={addPerdiemWorkPlan}>
          Add Work Plan
        </Button>{" "}
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
      <NotificationContainer />
    </form>
  );
};


export default PerdiemRequestForm;
