import axiosInstance from "../../utils/axiosInstance";
import { loadingStartedAction, loadingEndedAction } from "../isLoading/actions";
import {NotificationManager} from "react-notifications";
import { loadUserPerdiemRequestsAction, createPerdiemRequestAction } from "./actions";

export const loadUserPerdiemRequests = () => (dispatch)=>{
   axiosInstance.get("/perdiem_api/all_user_perdiem_requests").then(
       res => {
           if(res.status===200) 
           dispatch(loadUserPerdiemRequestsAction(res.data));
       }
   )
}

export const createPerdiemRequest = (perdiemRequest, history)=>(dispatch)=>{
    dispatch(loadingStartedAction());
    console.log("perdiem request from thunk", perdiemRequest)
    axiosInstance
    .post("/perdiem_api/perdiem_request", perdiemRequest)
    .then((res) => {
      dispatch(createPerdiemRequestAction(res.data));
      NotificationManager.success(
        `Perdiem Request ID ${res.data.id} successfully created`,
        "Perdiem Request Created"
      );
      history.push("/all_perdiem_requests");
      dispatch(loadingEndedAction())
    })
    .catch((err) => {
      console.log("Error: ", err)
      dispatch(loadingEndedAction())
    });
  }
