

export const LOAD_SERVICE_REQUESTS_FOR_APPROVAL = "LOAD_SERVICE_REQUESTS_FOR_APPROVAL"
export const loadServiceRequestsForApprovalAction = serviceRequests => ({
   type: LOAD_SERVICE_REQUESTS_FOR_APPROVAL,
   payload: serviceRequests
})

export const REMOVE_SERVICE_REQUEST = "REMOVE_SERVICE_REQUEST"
export const removeServiceRequest = serviceRequest => ({
   type: REMOVE_SERVICE_REQUEST,
   payload: serviceRequest
})