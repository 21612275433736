export const CREATE_SERVICE_REQUEST = 'CREATE_SERVICE_REQUEST';
export const createServiceRequestAction = serviceRequest =>({
    type: CREATE_SERVICE_REQUEST,
    payload: {serviceRequest}
});

export const LOAD_USER_SERVICE_REQUESTS = "LOAD_USER_SERVICE_REQUESTS"
export const loadUserServiceRequestsAction = serviceRequests => ({
   type: LOAD_USER_SERVICE_REQUESTS,
   payload: serviceRequests
})

