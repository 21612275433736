import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import OutsideLayout from "../layouts/OutsideLayout";
import { useToken } from "../hooks/useToken";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import Loader from "react-promise-loader";


const Authenticating = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get("code");
  const history = useHistory();
  const [, setToken] = useToken();

  useEffect(() => {
      trackPromise(
        axios.post(
          `${process.env.REACT_APP_BASE_URL}/accounts/login`,
          { code }
        )
      ).then(({data}) => {
        const { access_token, refresh_token } = data;
        localStorage.setItem("refreshToken", refresh_token);
        setToken(access_token);
        history.push("/");
      })
  });

  return (
    <OutsideLayout>
      <div className="card">
        <div className="card-body text-center">
          <div className="mb-4">
            <i className="feather icon-unlock auth-icon"></i>
          </div>
          <h3 className="mb-4">{process.env.REACT_APP_NAME}</h3>
          <p>Seems to be taking too long to authenticate...</p>
          <Loader promiseTracker={usePromiseTracker} />
          <Link to="/login/">
            <button className="btn btn-primary shadow-2 mb-4">Try Again</button>
          </Link>
        </div>
      </div>
    </OutsideLayout>
  );
};

export default Authenticating;
