import axiosInstance from "../../utils/axiosInstance";
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import { loadGLAccountsAction } from "./actions";

export const loadGLAccounts = () => (dispatch) => {
    dispatch(loadingStartedAction())
    axiosInstance.get("/api/gl_accounts").then((res) => {
      if (res.status === 200) dispatch(loadGLAccountsAction(res.data.results));
      dispatch(loadingEndedAction())
    });
  }