import {  LOAD_PAID_PERDIEM_REQUESTS, LOAD_PAID_PERDIEM_REQUESTS_CSV } from "./actions";

export const paidPerdiemRequests = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_PAID_PERDIEM_REQUESTS:{
            return {...payload}
        }
        default:
            return state;
    }
}

export const paidPerdiemRequestsCsv = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_PAID_PERDIEM_REQUESTS_CSV:{
            return {...payload}
        }
        default:
            return state;
    }
}