import {LOAD_USER_ITEM_REQUESTS} from "./actions";


export const itemRequests = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {

        case LOAD_USER_ITEM_REQUESTS:{
            return {...payload}
        }
        default:
            return state;
    }
}