import React from 'react';
import {Link} from "react-router-dom";
import UploadItemsForm from "./components/forms/UploadItemsForm";

const ManageItems = () => {
    return (
        <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="page-header-title">
                    <h5 className="m-b-10">Manage Items</h5>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/">
                                <i className="feather icon-home"></i>
                            </Link>
                        </li>
                        <li className="breadcrumb-item">Manage Items</li>
                    </ul>
                </div>
                <div className="card">
                    <div className="card-body">
                        <UploadItemsForm/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageItems;