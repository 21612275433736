import React, {useState} from "react";
import { useDispatch } from "react-redux";
import Pagination from "../../../../components/Pagination";
import { loadCashRequestApprovals } from "../../../../store/cashRequestApprovals/thunks";
import ApprovedIcon from "../../../../components/ApprovedIcon";
import RejectedIcon from "../../../../components/RejectedIcon";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

const CashRequestApprovalsTable = ({
  cashRequestApprovals,
  resultsCount = 0,
}) => {
  const dispatch = useDispatch();
  const handlePageClick = (e) => {
    dispatch(loadCashRequestApprovals(e.selected));
  };
  const [search, setSearch] = useState('')
  return (
    <>
    <Form>
        <Form.Group className="form-group col-md-12"> 
          <Form.Control
            placeholder="Search by Beneficiary"        
            onChange={(e)=>setSearch(e.target.value)}
          />
        </Form.Group>
      </Form>    
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Cash Request ID</th>
            <th>Timestamp</th>
            <th>Beneficiary</th>
            <th>Amount</th>
            <th>Has Approved</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {cashRequestApprovals.filter((cashRequestApproval) => {
            return search.toLowerCase() === ''? cashRequestApproval : 
            cashRequestApproval.beneficiary.toLowerCase().includes(search);
          }).map((cashRequestApproval, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{cashRequestApproval.cash_request_id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(cashRequestApproval.date).toLocaleString()}
                </h6>
              </td>
              <td>{cashRequestApproval.beneficiary}</td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: cashRequestApproval.currency,
                  }).format(parseInt(cashRequestApproval.amount))}
                </h6>
              </td>
              <td>
                {cashRequestApproval.is_approved ? (
                  <ApprovedIcon />
                ) : (
                  <RejectedIcon />
                )}
              </td>
              <td>
                <Link
                  to={"/cash_request/track_approvals/" + cashRequestApproval.cash_request_id}
                  className="label theme-bg text-white f-12"
                >
                  Track Approvals
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        resultsCount={resultsCount}
        handlePageClick={handlePageClick}
      />
    </div>
    </>
  );
};

export default CashRequestApprovalsTable;
