import React from "react";

const PerdiemRatesTable = ({ perdiemRates }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Category</th>
            <th>Local</th>
            <th>East Africa</th>
            <th>Africa</th>
            <th>Others</th>
            <th>Transport</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {perdiemRates.map((perdiemRate, key) => (
            <tr key={key}>
              <td>{perdiemRate.id}</td>
              <td>
                <h6 className="m-0">
                  {perdiemRate.category}
                </h6>
              </td>
              <td>
                <h6 className="m-0">{perdiemRate.local}</h6>
              </td>
              <td>
                <h6 className="m-0">{perdiemRate.east_africa}</h6>
              </td>
              <td>
                <h6 className="m-0">{perdiemRate.africa}</h6>
              </td>
              <td>
                <h6 className="m-0">{perdiemRate.others}</h6>
              </td>
              <td>
                <h6 className="m-0">{perdiemRate.transport}</h6>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PerdiemRatesTable;
