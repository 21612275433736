import React from 'react'

const TabPane = ({name, children, show=false}) => {
  const classes = show ? "active show" : ""
  return (
  <div
    className={`tab-pane fade ${classes}`}
    id={`${name}`}
    role="tabpanel"
    aria-labelledby={`${name}-tab`}
  >
     {children}
  </div>
  )
}

export default TabPane