import React from "react";
import { Link } from "react-router-dom";
import useFetching from "../../hooks/useFetching";
import { loadGMUsers } from "../../store/GMusers/thunks";
import { loadProjects } from "../../store/projects/thunks";
import ServiceRequestForm from "./components/forms/serviceRequestForm"


const NewServiceRequest = () => {
  useFetching(loadGMUsers)
  useFetching(loadProjects)
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="page-header-title">
        <h5 className="m-b-10">Create New Service Request</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">Create New Service Request</li>
        </ul>
      </div>
        <div className="card">
          <div className="card-body">
            <ServiceRequestForm/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewServiceRequest;
