import { createSelector } from "reselect";


export const getUserItemRequests = (state) => state.itemRequests.results;
export const getUserItemRequestsCount = (state) => state.itemRequests.count;

export const getPendingUserItemRequests = createSelector(
  getUserItemRequests,
  (itemRequests) =>
    itemRequests.filter(
        (itemRequest) => itemRequest.ceo_approval === "Pending")
);

export const getApprovedUserItemRequests = createSelector(
  getUserItemRequests,
  (itemRequests) =>
    itemRequests.filter(
        (itemRequest) => itemRequest.ceo_approval === "Approved")
);

export const getRejectedUserItemRequests = createSelector(
  getUserItemRequests,
  (itemRequests) =>
    itemRequests.filter(
        (itemRequest) => itemRequest.ceo_approval === "Rejected")
);
