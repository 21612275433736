export const greeting = () => {
  var d = new Date();
  var hours = d.getHours();

  if (hours < 12) {
    return "Good Morning!";
  }
  if (hours >= 12 && hours < 18) {
    return "Good Afternoon!";
  }
  if (hours === 1) {
    return "Go Eat Lunch!";
  }
  if (hours > 17){
      return "Good Evening!"
  }
};
