import React from "react";
import { Link, useParams } from "react-router-dom";
import AddFinancialInfoForm from "./components/forms/AddFinancialInfoForm";
import useFetching from "../../hooks/useFetching";
import { loadGLAccounts } from "../../store/glAccounts/thunks";

const AddFinancialInfo = () => {
  const { id } = useParams();
  useFetching(loadGLAccounts);
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Add Financial Info To Cash Request ID {id}</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/pay_cash">Pay Cash</Link>
          </li>
          <li className="breadcrumb-item">Add Financial Info</li>
        </ul>
      </div>
      <div className="card">
        <div className="card-body">{<AddFinancialInfoForm />}</div>
      </div>
    </div>
  );
};

export default AddFinancialInfo;
