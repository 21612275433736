import React from "react";
import { Link } from "react-router-dom";

import AddFinancialInfoForm from "./components/forms/AddServiceFinancialInfoForm";

const AddFinancialInfo = () => {
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Add Financial Info To Service Request ID 151</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/pay_service">Pay Service</Link>
          </li>
          <li className="breadcrumb-item">Add Financial Info</li>
        </ul>
      </div>
      <div className="card">
        <div className="card-body">{<AddFinancialInfoForm />}</div>
      </div>
    </div>
  );
};

export default AddFinancialInfo;
