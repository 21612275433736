import React from "react";
import useFetching from "../../hooks/useFetching";
import { loadUserCashRequests } from "../../store/cashRequests/thunks";
import { useSelector, useDispatch } from "react-redux";
import { getUserCashRequests, getUserCashRequestsCount } from "../../store/cashRequests/selectors";
import PageTemplate from "../../components/PageTemplate";
import { Card } from "react-bootstrap";
import CashRequestsTable from "./components/tables/CashRequestsTable";

const AllCashRequests = () => {
  useFetching(loadUserCashRequests);
  const cashRequests = useSelector(getUserCashRequests);
  const resultsCount = useSelector(getUserCashRequestsCount);
  const dispatch = useDispatch();
  const dispatchPagination = (e) => {
    dispatch(loadUserCashRequests(e.selected));
  };
  return (
    <PageTemplate title={"All Cash Requests"}>
      <Card>
        <Card.Body>
          {cashRequests && (
            <CashRequestsTable
              cashRequests={cashRequests}
              resultsCount={resultsCount}
              dispatchPagination={dispatchPagination}
            />
          )}
        </Card.Body>
      </Card>
    </PageTemplate>
  );
};

export default AllCashRequests;
