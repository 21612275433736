import React from "react";
import { useForm } from "react-hook-form";

const ApprovalForm = ({ handleApprove }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()  
 
  const onSubmit = (data) => {
    handleApprove(data);

  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
      <div className="form-group col-md-12">
        <label>Comment</label>
        <textarea
          className="form-control"
          placeholder="(Optional) Enter Comment"
          {...register("comment", { required: false })}
        ></textarea>
      </div>
      <div className="form-group col-md-4">
        <label>Action</label>
        <select
          className="form-control"
          {...register("is_approved", { required: true })}
        >
          <option value="">-- SELECT Action ---</option>
          <option value={1} key={1}>
            Approve
          </option>
          <option value={0} key={0}>
            Reject
          </option>
        </select>
        {errors.is_approved && (
          <small className="error">Please select an action</small>
        )}
      </div>
      <div className="col-md-12">
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
    </form>
  );
};

export default ApprovalForm;
