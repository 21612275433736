import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import axiosInstance from '../../utils/axiosInstance';
import { loadPerdiemRequestDetailAction, removePerdiemRequestDetailAction } from "./actions";

export const loadPerdiemRequestDetail = (id) => (dispatch) => {
    dispatch(loadingStartedAction());
    const uri = `/perdiem_api/perdiem_request_detail/${id}`;
    axiosInstance.get(uri).then((res) => {
      if (res.status === 200) {
        dispatch(loadPerdiemRequestDetailAction(res.data));
        dispatch(loadingEndedAction());
      }
      dispatch(loadingEndedAction());
    });
  };

  export const removePerdiemRequestDetail = () => (dispatch)=>{
       dispatch(removePerdiemRequestDetailAction());
  }