import { LOAD_USER } from "./actions";

export const user = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_USER:{
            return payload
        }
        default:
            return state;
    }
}