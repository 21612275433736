
import { LOAD_PERDIEM_REQUEST_DETAIL, REMOVE_PERDIEM_REQUEST_DETAIL } from './actions';


export const perdiemRequestDetail = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_PERDIEM_REQUEST_DETAIL:
            return {...payload};

        case REMOVE_PERDIEM_REQUEST_DETAIL: 
           return {};
           
        default:
            return state;
    }
}