import { CLEAR_NOTIFICATIONS, LOAD_NOTIFICATIONS } from "./actions";

export const notifications = (state=[],action)=>{
    const {type, payload}=action;
    switch (type) {
        case LOAD_NOTIFICATIONS:{
            return [...payload]
        }
        case CLEAR_NOTIFICATIONS: {
            return payload
        }
        default:
            return state;
    }
}