import axiosInstance from "../../utils/axiosInstance";
import {createItemRequestAction, loadUserItemRequestsAction} from "./actions";
import { loadingStartedAction, loadingEndedAction } from "../isLoading/actions";
import {NotificationManager} from "react-notifications";

export const loadUserItemRequests = (page=0) => (dispatch) => {
  dispatch(loadingStartedAction());
  const uri = "/api/item_requests";
  const endpoint = page ? `${uri}?page=${page + 1}` : uri;
  axiosInstance.get(endpoint).then((res) => {
    if (res.status === 200) {
      dispatch(loadUserItemRequestsAction(res.data));
      dispatch(loadingEndedAction());
    }
  }).catch(e=>{dispatch(loadingEndedAction())});
};

export const createItemRequest = (itemRequest, history)=>(dispatch)=>{
  dispatch(loadingStartedAction());
  console.log("Item request from thunk", itemRequest)
  axiosInstance
  .post("/api/item_requests", itemRequest)
  .then((res) => {
    dispatch(createItemRequestAction(res.data));
    NotificationManager.success(
      `Item Request ID ${res.data.id} successfully created`,
      "Item Request Created"
    );
    history.push("/all_item_requests");
    dispatch(loadingEndedAction())
  })
  .catch((err) => {
    dispatch(loadingEndedAction())
  });
}
