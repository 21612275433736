import {  LOAD_USER_CASH_REQUESTS, CREATE_CASH_REQUEST } from "./actions";

export const cashRequests = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_USER_CASH_REQUESTS:
            return {...payload};
        
        case  CREATE_CASH_REQUEST: 
            return {...payload, ...state}
        default:
            return state;
    }
}

