import {  LOAD_PAID_SERVICE_REQUESTS } from "./actions";

export const paidServiceRequests = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_PAID_SERVICE_REQUESTS:{
            return {...payload}
        }
        default:
            return state;
    }
}