import React, { useEffect } from "react";
import PageTemplate from "../components/PageTemplate";
import { loadPerdiemRequestApprovalDetails } from "../store/perdiemRequestApprovalDetails/thunks";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPerdiemRequestApprovalDetails } from "../store/perdiemRequestApprovalDetails/selectors";
import PerdiemRequestApprovalDetailsCards from '../components/cards/PerdiemRequestApprovalDetailsCards';
import { Card } from 'react-bootstrap';
import PerdiemRequestDetails from "./PerdiemRequestDetails";


const PerdiemRequestApprovalDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const perdiemRequestApprovalDetails = useSelector(getPerdiemRequestApprovalDetails);
  useEffect(() => {
    dispatch(loadPerdiemRequestApprovalDetails(0, id));
  }, [id]);

  return (
    <PageTemplate title={"Perdiem Request Approval Details"}>
      <PerdiemRequestDetails /> 
      {perdiemRequestApprovalDetails && perdiemRequestApprovalDetails.length > 0 ? <PerdiemRequestApprovalDetailsCards perdiemRequestApprovalDetails={perdiemRequestApprovalDetails}/>:
      <Card>
        <Card.Body>No Approvals yet</Card.Body>
      </Card>
      }   
    </PageTemplate>
  );
};

export default PerdiemRequestApprovalDetails;
