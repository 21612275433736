import React from "react";
import { Link } from "react-router-dom";

const UserDropDown = ({ user }) => {
  const { first_name, last_name, gender} = user;
  return (
    <li>
      <div className="dropdown drp-user">
        <a href="/" className="dropdown-toggle" data-toggle="dropdown">
          <i className="icon feather icon-settings"></i>
        </a>
        <div className="dropdown-menu dropdown-menu-right profile-notification">
          <div className="pro-head">
            {gender && gender==="Male"? (
            <img
              src="assets/images/user/male-avatar.jpg"
              className="img-radius"
              alt="user"
            />):
            (
            <img
              src="assets/images/user/female-avatar.jpg"
              className="img-radius"
              alt="user"
            />)}
            <span>
              {first_name} {last_name}
            </span>
            <Link to="/logout" className="dud-logout" title="Logout">
              <i className="feather icon-log-out"></i>
            </Link>
          </div>
          <ul className="pro-body">
            <li>
              <a href="/" className="dropdown-item">
                <i className="feather icon-settings"></i> Settings
              </a>
            </li>
            <li>
              <a href="/" className="dropdown-item">
                <i className="feather icon-user"></i> Profile
              </a>
            </li>
            <li>
              <a href="/" className="dropdown-item">
                <i className="feather icon-mail"></i> My Messages
              </a>
            </li>
            <li>
              <Link to="/logout" className="dropdown-item">
                <i className="feather icon-lock"></i> Lock Screen
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
};

export default UserDropDown;
