import React from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const MenuListItem = ({itemData}) => {
  const {pathname} = useLocation()
  const activeClassName = pathname === itemData.path?"active":""
  return (
    <li className={"nav-item "+ activeClassName}>
    <Link to={itemData.path} className="nav-link ">
      <span className="pcoded-micon">
        <i className={itemData.icon}></i>
      </span>
      <span className="pcoded-mtext">{itemData.name}</span>
      </Link>
    </li>
  )
}

export default MenuListItem;