import React, {useState} from "react";
import RejectedIcon from "../../../../components/RejectedIcon";
import PendingIcon from "../../../../components/PendingIcon";
import ApprovedIcon from "../../../../components/ApprovedIcon";
import Pagination from "../../../../components/Pagination";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

const CashRequestsTable = ({
  cashRequests,
  resultsCount,
  dispatchPagination,
}) => {
  const [search, setSearch] = useState('')
  return (
    <>
      <Form>
        <Form.Group className="form-group col-md-12"> 
          <Form.Control
            placeholder="Search"        
            onChange={(e)=>setSearch(e.target.value)}
          />
        </Form.Group>
      </Form>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Date</th>
              <th>Purpose</th>
              <th>Amount</th>
              <th>GM</th>
              <th>CFO</th>
              <th>CEO</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {cashRequests.filter((cashRequest) => {
            return search.toLowerCase() === ''? cashRequest : 
            cashRequest.purpose.toLowerCase().includes(search);
          }).map((cashRequest, key) => (
              <tr key={key}>
                <td>
                  <h6 className="m-0">
                    {new Date(cashRequest.date).toLocaleDateString("en-UK")}
                  </h6>
                </td>
                <td>{cashRequest.purpose}</td>
                <td>
                  <h6 className="m-0">
                    {new Intl.NumberFormat("en-GB", {
                      style: "currency",
                      currency: cashRequest.currency,
                    }).format(parseInt(cashRequest.amount))}
                  </h6>
                </td>
                <td>
                  {cashRequest.business_unit_gm_approval === "Rejected" && (
                    <RejectedIcon />
                  )}
                  {cashRequest.business_unit_gm_approval === "Pending" && (
                    <PendingIcon />
                  )}
                  {cashRequest.business_unit_gm_approval === "Approved" && (
                    <ApprovedIcon />
                  )}
                </td>
                <td>
                  {cashRequest.cfo_approval === "Rejected" && <RejectedIcon />}
                  {cashRequest.cfo_approval === "Pending" && <PendingIcon />}
                  {cashRequest.cfo_approval === "Approved" && <ApprovedIcon />}
                </td>
                <td>
                  {cashRequest.ceo_approval === "Rejected" && <RejectedIcon />}
                  {cashRequest.ceo_approval === "Pending" && <PendingIcon />}
                  {cashRequest.ceo_approval === "Approved" && <ApprovedIcon />}
                </td>
                <td>
                  <Link
                    to={"/cash_request/track_approvals/" + cashRequest.id}
                    className="label theme-bg text-white f-12"
                  >
                    Track Approvals
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        resultsCount={resultsCount}
        handlePageClick={dispatchPagination}
      />
    </>
  );
};

export default CashRequestsTable;
