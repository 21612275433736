import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../../components/Pagination";
import useFetching from "../../../../hooks/useFetching";
import { loadServiceRequestsForPayment } from "../../../../store/serviceRequestsForPayment/thunks";
import { getServiceRequestsForPayment } from "../../../../store/serviceRequestsForPayment/selectors";


const PayServiceRequestsTable = () => {
  useFetching(loadServiceRequestsForPayment)
  const serviceRequests = useSelector(getServiceRequestsForPayment);
  const dispatch = useDispatch();
  const resultsCount = useSelector(
    (state) => state.serviceRequestsForPayment.count
  );
  console.log("From Table", serviceRequests);
  const handlePaginationChange = (e) => {
    dispatch(loadServiceRequestsForPayment(e.selected));
  };


  return serviceRequests?(
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Beneficiary</th>
            <th>Phone Number</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {serviceRequests.map((serviceRequest, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{serviceRequest.id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(serviceRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>{serviceRequest.name}</td>
              <td>{serviceRequest.phone_number}</td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB").format(parseInt(serviceRequest.amount))}
                </h6>
              </td>
              <td>
                <Link
                  to={"/pay_service_request/" + serviceRequest.id}
                  className="label theme-bg text-white f-12"
                >
                  View to Pay
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        resultsCount={resultsCount}
        handlePageClick={handlePaginationChange}
      />
    </div>
  ):(<div>No service requests Available</div>);
};

export default PayServiceRequestsTable;
