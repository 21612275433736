import React from "react";
import { Link } from "react-router-dom";
import PerdiemRatesTab from "./components/tabs/perdiemRatesTab";


const PerdiemRates = () => {
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Perdiem Rates</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">Perdiem Rates</li>
        </ul>
      </div>
      <PerdiemRatesTab />
    </div>
  );
};

export default PerdiemRates;
