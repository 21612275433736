import React from "react";
import { useDispatch } from "react-redux";
import Pagination from "../../../../components/Pagination";
import { loadServiceRequestApprovals } from '../../../../store/serviceRequestApprovals/thunks';
import { getServiceRequestApprovals } from '../../../../store/serviceRequestApprovals/selectors';
import ApprovedIcon from '../../../../components/ApprovedIcon';
import RejectedIcon from '../../../../components/RejectedIcon';
import useFetching from "../../../../hooks/useFetching";
import { useSelector } from "react-redux";

const ServiceRequestApprovalsTable = () => {
  useFetching(loadServiceRequestApprovals);
  const serviceRequestApprovals = useSelector(getServiceRequestApprovals);
  const resultsCount = useSelector(
    (state) => state.serviceRequestApprovals.count
  );
  const dispatch = useDispatch()
  const handlePageClick = (e) => {
     dispatch(loadServiceRequestApprovals(e.selected));
  };
  return serviceRequestApprovals?(
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Timestamp</th>
            <th>Beneficiary</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {serviceRequestApprovals.map((serviceRequestApproval, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{serviceRequestApproval.service_request_id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(serviceRequestApproval.date).toLocaleString()}
                </h6>
              </td>
              <td>{serviceRequestApproval.beneficiary}</td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: serviceRequestApproval.currency,
                  }).format(parseInt(serviceRequestApproval.amount))}
                </h6>
              </td>
              <td>{serviceRequestApproval.is_approved?(
                <ApprovedIcon/>
                ):<RejectedIcon/>}
                </td>
            </tr>
          ))}
        </tbody>
      </table>
     <Pagination resultsCount={resultsCount} handlePageClick={handlePageClick}/>
    </div>
  ):(<div>No service request approvals Yet</div>);
};

export default ServiceRequestApprovalsTable;
