import React, { useEffect } from "react";
import PageTemplate from "../../components/PageTemplate";
import { loadTrackCashRequestApprovals } from "../../store/trackCashRequestApprovals/thunks";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTrackCashRequestApprovals } from "../../store/trackCashRequestApprovals/selectors";
import TrackCashRequestApprovalsCards from './components/cards/TrackCashRequestApprovalsCards';
import { Card } from 'react-bootstrap';
import CashRequestDetails from "./CashRequestDetails";

const TrackCashRequestApprovals = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const cashRequestApprovals = useSelector(getTrackCashRequestApprovals);
  useEffect(() => {
    dispatch(loadTrackCashRequestApprovals(0, id));
  }, [id]);

  return (
    <PageTemplate title={"Track Cash Request Approvals"}>
      <CashRequestDetails />
      {cashRequestApprovals && cashRequestApprovals.length > 0 ? <TrackCashRequestApprovalsCards cashRequestApprovals={cashRequestApprovals}/>:
      <Card>
        <Card.Body>No Approvals yet</Card.Body>
      </Card>
      }    
    </PageTemplate>
  );
};

export default TrackCashRequestApprovals;
