import React from "react";
import { useSelector } from "react-redux";
import { getNotifications } from "../store/notifications/selectors";
import NotificationCard from "./NotificationCard";

const NotificationDropDown = () => {
  const notifications = useSelector(getNotifications);
  return notifications.length > 0 && (
    <li>
      <div className="dropdown">
        <a href="/" className="dropdown-toggle" data-toggle="dropdown">
          <div>
            <i className="icon feather icon-bell"></i>
            <i style={{ color: "red" }}>{notifications.length}</i>
          </div>
        </a>
        <NotificationCard notifications={notifications}/>
      </div>
    </li>
  );
};

export default NotificationDropDown;
