import React from 'react'
import { Link } from 'react-router-dom'
import useFetching from '../../../../hooks/useFetching';
import Pagination from "../../../../components/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { loadServiceRequestsForApproval } from "../../../../store/serviceRequestsForApproval/thunks";
import { getServiceRequestsForApproval } from '../../../../store/serviceRequestsForApproval/selectors';

const ApproveServiceRequestsTable = () => {
  useFetching(loadServiceRequestsForApproval)
  const serviceRequests = useSelector(getServiceRequestsForApproval)

  const resultsCount = useSelector(
    (state) => state.serviceRequestsForApproval.count
  );
  
  const dispatch = useDispatch()
  const handlePageClick = (e) => {
    dispatch(loadServiceRequestsForApproval(e.selected));
  };
  return serviceRequests ? (
    <div className="table-responsive">
    <table className="table table-hover">
    <thead>
      <tr>
        <th>Date</th>
        <th>Beneficiary</th>
        <th>Purpose</th>
        <th>Service</th>
        <th>Quantity</th>
        <th>Amount</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {serviceRequests.map((serviceRequest, key) => (
        <tr key={key}>
          <td>
            <h6 className="m-0">
              {new Date(serviceRequest.date).toLocaleDateString("en-UK")}
            </h6>
          </td>
          <td>{serviceRequest.name}</td>
          <td>
            <h6 className="m-0">{serviceRequest.purpose}</h6>
          </td>
          <td>
            <h6 className="m-0">{serviceRequest.service}</h6>
          </td>
          <td>
            <h6 className="m-0">{serviceRequest.quantity}</h6>
          </td>
          <td>
          <h6 className="m-0">
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: serviceRequest.currency ,
                }).format(parseInt(serviceRequest.amount))}
              </h6>
          </td>
          <td><Link to={"/approve_service_request/" + serviceRequest.id} className="label theme-bg text-white f-12">View to Approve</Link></td>
        </tr>
      ))}
    </tbody>
  </table>
  <Pagination resultsCount={resultsCount} handlePageClick={handlePageClick}/>
  </div>
  ):(<div>No Service requests for approval Available</div>);
}

export default ApproveServiceRequestsTable