export const LOAD_NOTIFICATIONS = "LOAD_NOTIFICATIONS"
export const loadNotificationsAction = notifications => ({
   type: LOAD_NOTIFICATIONS,
   payload: notifications
})

export const CLEAR_NOTIFICATIONS = "LOAD_NOTIFICATIONS"
export const clearNotificationsAction = () => ({
   type: LOAD_NOTIFICATIONS,
   payload: []
})