import axiosInstance from "../../utils/axiosInstance";
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import {
  loadCashRequestsForApprovalAction,
  removeCashRequestForApproval
} from "./actions";
import { NotificationManager } from "react-notifications";

export const loadCashRequestsForApproval =
  (page = 0) =>
  (dispatch) => {
    dispatch(loadingStartedAction());
    const uri = "/api/cash_requests_for_approval";
    const endpoint = page ? `${uri}?page=${page + 1}` : uri;

    axiosInstance
      .get(endpoint)
      .then((res) => {
        if (res.status === 200)
          dispatch(loadCashRequestsForApprovalAction(res.data));
        dispatch(loadingEndedAction());
      })
      .catch((err) => {
        console.log("Request Denied");
        dispatch(loadingEndedAction());
      });
  };

export const approveCashRequestThunk = (cashRequest, data) => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance
    .post(`api/approve_cash_request/${cashRequest.id}`, data)
    .then((res) => {
      NotificationManager.success(
        `Successfully sent your approval the cash request from ${cashRequest.name}`
      );
      dispatch(removeCashRequestForApproval(cashRequest))
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      console.log("The error is here", err.response)
      NotificationManager.error("Could not approve cash request");
      dispatch(loadingEndedAction());
    });
};

export const rejectCashRequest = (cashRequest) => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance
    .get(`api/reject_cash_request/${cashRequest.id}`)
    .then((res) => {
      NotificationManager.success(
        `Successfully rejected the cash request from ${cashRequest.name}`
      );
      dispatch(removeCashRequestForApproval(cashRequest));
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      console.table(err)
      NotificationManager.error("Something went wrong");
      dispatch(loadingEndedAction());
    });
};
