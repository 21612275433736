import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import axiosInstance from '../../utils/axiosInstance';
import { loadPaidPerdiemRequestsAction, loadPaidPerdiemRequestsCsvAction } from "./actions";



export const loadPaidPerdiemRequests = (page=0) => (dispatch) => {
  dispatch(loadingStartedAction());
  const uri = "/perdiem_api/paid_perdiem_requests";
  const endpoint = page ? `${uri}?page=${page + 1}` : uri;
  axiosInstance.get(endpoint).then((res) => {
    if (res.status === 200) {
      dispatch(loadPaidPerdiemRequestsAction(res.data));
      dispatch(loadingEndedAction());
    }
    dispatch(loadingEndedAction());
  });
};

export const loadPaidPerdiemRequestsCsv = (page=0) => (dispatch) => {
  dispatch(loadingStartedAction());
  const endpoint = "/perdiem_api/perdiem_requests_csv";
  axiosInstance.post(endpoint).then((res) => {
    if (res.status === 200) {
      dispatch(loadPaidPerdiemRequestsCsvAction(res.data));
      dispatch(loadingEndedAction());
    }
    dispatch(loadingEndedAction());
  });
};