import React from "react";
import PerdiemRequestForm from "../components/forms/PerdiemRequestForm";
import useFetching from "../hooks/useFetching";
import { loadGMUsers } from "../store/GMusers/thunks";
import { loadProjects } from "../store/projects/thunks";


const NewPerdiemRequest = () => {
  useFetching(loadProjects)
  useFetching(loadGMUsers)
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="page-header-title">
        <h5 className="m-b-10">Create New Perdiem Request</h5>
      </div>
        <div className="card">
          <div className="card-body">
            <PerdiemRequestForm/>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPerdiemRequest;
