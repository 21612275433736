import React, { Suspense } from "react";
import NavTabs from "../../../../components/tabs/NavTabs";
import NavTab from "../../../../components/tabs/NavTab";
import TabContent from "../../../../components/tabs/TabContent";
import TabPane from "../../../../components/tabs/TabPane";
import PayCashRequestsTable from '../tables/PayCashRequestsTable';
import PaidCashRequestsTable from '../tables/PaidCashRequestsTable';
import FilterForm from "../forms/FilterForm";

const CashRequestsForPaymentTab = () => {
  return (
    <>
      <NavTabs>
        <NavTab name={"pending-payment"} color="blue" show={true}>
          Pending Payment
        </NavTab>
        <NavTab name={"paid"} color="green">
          Paid
        </NavTab>
      </NavTabs>
      <TabContent>
        <TabPane name={"pending-payment"} show={true}>
           <Suspense fallback={<div>Loading Cash Requests ...</div>}>
              <PayCashRequestsTable/>
          </Suspense>
        </TabPane>
        <TabPane name={"paid"}>
           <Suspense fallback={<div>Loading Cash Requests ...</div>}>
              <FilterForm/>
              <PaidCashRequestsTable/>
          </Suspense>
        </TabPane>
      </TabContent>
    </>
  );
};

export default CashRequestsForPaymentTab;
