import { LOADING_ENDED, LOADING_STARTED } from "./actions";

export const isLoading = (state = false, action) => {
  const { type} = action;
  switch (type) {
    case LOADING_STARTED: {
      return true;
    }
    case LOADING_ENDED: {
      return false;
    }
    default:
      return state;
  }
};
