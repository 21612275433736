

export const LOAD_CASH_REQUESTS_FOR_APPROVAL = "LOAD_CASH_REQUESTS_FOR_APPROVAL"
export const loadCashRequestsForApprovalAction = cashRequestsPagination => ({
   type: LOAD_CASH_REQUESTS_FOR_APPROVAL,
   payload: cashRequestsPagination
})

export const REMOVE_CASH_REQUEST_FOR_APPROVAL = "REMOVE_CASH_REQUEST_FOR_APPROVAL"
export const removeCashRequestForApproval = cashRequest => ({
   type: REMOVE_CASH_REQUEST_FOR_APPROVAL,
   payload: cashRequest
})