import React from "react";
import { useSelector } from "react-redux";
import PendingPerdiemRequestsTable from "../components/tables/PendingPerdiemRequestsTable";
import useFetching from '../hooks/useFetching';
import { loadUserPerdiemRequests } from '../store/perdiemRequests/thunks';
import {
  getUserPerdiemRequests,
} from "../store/perdiemRequests/selectors";

const AllPerdiemRequests = () => {
  useFetching(loadUserPerdiemRequests)
  const PerdiemRequests = useSelector(getUserPerdiemRequests);
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">All Perdiem Requests</h5>
      </div>
      <PendingPerdiemRequestsTable perdiemRequests={PerdiemRequests}/>

    </div>
  );
};

export default AllPerdiemRequests;
