import React, {useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMenuItems } from "../data/menu";
import { useOutsideAlerter } from "../hooks/useOutsideAlerter";
import ExpandableMenuListItem from "./ExpandableMenuListItem";
import MenuListItem from "./MenuListItem";
import { getUser } from "../store/user/selectors";
import Logo from "../assets/logo.png";



const SideBar = ({ isNavBarOpen, setIsNavBarOpen }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsNavBarOpen);
  const user = useSelector(getUser)
  
  const menuItems = getMenuItems(user)


  return (
    <nav ref={wrapperRef} className={isNavBarOpen ? "pcoded-navbar mob-open" : "pcoded-navbar"}>
      <div className="navbar-wrapper">
        <div className="navbar-brand header-logo">
          <Link to="/" className="b-brand">
            <img
              src={Logo}
              height={40}
              width={60}
              alt="logo"
            />
            <div className="b-title">{process.env.REACT_APP_NAME}</div>
          </Link>
          <a href="/" className="mobile-menu" id="mobile-collapse">
            <span></span>
          </a>
        </div>
        <div className="navbar-content scroll-div">
          <ul className="nav pcoded-inner-navbar">
            {menuItems.map((item, key) =>
              item.expandable ? (
                <ExpandableMenuListItem
                  itemData={item}
                  subMenuItems={item.subMenuItems}
                  key={key}
                />
              ) : (
                <MenuListItem itemData={item} key={key} />
              )
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default SideBar;

