import React from 'react'
import { useSelector } from 'react-redux';
import { getUser } from '../store/user/selectors';
import { greeting } from '../utils/greeting';
import useFetching from '../hooks/useFetching';
import { loadNotifications } from '../store/notifications/thunks';


function Dashboard() {
  const user = useSelector(getUser)
  useFetching(loadNotifications);
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
    <div className="page-header-title">
      <h5 className="m-b-10">Dashboard</h5>
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
            <i className="feather icon-home"></i>
        </li>
        <li className="breadcrumb-item"></li>
      </ul>
    </div>
      <div className="card-block">
         <h1>{greeting()}{ user.first_name && " " + user.first_name}</h1>
      </div>
  </div>
  )
}

export default Dashboard