import React from "react";
import { Link } from "react-router-dom";
import PerdiemRequestsApprovalTab from "../components/tabs/PerdiemRequestsApprovalTab";

const ApprovePerdiemRequests = () => {
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Approve Perdiem Requests</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">Approve Perdiem Requests</li>
        </ul>
      </div>
      <PerdiemRequestsApprovalTab/>      
    </div>
  );
};

export default ApprovePerdiemRequests;
