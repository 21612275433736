import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import axiosInstance from '../../utils/axiosInstance';
import { loadPaidServiceRequestsAction } from "./actions";



export const loadPaidServiceRequests = (page=0) => (dispatch) => {
  dispatch(loadingStartedAction());
  const uri = "/perdiem_api/paid_service_requests";
  const endpoint = page ? `${uri}?page=${page + 1}` : uri;
  axiosInstance.get(endpoint).then((res) => {
    if (res.status === 200) {
      dispatch(loadPaidServiceRequestsAction(res.data));
      dispatch(loadingEndedAction());
    }
    dispatch(loadingEndedAction());
  });
};