import React from "react";

const RejectedIcon = () => {
  return (
    <span className="pcoded-micon">
      <i className="m-0 text-c-red feather icon-x"></i>
    </span>
  );
};

export default RejectedIcon;
