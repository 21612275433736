export const LOAD_CASH_REQUEST_DETAIL = "LOAD_CASH_REQUEST_DETAIL"
export const loadCashRequestDetailAction = cashRequest => ({
    type: LOAD_CASH_REQUEST_DETAIL,
    payload: cashRequest})


export const REMOVE_CASH_REQUEST_DETAIL = "REMOVE_CASH_REQUEST_DETAIL"
export const removeCashRequestDetailAction = cashRequest => ({
    type: LOAD_CASH_REQUEST_DETAIL,
    payload: {}
})

