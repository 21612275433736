import { createSelector } from "reselect";

export const getUserPerdiemRequests = (state) => state.perdiemRequests;

export const getPendingUserPerdiemRequests = createSelector(
  getUserPerdiemRequests,
  (perdiemRequests) => 
    perdiemRequests.filter(
        (perdiemRequest) => perdiemRequest.ceo_approval === "Pending")
);

export const getApprovedUserPerdiemRequests = createSelector(
  getUserPerdiemRequests,
  (perdiemRequests) =>
    perdiemRequests.filter(
        (perdiemRequest) => perdiemRequest.ceo_approval === "Approved")
);

export const getRejectedUserPerdiemRequests = createSelector(
  getUserPerdiemRequests,
  (perdiemRequests) =>
    perdiemRequests.filter(
        (perdiemRequest) => perdiemRequest.ceo_approval === "Rejected")
);
