import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { cashRequests } from "./cashRequests";
import { cashRequestsForApproval } from "./cashRequestsForApproval";
import { perdiemRequestsForApproval } from "./perdiemRequestsForApproval";
import {perdiemRequests} from "./perdiemRequests";
import {user } from "./user";
import { isLoading } from "./isLoading";
import thunk from "redux-thunk";
import { notifications } from "./notifications";
import { GMUsers } from "./GMusers";
import { GLAccounts } from "./glAccounts";
import { projects } from "./projects";
import { workers } from "./workers";
import {itemRequests} from "./itemRequests";
import {itemRequestsForApproval} from "./itemRequestsForApproval";
import {items} from "./items";
import {cashRequestsForPayment} from "./cashRequestsForPayment";
import {itemRequestsForDispatch} from "./itemRequestsForDispatch";
import {serviceRequests} from "./serviceRequests";
import {serviceRequestsForApproval} from "./serviceRequestsForApproval";
import { perdiemRates } from "./perdiem";
import { perdiemWorkPlans } from "./perdiemWorkPlan";
import {perdiemRequestsForPayment} from "./perdiemRequestsForPayment";
import {serviceRequestsForPayment} from "./serviceRequestForPayment";
import { paidServiceRequests } from './paidServiceRequests/index';
import {cashRequestDetail} from "./cashRequestDetail";
import { paidCashRequests } from './paidCashRequests/index';
import { paidPerdiemRequests, paidPerdiemRequestsCsv } from './paidPerdiemRequests/index';
import { perdiemRequestApprovals } from './perdiemRequestApprovals/index';
import { cashRequestApprovals } from './cashRequestApprovals/index';
import {itemRequestDetail} from './itemRequestDetail';
import {serviceRequestDetail} from "./serviceRequestDetail";
import {perdiemRequestDetail} from "./perdiemRequestDetail";import { serviceRequestApprovals } from './serviceRequestApprovals/index';
import { trackCashRequestApprovals } from './trackCashRequestApprovals/index';
import { perdiemRequestApprovalDetails } from './perdiemRequestApprovalDetails/index'; 

const reducers = {
  user,
  notifications,
  GMUsers,
  GLAccounts,
  isLoading,
  cashRequests,
  projects,
  serviceRequestDetail,
  cashRequestsForApproval,
  cashRequestsForPayment,
  paidCashRequests,
  perdiemRequests,
  perdiemRequestsForApproval,
  perdiemRequestApprovalDetails,
  perdiemRequestDetail,
  perdiemRequestApprovals,
  paidPerdiemRequests,
  workers,
  itemRequests,
  itemRequestsForApproval,
  itemRequestsForDispatch,
  items,
  serviceRequests,
  serviceRequestsForApproval,
  serviceRequestApprovals,
  perdiemRates,
  perdiemWorkPlans,
  perdiemRequestsForPayment,
  serviceRequestsForPayment,
  paidServiceRequests,
  cashRequestDetail,
  cashRequestApprovals,
  trackCashRequestApprovals,
  itemRequestDetail,
  paidPerdiemRequestsCsv,
};

const initialState = {};
const middleWare =[thunk];
const rootReducer = combineReducers(reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(...middleWare)
  )
);
