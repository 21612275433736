import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import axiosInstance from '../../utils/axiosInstance';
import { loadCashRequestDetailAction, removeCashRequestDetailAction } from "./actions";

export const loadCashRequestDetail = (id) => (dispatch) => {
    dispatch(loadingStartedAction());
    const uri = `/api/cash_request_detail/${id}`;
    axiosInstance.get(uri).then((res) => {
      if (res.status === 200) {
        dispatch(loadCashRequestDetailAction(res.data));
        dispatch(loadingEndedAction());
      }
      dispatch(loadingEndedAction());
    });
  };

  export const removeCashRequestDetail = () => (dispatch)=>{
       dispatch(removeCashRequestDetailAction());
  }