import React from 'react'
import { NotificationContainer } from 'react-notifications'


const OutsideLayout = ({ children, ...restProps }) => {
  return (
    <div className="auth-wrapper">
    <div className="auth-content">
        <div className="auth-bg">
            <span className="r"></span>
            <span className="r s"></span>
            <span className="r s"></span>
            <span className="r"></span>
        </div>
        {children}
    </div>
    <NotificationContainer/>
</div>
  )
}

export default OutsideLayout