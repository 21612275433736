import { LOAD_PERDIEM_REQUESTS_FOR_APPROVAL, REMOVE_PERDIEM_REQUEST_FOR_APPROVAL } from "./actions";


export const perdiemRequestsForApproval = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_PERDIEM_REQUESTS_FOR_APPROVAL:{
            return {...payload}
        }
        case REMOVE_PERDIEM_REQUEST_FOR_APPROVAL:
            return {...state, results: state.results.filter(perdiemRequest => perdiemRequest.id !== payload.id)}
        default:
            return state;
    }
}