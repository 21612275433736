import React from "react";
import { Link } from "react-router-dom";
import { loadCashRequestApprovals } from '../../store/cashRequestApprovals/thunks';
import { useSelector } from 'react-redux';
import useFetching from '../../hooks/useFetching';
import { getCashRequestApprovals } from '../../store/cashRequestApprovals/selectors';
import CashRequestApprovalsTable from './components/tables/CashRequestApprovalsTable';



const CashRequestApprovalHistory = () => {
  useFetching(loadCashRequestApprovals);
  const cashRequestApprovals = useSelector(
    getCashRequestApprovals
  );
  const resultsCount = useSelector(
    (state) => state.cashRequestApprovals.count
  );
  

  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Cash Requests Approval History</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/approve_cash_requests">Approve Cash Requests</Link>
          </li>
          <li className="breadcrumb-item">Cash Request Approval History</li>
        </ul>
      </div>
      <div className="card">
        <div className="card-body">
           {cashRequestApprovals && cashRequestApprovals.length > 0 ? (
            <CashRequestApprovalsTable
              cashRequestApprovals={cashRequestApprovals}
              resultsCount={resultsCount}
            />
          ) : (
            <div>No Cash Requests Approvals Yet</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CashRequestApprovalHistory;
