import axiosInstance from "../../utils/axiosInstance";
import { createCashRequestAction, loadUserCashRequestsAction } from "./actions";
import { loadingStartedAction, loadingEndedAction } from "../isLoading/actions";
import {NotificationManager} from "react-notifications";
export const loadUserCashRequests = (page=0) => (dispatch) => {
  dispatch(loadingStartedAction());
  const uri = "/api/all_user_cash_requests";
  const endpoint = page ? `${uri}?page=${page + 1}` : uri;
  axiosInstance.get(endpoint).then((res) => {
    if (res.status === 200) {
      dispatch(loadUserCashRequestsAction(res.data));
      dispatch(loadingEndedAction());
    }
    dispatch(loadingEndedAction());
  });
};

export const createCashRequest = (cashRequest, history)=>(dispatch)=>{
  dispatch(loadingStartedAction());
  dispatch(createCashRequestAction(cashRequest));
  axiosInstance
  .post("/api/cash_request", cashRequest)
  .then((res) => {
    NotificationManager.success(
      `Cash Request ID ${res.data.id} successfully created`,
      "Cash Request Created"
    );
    history.push("/all_cash_requests");

    dispatch(loadingEndedAction())
  })
  .catch((err) => {
    console.log("The err is ", err.response.data)
    NotificationManager.error(
      "Something went wrong. If the problem persists please contact Soliton BI",
      `${err.response.status} Error`
    );
    dispatch(loadingEndedAction())
  });
}


