import React, {useState} from "react";
import { Link } from "react-router-dom";
import { loadCashRequestsForApproval } from "../../../../store/cashRequestsForApproval/thunks";
import { useDispatch } from "react-redux";
import Pagination from "../../../../components/Pagination";
import Form from "react-bootstrap/Form";

const ApproveCashRequestsTable = ({ cashRequests, resultsCount=0 }) => {
  const dispatch = useDispatch()
  const handlePageClick = (e) => {
     dispatch(loadCashRequestsForApproval(e.selected));
  };
  const [search, setSearch] = useState('')
  return (
    <>
    <Form>
        <Form.Group className="form-group col-md-12"> 
          <Form.Control
            placeholder="Search by Purpose"        
            onChange={(e)=>setSearch(e.target.value)}
          />
        </Form.Group>
      </Form>
    
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Beneficiary</th>
            <th>Purpose</th>
            <th>Amount</th>
            <th>GM</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {cashRequests.filter((cashRequest) => {
            return search.toLowerCase() === ''? cashRequest : 
            cashRequest.purpose.toLowerCase().includes(search);
          }).map((cashRequest, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{cashRequest.id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(cashRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>{cashRequest.name}</td>
              <td>{cashRequest.purpose}</td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: cashRequest.currency,
                  }).format(parseInt(cashRequest.amount))}
                </h6>
              </td>
              <td>{cashRequest.business_unit_gm_name || "N/A"}</td>
              <td>
                <Link
                  to={"/approve_cash_request/" + cashRequest.id}
                  className="label theme-bg text-white f-12"
                >
                  View to Approve
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
     <Pagination resultsCount={resultsCount} handlePageClick={handlePageClick}/>
    </div>
    </>
  );
};

export default ApproveCashRequestsTable;
