import { LOAD_PROJECTS } from "./actions";

export const projects = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_PROJECTS:{
            return [...payload]
        }
        default:
            return state;
    }
}