

export const LOAD_ITEM_REQUESTS_FOR_DISPATCH = "LOAD_ITEM_REQUESTS_FOR_DISPATCH"
export const loadItemRequestsForDispatchAction = itemRequests => ({
   type: LOAD_ITEM_REQUESTS_FOR_DISPATCH,
   payload: itemRequests
})

export const REMOVE_ITEM_REQUEST = "REMOVE_ITEM_REQUEST"
export const removeItemRequest = itemRequest => ({
   type: REMOVE_ITEM_REQUEST,
   payload: itemRequest
})