import axiosInstance from "../../utils/axiosInstance";
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import { loadGMUsersAction } from "./actions";

export const loadGMUsers = () => (dispatch) => {
    dispatch(loadingStartedAction())
    axiosInstance.get("/accounts/general_managers").then((res) => {
      if (res.status === 200) dispatch(loadGMUsersAction(res.data));
      dispatch(loadingEndedAction())
    });
  };