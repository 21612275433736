import {CREATE_SERVICE_REQUEST, LOAD_USER_SERVICE_REQUESTS} from "./actions";


export const serviceRequests = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case CREATE_SERVICE_REQUEST:{
            const {serviceRequest} = payload;
            return [serviceRequest, ...state]
        }
        case LOAD_USER_SERVICE_REQUESTS:{
            return [...payload]
        }
        default:
            return state;
    }
}