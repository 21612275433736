import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { useUser } from "./hooks/useUser";

const CustomLayoutRoute = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  const user = useUser();
  if(!user) return <Redirect to="/login"/>
  return  <Route
    {...rest}
    render={(props) => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />;
};

export default CustomLayoutRoute;

