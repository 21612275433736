export const LOAD_ITEM_REQUEST_DETAIL = "LOAD_ITEM_REQUEST_DETAIL"
export const loadItemRequestDetailAction = itemRequest => ({
    type: LOAD_ITEM_REQUEST_DETAIL,
    payload: itemRequest})


export const REMOVE_ITEM_REQUEST_DETAIL = "REMOVE_ITEM_REQUEST_DETAIL"
export const removeItemRequestDetailAction = itemRequest => ({
    type: LOAD_ITEM_REQUEST_DETAIL,
    payload: {}
})

