import React, { useState } from "react";
import NotificationDropDown from "./NotificationDropDown";
import UserDropDown from "./UserDropDown";
import { useSelector } from "react-redux";
import { getUser } from "../store/user/selectors";

const TopBar = ({ setIsNavBarOpen, isNavBarOpen }) => {
  const [isMobileMenuOn] = useState(false);
  const handleClick = (e) => {
    e.preventDefault();
    const result = isNavBarOpen ? false : true;
    setIsNavBarOpen(result);
  };
  const user = useSelector(getUser);
  return (
    <header className="navbar pcoded-header navbar-expand-lg navbar-light">
      <div className="m-header">
        <a
          href="/"
          className={isMobileMenuOn ? "mobile-menu on" : "mobile-menu"}
          id="mobile-collapse1"
          onClick={(e)=>handleClick(e)}
        >
          <span></span>
        </a>
        <a href="/" className="b-brand">
          <img
              src="assets/images/logo.png"
              height={40}
              width={60}
              alt="logo"
            />
          <span className="b-title">{process.env.REACT_APP_NAME}</span>
        </a>
      </div>
      <a href="/" className="mobile-menu" id="mobile-header">
        <i className="feather icon-more-horizontal"></i>
      </a>
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto">
          <li>
            <a href="/" className="full-screen">
              <i className="feather icon-maximize"></i>
            </a>
          </li>
          <li className="nav-item">
            <div className="main-search">
              <div className="input-group">
                <input
                  type="text"
                  id="m-search"
                  className="form-control"
                  placeholder="Search . . ."
                />
                <a href="/" className="input-group-append search-close">
                  <i className="feather icon-x input-group-text"></i>
                </a>
                <span className="input-group-append search-btn btn btn-primary">
                  <i className="feather icon-search input-group-text"></i>
                </span>
              </div>
            </div>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <NotificationDropDown/>
          <UserDropDown user={user}/> 
        </ul>
      </div>
    </header>
  );
};

export default TopBar;
