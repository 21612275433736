import React, { useState } from "react";
import RejectedIcon from "../../components/RejectedIcon";
import PendingIcon from "../../components/PendingIcon";
import ApprovedIcon from "../../components/ApprovedIcon";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

const PendingPerdiemRequestsTable = ({ perdiemRequests }) => {
  const [search, setSearch] = useState('')
  return (
    <>
    <Form>
      <Form.Group className="form-group col-md-12"> 
        <Form.Control
          placeholder="Search"        
          onChange={(e)=>setSearch(e.target.value)}
        />
      </Form.Group>
    </Form>
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Date</th>
            <th>Purpose</th>
            <th>Amount</th>
            <th>GM</th>
            <th>HR</th>
            <th>CFO</th>
            <th>CEO</th>
            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {perdiemRequests && perdiemRequests.filter((perdiemRequest) => {
            return search.toLowerCase() === ''? perdiemRequest : 
            perdiemRequest.reason.toLowerCase().includes(search);
          }).map((perdiemRequest, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">
                  {new Date(perdiemRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>
                <h6 className="m-0">{perdiemRequest.reason}</h6>
              </td>
              <td> 
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: perdiemRequest.currency,
                  }).format(parseInt(perdiemRequest.total_amount))}
                </h6>
              </td>
              <td>
              {perdiemRequest.business_unit_gm_approval === "Rejected" && (
                 <RejectedIcon/>
                )}
                {perdiemRequest.business_unit_gm_approval === "Pending" && (
                  <PendingIcon/>
                )}
                {perdiemRequest.business_unit_gm_approval === "Approved" && (
                  <ApprovedIcon/>
                )}
              </td>
              <td>
              {perdiemRequest.hr_approval === "Rejected" && (
                 <RejectedIcon/>
                )}
                {perdiemRequest.hr_approval === "Pending" && (
                  <PendingIcon/>
                )}
                {perdiemRequest.hr_approval === "Approved" && (
                  <ApprovedIcon/>
                )}
              </td>
              <td>
              {perdiemRequest.cfo_approval === "Rejected" && (
                 <RejectedIcon/>
                )}
                {perdiemRequest.cfo_approval === "Pending" && (
                  <PendingIcon/>
                )}
                {perdiemRequest.cfo_approval === "Approved" && (
                  <ApprovedIcon/>
                )}
              </td>
              <td>
              {perdiemRequest.ceo_approval === "Rejected" && (
                 <RejectedIcon/>
                )}
                {perdiemRequest.ceo_approval === "Pending" && (
                  <PendingIcon/>
                )}
                {perdiemRequest.ceo_approval === "Approved" && (
                  <ApprovedIcon/>
                )}
              </td>
              <td>
                  <Link
                    to={"/perdiem_request_approval_details/" + perdiemRequest.id}
                    className="label theme-bg text-white f-12"
                  >
                    View Details
                  </Link>
                </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};

export default PendingPerdiemRequestsTable;
