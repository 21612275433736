import React from "react";
import { Link } from "react-router-dom";
import ServiceRequestsForPaymentTab from "./components/tabs/ServiceRequestsForPaymentTab";

const PayServiceRequests = () => {
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Pay Service Requests</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">Pay Service</li>
        </ul>
      </div>
      <ServiceRequestsForPaymentTab/>
    </div>
  );
};

export default PayServiceRequests;
