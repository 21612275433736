import React from "react";
import {useForm} from "react-hook-form";
import {
    NotificationContainer,
} from "react-notifications";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

import {createPerdiemRate} from "../../../../store/perdiem/thunks";

const PerdiemRatesForm = () => {
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();
    const history = useHistory();

    const onSubmit = (formData) => {
        dispatch(createPerdiemRate(formData))
        history.push("/all_perdiem_rates");
    };
    
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="row g-3">                
                              
                <div className="form-group col-md-6">
                    <label>Category</label>
                    <select
                    className="form-control"
                    {...register("category", { required: true })}
                    >
                    <option value="GM">General Managers</option>
                    <option value="SH">Section Heads</option>
                    <option value="GS">General Staff/Team Leader</option>
                    <option value="SS">Surbodinate Staff</option>
                    <option value="Others">Others</option>
                    </select>
                    {errors.category && (
                    <small className="error">Please select a  Category</small>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Local Rate</label>
                    <input
                        type={"number"}
                        className="form-control"
                        placeholder="Enter Local Rate (with in Uganda)"
                        {...register("local")}
                    />
                </div>

                <div className="form-group col-md-6">
                    <label>East Africa Rate</label>
                    <input
                        type={"number"}
                        className="form-control"
                        {...register("east_africa", {required: true, min: 1})}
                    />
                    {errors.east_africa && (
                        <small className="error">Please enter Rate for East Africa</small>
                    )}
                </div>

                <div className="form-group col-md-6">
                    <label>Africa Rate</label>
                    <input
                        type={"number"}
                        className="form-control"
                        {...register("africa", {required: true, min: 1})}
                    />
                    {errors.east_africa && (
                        <small className="error">Please enter Rate for East Africa</small>
                    )}
                </div>
                
                <div className="form-group col-md-6">
                    <label>Others (UK, UAE, US & EUROPE)</label>
                    <input
                        type={"number"}
                        className="form-control"
                        {...register("others", {required: true, min: 1})}
                    />
                    {errors.east_africa && (
                        <small className="error">Please enter Rate for Others</small>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Transport</label>
                    <input
                        type={"number"}
                        className="form-control"
                        {...register("transport", {min: 1})}
                    />
                </div>

                <div className="col-md-12">
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </div>

                <NotificationContainer/>
            </form>
        </>
    );
};


export default PerdiemRatesForm;
