import axiosInstance from "../../utils/axiosInstance"
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import {loadItemsAction} from "./actions";
import {NotificationManager} from "react-notifications";

export const loadItems = () => (dispatch)=>{
   dispatch(loadingStartedAction())
   axiosInstance.get("/api/integrations/items").then(
       res => {
           if(res.status===200) dispatch(loadItemsAction(res.data));
           dispatch(loadingEndedAction());
       }
   ).catch(e=>{dispatch(loadingEndedAction())})
}

export const UploadItems = (file) => (dispatch)=> {
    dispatch(loadingStartedAction())
    const uploadData = new FormData()
    uploadData.append('file', file[0])
    axiosInstance.post("/api/integrations/items/upload",
        uploadData
    ).then(res => {
        dispatch(loadingEndedAction())
        NotificationManager.success(
           "Successfully uploaded items from SAP excel file"
        )

    }).catch(err => NotificationManager.error(
        "Something went wrong. If the problem persists please contact Soliton BI",
        `${err.response.status} Error`
    ))
}