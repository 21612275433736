import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({resultsCount=0, handlePageClick }) => {
  const pageCount = Math.ceil(resultsCount/10)
  return (
        <nav>
        {pageCount > 1 && <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={10}
          pageCount={pageCount}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          className={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          activeClassName={"active"}
        />}
      </nav>
  )
}

export default Pagination