import axiosInstance from "../../utils/axiosInstance";
import {loadingEndedAction, loadingStartedAction} from "../isLoading/actions";
import {loadPerdiemRequestsForPaymentAction, removePerdiemRequest} from "./actions";
import {NotificationManager} from "react-notifications";
import { saveAs } from "file-saver";import { concatenatePageToUri } from "../../utils/concatenatePageToUri";

export const loadPerdiemRequestsForPayment =
  (page = 0) =>
  (dispatch) => {
    const endpoint = concatenatePageToUri(
      "/perdiem_api/perdiem_requests_for_payment",
      page
    );
    dispatch(loadingStartedAction());
    axiosInstance
      .get(endpoint)
      .then((res) => {
        if (res.status === 200)
          dispatch(loadPerdiemRequestsForPaymentAction(res.data));
        dispatch(loadingEndedAction());
      })
      .catch((err) => {
        console.log("Request Denied");
        dispatch(loadingEndedAction());
      });
  };


export const payPerdiemRequest = (perdiemRequest) => (dispatch) => {
    dispatch(loadingStartedAction())
    axiosInstance.get(`perdiem_api/pay_perdiem_request/${perdiemRequest.id}`).then(
        res => {
            NotificationManager.success(`Successfully paid the perdiem request from ${perdiemRequest.name}`)
            dispatch(removePerdiemRequest(perdiemRequest))
            dispatch(loadingEndedAction())
        }
    ).catch(
        err => {
            NotificationManager.error("Could not indicate perdiem request as paid")
            dispatch(loadingEndedAction())
        }
    )
}

export const downloadPerdiemRequestPDF = (perdiemRequest) => (dispatch) => {
    dispatch(loadingStartedAction());
    axiosInstance
      .get(`perdiem_api/perdiem_request_pdf/${perdiemRequest.id}`)
      .then((res) => {
        if (res.status === 200) {
          const {baseURL, url} = res.config
          const URL = baseURL+"/"+ url
             saveAs(
              URL,
             `perdiem_request_${perdiemRequest.id}.pdf`
      );
          NotificationManager.success(
            `Successfully downloaded perdiem request ID ${perdiemRequest.id} pdf for ${perdiemRequest.name}`
          );
        }
        dispatch(loadingEndedAction());
      })
      .catch((err) => {
        dispatch(loadingEndedAction());
      });
  };

  export const downloadPerdiemRequestsCSV = (data) => (dispatch) => {
    console.log("Data: ", data)
    dispatch(loadingStartedAction());
    axiosInstance
      .get("perdiem_api/perdiem_requests_csv",  data )
      .then((res) => {
        if (res.status === 200) {
          const {baseURL, url} = res.config
          const URL = baseURL+"/"+ url
             saveAs(
              URL
      );
          NotificationManager.success(
            `Successfully downloaded perdiem requests csv`
          );
        }
        dispatch(loadingEndedAction());
      })
      .catch((err) => {
        dispatch(loadingEndedAction());
      });
  };

