import { LOAD_PERDIEM_REQUESTS_FOR_PAYMENT, REMOVE_PERDIEM_REQUEST} from "./actions";


export const perdiemRequestsForPayment = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_PERDIEM_REQUESTS_FOR_PAYMENT:{
            return [...payload]
        }
        case REMOVE_PERDIEM_REQUEST: {
            return state.filter(perdiemRequest => perdiemRequest.id !== payload.id)
        }
        default:
            return state;
    }
}