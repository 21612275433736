import { LOAD_CASH_REQUESTS_FOR_PAYMENT, REMOVE_CASH_REQUEST} from "./actions";


export const cashRequestsForPayment = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_CASH_REQUESTS_FOR_PAYMENT:{
            return {...payload}
        }
        case REMOVE_CASH_REQUEST: {
            return {...state, results: state && state.length > 0 ? state.results.filter(cashRequest => cashRequest.id !== payload.id):[]}
        }
        default:
            return state;
    }
}