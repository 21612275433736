import { LOAD_GMUSERS } from "./actions";

export const GMUsers = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_GMUSERS:{
            return [...payload]
        }
        default:
            return state;
    }
}