import React from 'react'
import { confirm } from "react-confirm-box";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {dispatchItemRequest} from "../../../../store/itemRequestsForDispatch/thunks";

const DispatchItemRequestsTable = ({itemRequests}) => {
  const dispatch = useDispatch()
  const onClick = async (itemRequest) => {
    const result = await confirm(`Are you sure you want to indicate item request with ID ${itemRequest.id} as dispatched?`);
    if (result) {
      dispatch(dispatchItemRequest(itemRequest))
      return;
    }
    console.log("You click No!");
  };

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Beneficiary</th>
          <th>Item</th>
          <th>Quantity</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        {itemRequests.map((itemRequest, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{itemRequest.id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(itemRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>{itemRequest.name}</td>
              <td>
                <h6 className="m-0">{itemRequest.item_name}</h6>
              </td>
              <td>
                <h6 className="m-0">{itemRequest.quantity}</h6>
              </td>
              <td><Link onClick={()=> onClick(itemRequest)} className="label theme-bg text-white f-12">Dispatch</Link></td>
            </tr>
        ))}
        </tbody>
      </table>
  </div>
  )
}

export default DispatchItemRequestsTable