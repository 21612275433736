import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  loadPerdiemRequestDetail,
  removePerdiemRequestDetail,
} from "../store/perdiemRequestDetail/thunks";
import { getPerdiemRequestDetail } from "../store/perdiemRequestDetail/selectors";

import { loadPerdiemRequestApprovalDetails } from "../store/perdiemRequestApprovalDetails/thunks";
import { getPerdiemRequestApprovalDetails } from "../store/perdiemRequestApprovalDetails/selectors";
import PerdiemRequestApprovalDetailsCards from '../components/cards/PerdiemRequestApprovalDetailsCards';
import { Card } from 'react-bootstrap';

import ApprovalForm from "../components/forms/PerdiemApprovalForm";
import PerdiemRequestDetails from "./PerdiemRequestDetails";

const ApprovePerdiemRequest = () => {
  const dispatch = useDispatch()
  const { id } = useParams();
  
  const perdiemRequestApprovalDetails = useSelector(getPerdiemRequestApprovalDetails);
  useEffect(() => {
    dispatch(loadPerdiemRequestApprovalDetails(0, id));
  }, [id]);

  useEffect(() => {
    dispatch(loadPerdiemRequestDetail(id));
    return dispatch(removePerdiemRequestDetail());
  }, [id, dispatch]);
    
  const perdiemRequest = useSelector(getPerdiemRequestDetail);

  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Approve Perdiem Request</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
            <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/approve_perdiem_requests">
            Approve Perdiem Requests
            </Link>
            
          </li>
          <li className="breadcrumb-item">
            Approve Perdiem Request
          </li>
        </ul>
      </div>
      <PerdiemRequestDetails />
      <>
        {perdiemRequestApprovalDetails && perdiemRequestApprovalDetails.length > 0 ? <PerdiemRequestApprovalDetailsCards perdiemRequestApprovalDetails={perdiemRequestApprovalDetails}/>:
        <Card>
          <Card.Body>No Approvals yet</Card.Body>
        </Card>
        }  
      </>
      <div className="card">
        <div className="card-header">
          <h5>Approve/Reject Request</h5>
        </div>
        <div className="card-block">
          <ApprovalForm perdiemRequest={perdiemRequest} />
        </div>
      </div>
    </div>
  );
};

export default ApprovePerdiemRequest;
