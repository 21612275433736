import axiosInstance from "../../utils/axiosInstance";
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import { loadCashRequestsForPaymentAction, removeCashRequest } from "./actions";
import { NotificationManager } from "react-notifications";
import { concatenatePageToUri } from "../../utils/concatenatePageToUri";
import { saveAs } from "file-saver";



export const loadCashRequestsForPayment =
  (page = 0) =>
  (dispatch) => {
    const endpoint = concatenatePageToUri(
      "/api/cash_requests_for_payment",
      page
    );
    dispatch(loadingStartedAction());
    axiosInstance
      .get(endpoint)
      .then((res) => {
        if (res.status === 200)
          dispatch(loadCashRequestsForPaymentAction(res.data));
        dispatch(loadingEndedAction());
      })
      .catch((err) => {
        console.log("Request Denied");
        dispatch(loadingEndedAction());
      });
  };

export const downloadCashRequestPDF = (cashRequest) => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance
    .get(`api/cash_request_pdf/${cashRequest.id}`)
    .then((res) => {
      if (res.status === 200) {
        const {baseURL, url} = res.config
        const URL = baseURL+"/"+ url
           saveAs(
            URL,
           `cash_request_${cashRequest.id}.pdf`
    );
        NotificationManager.success(
          `Successfully downloaded cash request ID ${cashRequest.id} pdf for ${cashRequest.name}`
        );
      }
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      dispatch(loadingEndedAction());
    });
};

export const payCashRequest = (cashRequest) => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance
    .get(`api/pay_cash_request/${cashRequest.id}`)
    .then((res) => {
      NotificationManager.success(
        `Successfully paid the cash request from ${cashRequest.name}`
      );
      dispatch(removeCashRequest(cashRequest));
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      NotificationManager.error("Could not indicate cash request as paid");
      dispatch(loadingEndedAction());
    });
};

export const downloadCashRequestsCSV = (data) => (dispatch) => {
  // console.log(start_date)
  dispatch(loadingStartedAction());
  axiosInstance
    .post("api/paid_cash_requests_csv", data)
    .then((res) => {
      if (res.status === 200) {
        const {baseURL, url} = res.config
        const URL = baseURL+"/"+ url
        console.log("URL: ", URL)
           saveAs(
            URL,
           `cash_requests.csv`
    );
        NotificationManager.success(
          `Successfully downloaded cash requests csv`
        );
      }
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      dispatch(loadingEndedAction());
    });
};

