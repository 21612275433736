import React from "react";
import { useForm } from "react-hook-form";
import { NotificationContainer } from "react-notifications";
import { createFinancialInformation } from "../../../../store/financialInformation/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getGLAccounts } from "../../../../store/glAccounts/selectors";

const AddFinancialInfoForm = () => {
  const { register, handleSubmit } = useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = (data) => {
    dispatch(createFinancialInformation(data, id));
    history.push("/pay_cash");
  };

  const gl_accounts = useSelector(getGLAccounts);

  const payment_accounts = [
    {
      id: "Mobile Money",
      name: "Mobile Money",
    },
    {
      id: "Bank Transfer",
      name: "Bank Transfer",
    },
    {
      id: "Physical Cash",
      name: "Physical Cash",
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
      <input type="hidden" value={1} {...register("is_paid")} />
      <div className="form-group col-md-12">
        <label>G/L Account</label>
        <select
          className="form-control"
          {...register("gl_account", { required: true })}
        >
          <option value="">-- SELECT G/L Account ---</option>
          {gl_accounts.length > 0 &&
            gl_accounts.map((item, key) => (
              <option value={item.id} key={key}>
                {item.description}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group col-md-6">
        <label>Payment Account</label>
        <select
          className="form-control"
          {...register("payment_account", { required: true })}
        >
          <option value="">-- SELECT PAYMENT ACCOUNT ---</option>
          {payment_accounts.length > 0 &&
            payment_accounts.map((item, key) => (
              <option value={item.id} key={key}>
                {item.name}
              </option>
            ))}
        </select>
      </div>

      <div className="form-group col-md-6">
        <label>Tax</label>
        <input
          type={"number"}
          className="form-control"
          placeholder="Enter Tax"
          {...register("tax")}
        />
      </div>
      <div className="form-check form-group col-md-4">
        <input
          className="form-check-input"
          type="checkbox"
          {...register("motor_vehicle_related")}
        />
        <label className="form-check-label">Is Motor Vehicle Related</label>
      </div>
      <div className="form-check form-group col-md-4">
        <input
          className="form-check-input"
          type="checkbox"
          {...register("plant_machinery_related")}
        />
        <label className="form-check-label">Is Plant Machinery Related</label>
      </div>
      <br />
      <div className="col-md-12">
        <button type="submit" className="btn btn-primary">
          Mark As Paid
        </button>
      </div>
      <NotificationContainer />
    </form>
  );
};

export default AddFinancialInfoForm;
