import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Logout = () => {
  const history = useHistory();
  
  useEffect(() => {
    localStorage.removeItem("token");
    history.push("login")
  })
  
  return (
    <div>Logging out</div>
  )
}

export default Logout