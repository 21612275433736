import axiosInstance from "../../utils/axiosInstance";
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import {
  loadItemRequestsForApprovalAction,
  removeItemRequestForApproval,
} from "./actions";
import { NotificationManager } from "react-notifications";

export const loadItemRequestsForApproval =
  (page = 0) =>
  (dispatch) => {
    dispatch(loadingStartedAction());
    const uri = "/api/item_requests_for_approval";
    const endpoint = page ? `${uri}?page=${page + 1}` : uri;
    axiosInstance
      .get(endpoint)
      .then((res) => {
        if (res.status === 200)
          dispatch(loadItemRequestsForApprovalAction(res.data));
        dispatch(loadingEndedAction());
      })
      .catch((err) => {
        console.log("Request Denied");
        dispatch(loadingEndedAction());
      });
  };

export const approveItemRequestThunk = (itemRequest, data) => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance
    .post(`api/approve_item_request/${itemRequest.id}`, data)
    .then((res) => {
      NotificationManager.success(
        `Successfully sent your approval for item request from ${itemRequest.name}`
      );
      dispatch(removeItemRequestForApproval(itemRequest))
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      console.log("The error is here", err.response)
      NotificationManager.error("Could not approve cash request");
      dispatch(loadingEndedAction());
    });
};
