import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import axiosInstance from '../../utils/axiosInstance';
import { loadServiceRequestDetailAction, removeServiceRequestDetailAction } from "./actions";

export const loadServiceRequestDetail = (id) => (dispatch) => {
    dispatch(loadingStartedAction());
    const uri = `/perdiem_api/service_request_detail/${id}`;
    axiosInstance.get(uri).then((res) => {
      if (res.status === 200) {
        dispatch(loadServiceRequestDetailAction(res.data));
        dispatch(loadingEndedAction());
      }
      dispatch(loadingEndedAction());
    });
  };

  export const removeServiceRequestDetail = () => (dispatch)=>{
       dispatch(removeServiceRequestDetailAction());
  }