import React, { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  loadServiceRequestDetail,
  removeServiceRequestDetail,
} from "../../store/serviceRequestDetail/thunks";
import { getServiceRequestDetail } from "../../store/serviceRequestDetail/selectors";
import { RotatingLines } from "react-loader-spinner";
import ApprovalForm from "./components/forms/ApprovalForm";

const ApproveServiceRequest = () => {
  const dispatch = useDispatch()
  const { id } = useParams();

  useEffect(() => {
    dispatch(loadServiceRequestDetail(id));
    return dispatch(removeServiceRequestDetail());
  }, [id, dispatch]);
    
  const serviceRequest = useSelector(getServiceRequestDetail);

  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Approve Service Request</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
            <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/approve_service_requests">
            Approve Service Requests
            </Link>
            
          </li>
          <li className="breadcrumb-item">
            Approve Service Request
          </li>
        </ul>
      </div>
      {serviceRequest.id ? (
      <div className="card">
        <div className="card-header">
          <h5>Service Request</h5>
        </div>
        <div className="card-block">
          <div className="row d-flex align-items-center">
            <div className="col-auto">
              <i className="feather icon-user f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h3 className="f-w-300">{serviceRequest.name}</h3>
              <span className="d-block text-uppercase">Beneficiary</span>
            </div>
            <div className="col-auto">
              <i className="feather icon-credit-card f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h3 className="f-w-300">
                {serviceRequest.service} ({serviceRequest.quantity})
              </h3>
              <span className="d-block text-uppercase">Service</span>
            </div>
          </div>
        </div>
        <div className="card-block border-bottom">
          <div className="row d-flex align-items-center">
            <div className="col-auto">
              <i className="feather icon-clipboard f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h4 className="f-w-300">{serviceRequest.designation}</h4>
              <span className="d-block text-uppercase">Designation</span>
            </div>
            <div className="col">
              <h4 className="f-w-300">{serviceRequest.project_name || "N/A"}</h4>
              <span className="d-block text-uppercase">Project</span>
            </div>
            <div className="col">
              <h4 className="f-w-300">{serviceRequest.segment || "N/A"}</h4>
              <span className="d-block text-uppercase">Segment</span>
            </div>
          </div>
        </div>
        <div className="card-block border-bottom">
          <div className="row d-flex align-items-center">
            <div className="col-auto">
              <i className="feather icon-align-justify f-30 text-c-green"></i>
            </div>
            <div className="col">
              <h6 className="f-w-300">{serviceRequest.purpose}</h6>
              <span className="d-block text-uppercase">Purpose</span>
            </div>
            {serviceRequest.attachment && (
                <>
                  <div className="col-auto">
                    <i className="f-30 text-c-green fa fa-file"></i>
                  </div>
                  <div className="col">
                    <h6 className="f-w-200">
                      <a
                        href={serviceRequest.attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view.
                      </a>
                    </h6>
                    <span className="d-block text-uppercase">
                      Attachment File
                    </span>
                  </div>
                </>
              )}
          </div>
        </div>
        <div className="card-block border-bottom">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="feather icon-clock f-30 text-c-green"></i>
              </div>
                <div className="col">
                <h4 className="f-w-300">
                  {serviceRequest.date
                    ? new Date(
                        serviceRequest.date
                      ).toLocaleString()
                    : "N/A"}
                </h4>
                <span className="d-block text-uppercase">SUBMISSION TIME</span>
              </div>
              <div className="col">
                <h4 className="f-w-300">
                  {serviceRequest.business_unit_gm_approval_time
                    ? new Date(
                        serviceRequest.business_unit_gm_approval_time
                      ).toLocaleString()
                    : "N/A"}
                </h4>
                <span>
                  GM NAME: {serviceRequest.business_unit_gm_name || "N/A"}
                </span>
                <span className="d-block text-uppercase">GM APPROVAL TIME</span>
              </div>
   
            </div>
          </div>
        <div className="card-block">
            <ApprovalForm serviceRequest={serviceRequest} />
          </div>        
      </div>
      ) : (
        <div className="card">
          <div className="card-header">
            <h5>Cash Request ID {id}</h5>
          </div>
          <div className="card-block">
            <div className="row d-flex align-items-center">
              <div style={{ display: "block", width: 100, margin: "auto" }}>
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="100"
                  visible={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApproveServiceRequest;
