import {LOAD_CASH_REQUESTS_FOR_APPROVAL, REMOVE_CASH_REQUEST_FOR_APPROVAL} from "./actions";



export const cashRequestsForApproval = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_CASH_REQUESTS_FOR_APPROVAL:
            return {...payload}

        case REMOVE_CASH_REQUEST_FOR_APPROVAL:
            return {...state, results: state.results.filter(cashRequest => cashRequest.id !== payload.id)}
        default: 
            return state;
    }
}