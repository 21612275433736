export const getMenuItems = (user) => [
  {
    name: "Admin",
    icon: "feather icon-shield",
    path: "/",
    expandable: true,
    display: user.is_superuser,
    subMenuItems: [
      {
        name: "Manage Items",
        path: "/manage_items",
      }
      ]
  },
  {
    name: "Dashboard",
    icon: "feather icon-home",
    path: "/",
    expandable: false,
    display: true
  },
  {
    name: "Cash",
    icon: "feather icon-feather",
    expandable: true,
    display: true,
    subMenuItems: [
      {
        name: "Create For Self",
        path: "/new_cash_request",
      },
      {
        name: "Create For Worker",
        path: "/create_for_worker",
      },
      {
        name: "All Cash Requests",
        path: "/all_cash_requests",
      }
    ],
  },
  {
    name: "Perdiem Rates",
    icon: "feather icon-sliders",
    expandable: true,
    display: (user.is_hr),
    subMenuItems: [
      {
        name: "New Perdiem Rate",
        path: "/new_perdiem_rate",
      },
      {
        name: "Perdiems Rates",
        path: "/all_perdiem_rates",
      },      
    ],
  },
  {
    name: "Perdiem Request",
    icon: "feather icon-compass",
    expandable: true,
    display: true,
    subMenuItems: [      
      {
        name: "New Perdiem Request",
        path: "/new_perdiem_request",
      },
      {
        name: "All Perdiems Requests",
        path: "/all_perdiem_requests",
      },
    ],
  },
  {
    name: "Items",
    icon: "feather icon-layers",
    expandable: true,
    display: true,
    subMenuItems: [
      {
        name: "Create For Self",
        path: "/new_item_request",
      },
      {
        name: "All Item Requests",
        path: "/all_item_requests",
      }
    ],
  },

  {
    name: "Services",
    icon: "feather icon-cast",
    expandable: true,
    display: true,
    subMenuItems: [
      {
        name: "Request For Service",
        path: "/new_service_request",
      },
      {
        name: "All Service Requests",
        path: "/all_service_requests",
      }
    ],
  },

  {
    name: "Approvals",
    icon: "feather icon-check-square",
    expandable: true,
    display: (user.is_approver || user.is_superuser),
    subMenuItems: [
      {
        name: "Approve cash Requests",
        path: "/approve_cash_requests",
      },
      {
        name: "Approve Perdiem Requests",
        path: "/approve_perdiem_requests",
      },
      {
        name: "Approve Item Requests",
        path: "/approve_item_requests",
      },
      {
        name: "Approve Service Requests",
        path: "/approve_service_requests",
      },
    ],
  },
  {
    name: "Payments and Dispatch",
    icon: "feather icon-credit-card",
    path: "/",
    expandable: true,
    display: user.is_finance_officer,
    subMenuItems: [
      {
        name: "Pay Cash",
        path: "/pay_cash",
      },
      {
        name: "Pay Perdiem",
        path: "/pay_perdiem",
      },
      {
        name: "Pay Service",
        path: "/pay_service",
      },
      {
        name: "Dispatch Items",
        path:"/dispatch_item_requests"
      }
    ]
  },
];
