import React from "react";
import RejectedIcon from "../../../../components/RejectedIcon";
import PendingIcon from "../../../../components/PendingIcon";
import ApprovedIcon from "../../../../components/ApprovedIcon";

const PendingItemRequestsTable = ({ itemRequests }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Items</th>
            <th>Amount</th>
            <th>GM</th>
            <th>CFO</th>
            <th>CEO</th>
          </tr>
        </thead>
        <tbody>
          {itemRequests.map((itemRequest, key) => (
            <tr>
              <td>{itemRequest.id}</td>
              <td>
                <h6 className="m-0">
                  {new Date(itemRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>
                <ul>
                  {itemRequest.item_purchases.map((item_purchase) => (
                    <li>{item_purchase.item_name}</li>
                  ))}
                </ul>
              </td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: itemRequest.currency,
                  }).format(parseInt(itemRequest.amount))}
                </h6>
              </td>
              <td>
                {itemRequest.business_unit_gm_approval === "Rejected" && (
                  <RejectedIcon />
                )}
                {itemRequest.business_unit_gm_approval === "Pending" && (
                  <PendingIcon />
                )}
                {itemRequest.business_unit_gm_approval === "Approved" && (
                  <ApprovedIcon />
                )}
              </td>
              <td>
                {itemRequest.cfo_approval === "Rejected" && <RejectedIcon />}
                {itemRequest.cfo_approval === "Pending" && <PendingIcon />}
                {itemRequest.cfo_approval === "Approved" && <ApprovedIcon />}
              </td>
              <td>
                {itemRequest.ceo_approval === "Rejected" && <RejectedIcon />}
                {itemRequest.ceo_approval === "Pending" && <PendingIcon />}
                {itemRequest.ceo_approval === "Approved" && <ApprovedIcon />}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PendingItemRequestsTable;
