
import { LOAD_CASH_REQUEST_DETAIL, REMOVE_CASH_REQUEST_DETAIL } from './actions';


export const cashRequestDetail = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_CASH_REQUEST_DETAIL:
            return {...payload};

        case REMOVE_CASH_REQUEST_DETAIL: 
           return {};
           
        default:
            return state;
    }
}