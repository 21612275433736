import React from "react";
import { useForm } from "react-hook-form";
import { NotificationContainer } from "react-notifications";
import { useSelector } from "react-redux";
import { getGMUsers } from "../../../../store/GMusers/selectors";
import { getProjects } from "../../../../store/projects/selectors";
import CurrencyInput from "react-currency-input-field";
import { currencies } from '../../../../data/currency';


const CashRequestForm = ({ onSubmit, workers }) => {
  const GMusers = useSelector(getGMUsers);
  const projects = useSelector(getProjects);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const setAmount = (value, name) => {
    setValue(name, value, {
      shouldValidate: true,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
      {workers && (
        <div className="form-group col-md-12">
          <label>Worker</label>
          <select
            className="form-control"
            {...register("worker", { required: true })}
          >
            <option value="">-- SELECT WORKER ---</option>
            {workers.length > 0 &&
              workers.map((item, key) => (
                <option value={item.id} key={key}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
      )}
      <div className="form-group col-md-12">
        <label>Purpose</label>
        <textarea
          className="form-control"
          placeholder="Enter purpose"
          {...register("purpose", { required: true })}
        ></textarea>
        {errors.purpose && (
          <small className="error">Please enter the purpose</small>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>Project</label>
        <select
          className="form-control"
          {...register("project", { required: true })}
        >
          <option value="">-- SELECT PROJECT ---</option>
          {projects.length > 0 &&
            projects.map((item, key) => (
              <option value={item.id} key={key}>
                {item.name}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group col-md-4">
        <label>Segment</label>
        <input
          className="form-control"
          placeholder="Enter Segment"
          {...register("segment", {
            maxLength: {
              value: 40,
              message: "Too Many Characters",
            },
          })}
        />
        {errors.segment && (
          <small className="error">{errors.segment.message}</small>
        )}
      </div>
      <div className="form-group col-md-4">
        <label for="formFile" class="form-label">
          Evidence
        </label>
        <input
          class="form-control"
          accept=".jpg,.pdf,.png, .jpeg"
          type="file"
          {...register("evidence")}
        ></input>
      </div>
      <div className="form-group col-md-4">
        <label>Amount</label>
        <CurrencyInput
          className="form-control"
          {...register("amount", {
            required: "Amount is required",
          })}
          onValueChange={setAmount}
        ></CurrencyInput>

        {errors.amount && (
          <small className="error">
            Please enter the amount between 0 and 5,000,000
          </small>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>Currency</label>
        <select
          className="form-control"
          {...register("currency", { required: true })}
        >
        {currencies.map(currency=>
          (<option key={currency}>{currency}</option>)
          )}
        </select>
        {errors.currency && (
          <small className="error">Please select the currency</small>
        )}
      </div>
      <div className="form-group col-md-4">
        <label>Business Unit GM</label>
        <select
          className="form-control"
          {...register("business_unit_gm", { required: true })}
        >
          <option value="">-- SELECT GM ---</option>
          {GMusers &&
            GMusers.map((item, key) => (
              <option value={item.id} key={key}>
                {item.first_name} {item.last_name}
              </option>
            ))}
        </select>
        {errors.business_unit_gm && (
          <small className="error">Please select your business unit GM</small>
        )}
      </div>

      <div className="col-md-12">
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
      <NotificationContainer />
    </form>
  );
};

export default CashRequestForm;
