import { NotificationManager } from "react-notifications";
import axiosInstance from "../../utils/axiosInstance";
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import { loadPerdiemRequestsForApprovalAction, removePerdiemRequest } from "./actions";

export const loadPerdiemRequestsForApproval = 
(page = 0) =>
(dispatch) => {
  dispatch(loadingStartedAction());
  const uri = "/perdiem_api/perdiem_requests_for_approval";
  const endpoint = page ? `${uri}?page=${page + 1}` : uri;

  axiosInstance
    .get(endpoint)
    .then((res) => {
      if (res.status === 200)
        dispatch(loadPerdiemRequestsForApprovalAction(res.data));
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      dispatch(loadingEndedAction());
    });
};

export const approvePerdiemRequest = (perdiemRequest, data) => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance
    .post(`perdiem_api/approve_perdiem_request/${perdiemRequest.id}`, data)
    .then((res) => {
      NotificationManager.success(
        `Successfully approved the perdiem request from ${perdiemRequest.name}`
      );
      dispatch(removePerdiemRequest(perdiemRequest))
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      NotificationManager.error("Could not approve perdiem request");
      dispatch(loadingEndedAction());
    });
};

export const rejectPerdiemRequest = (perdiemRequest) => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance
    .get(`perdiem_api/reject_perdiem_request/${perdiemRequest.id}`)
    .then((res) => {
      NotificationManager.success(
        `Successfully rejected the perdiem request from ${perdiemRequest.name}`
      );
      dispatch(removePerdiemRequest(perdiemRequest));
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      NotificationManager.error("Something went wrong");
      dispatch(loadingEndedAction());
    });
};
