

export const LOAD_PERDIEM_REQUESTS_FOR_PAYMENT = "LOAD_PERDIEM_REQUESTS_FOR_PAYMENt"
export const loadPerdiemRequestsForPaymentAction = perdiemRequests => ({
   type: LOAD_PERDIEM_REQUESTS_FOR_PAYMENT,
   payload: perdiemRequests
})

export const REMOVE_PERDIEM_REQUEST = "REMOVE_PERDIEM_REQUEST"
export const removePerdiemRequest = perdiemRequest => ({
   type: REMOVE_PERDIEM_REQUEST,
   payload: perdiemRequest
})