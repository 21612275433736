import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import axiosInstance from '../../utils/axiosInstance';
import { loadPaidCashRequestsAction } from "./actions";



export const loadPaidCashRequests = (page=0) => (dispatch) => {
  dispatch(loadingStartedAction());
  const uri = "/api/paid_cash_requests";
  const endpoint = page ? `${uri}?page=${page + 1}` : uri;
  axiosInstance.get(endpoint).then((res) => {
    if (res.status === 200) {
      dispatch(loadPaidCashRequestsAction(res.data));
      dispatch(loadingEndedAction());
    }
    dispatch(loadingEndedAction());
  });
};