import React from "react";

const ServiceRequestsTable = ({ serviceRequests }) => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Purpose</th>
            <th>service</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {serviceRequests.map((serviceRequest, key) => (
            <tr key={key}>
              <td>{serviceRequest.id}</td>
              <td>
                <h6 className="m-0">
                  {new Date(serviceRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>
                <h6 className="m-0">{serviceRequest.purpose}</h6>
              </td>
              <td>
                <h6 className="m-0">{serviceRequest.service_name}</h6>
              </td>
              <td>
                <h6 className="m-0">{serviceRequest.quantity}</h6>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ServiceRequestsTable;
