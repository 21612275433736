import axiosInstance from "../../utils/axiosInstance";
import {loadingEndedAction, loadingStartedAction} from "../isLoading/actions";
import {loadItemRequestsForDispatchAction, removeItemRequest} from "./actions";
import {NotificationManager} from "react-notifications";


export const loadItemRequestsForDispatch = (user) => (dispatch) => {
    dispatch(loadingStartedAction());
    if (user.is_finance_officer) {
        axiosInstance
            .get("/api/item_requests_for_dispatch")
            .then((res) => {
                if (res.status === 200)
                    dispatch(loadItemRequestsForDispatchAction(res.data));
                dispatch(loadingEndedAction())
            })
            .catch((err) => {
                console.log("Request Denied")
                dispatch(loadingEndedAction())
            });
    }
};

export const dispatchItemRequest = (itemRequest) => (dispatch) => {
    dispatch(loadingStartedAction())
    axiosInstance.get(`api/dispatch_item_request/${itemRequest.id}`).then(
        res => {
            NotificationManager.success(`Successfully dispatched the item request from ${itemRequest.name}`)
            dispatch(removeItemRequest(itemRequest))
            dispatch(loadingEndedAction())
        }
    ).catch(
        err => {
            NotificationManager.error("Could not indicate item request as dispatched")
            dispatch(loadingEndedAction())
        }
    )
}




