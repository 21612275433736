import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Pagination from "../../components/Pagination";
import { getPerdiemRequestsForPayment } from '../../store/perdiemRequestsForPayment/selectors';
import useFetching from "../../hooks/useFetching";
import { loadPerdiemRequestsForPayment } from "../../store/perdiemRequestsForPayment/thunks";


const PayPerdiemRequestsTable = () => {
  useFetching(loadPerdiemRequestsForPayment)
  const perdiemRequests = useSelector(getPerdiemRequestsForPayment);
  const dispatch = useDispatch();
  const resultsCount = useSelector(
    (state) => state.perdiemRequestsForPayment.count
  );
  const handlePaginationChange = (e) => {
    dispatch(loadPerdiemRequestsForPayment(e.selected));
  };


  return perdiemRequests?(
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Beneficiary</th>
            <th>Phone Number</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {perdiemRequests.map((perdiemRequest, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{perdiemRequest.id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(perdiemRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>{perdiemRequest.name}</td>
              <td>{perdiemRequest.phone_number}</td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: perdiemRequest.currency,
                  }).format(parseInt(perdiemRequest.total_amount))}
                </h6>
              </td>
              <td>
                <Link
                  to={"/pay_perdiem_request/" + perdiemRequest.id}
                  className="label theme-bg text-white f-12"
                >
                  View to pay
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        resultsCount={resultsCount}
        handlePageClick={handlePaginationChange}
      />
    </div>
  ):(<div>No perdiem requests Available</div>);
};

export default PayPerdiemRequestsTable;
