import axiosInstance from "../../utils/axiosInstance";
import {createServiceRequestAction, loadUserServiceRequestsAction} from "./actions";
import { loadingStartedAction, loadingEndedAction } from "../isLoading/actions";
import {NotificationManager} from "react-notifications";

export const loadUserServiceRequests = () => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance.get("/perdiem_api/service_requests").then((res) => {
    if (res.status === 200) {
      dispatch(loadUserServiceRequestsAction(res.data));
      dispatch(loadingEndedAction());
    }
  });
};

export const createServiceRequest = (serviceRequest)=>(dispatch)=>{
  dispatch(loadingStartedAction());
  axiosInstance
  .post("/perdiem_api/service_requests", serviceRequest)
  .then((res) => {
    dispatch(createServiceRequestAction(res.data));
    NotificationManager.success(
      `Service Request ID ${res.data.id} successfully created`,
      "Service Request Created"
    );
    dispatch(loadingEndedAction())
  })
  .catch((err) => {
    NotificationManager.error(
      "Something went wrong. If the problem persists please contact Soliton BI",
      `${err.response.status} Error`
    );
    dispatch(loadingEndedAction())
  });
}
