import React from 'react'

const NavTabs = ({children}) => {
  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
        {children}
      </ul>
  )
}

export default NavTabs