import React from 'react'
import { Route } from "react-router-dom";
import { BrowserRouter, Switch } from "react-router-dom";
import CustomLayoutRoute from "./CustomLayoutRoute";
import InsideLayout from "./layouts/InsideLayout";
import AllCashRequests from "./pages/cashRequest/AllCashRequests";
import AllPerdiemRequests from "./pages/AllPerdiemRequests";
import Dashboard from "./pages/Dashboard";
import NewCashRequest from "./pages/cashRequest/NewCashRequest";
import NewPerdiemRequest from "./pages/NewPerdiemRequest";
import PerdiemWorkPlan from "./pages/PerdiemWorkPlan";
import NewPerdiemRate from "./pages/perdiem/NewPerdiemRate"
import PerdiemRates from "./pages/perdiem/PerdiemRates";
import Login from "./pages/Login";
import Authenticating from "./pages/Authenticating";
import Logout from "./pages/Logout";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import ApproveCashRequests from "./pages/cashRequest/ApproveCashRequests";
import ApproveCashRequest from "./pages/cashRequest/ApproveCashRequest";
import ApprovePerdiemRequests from "./pages/ApprovePerdiemRequests";
import ApprovePerdiemRequest from "./pages/ApprovePerdiemRequest";
import CashRequestForWorker from "./pages/cashRequest/CashRequestForWorker";
import NewItemRequest from "./pages/items/NewItemRequest";
import ItemRequestForWorker from "./pages/items/ItemRequestForWorker";
import AllItemRequests from "./pages/items/AllItemRequests";
import ApproveItemRequests from "./pages/items/ApproveItemRequests";
import ApproveItemRequest from "./pages/items/ApproveItemRequest";
import ManageItems from "./pages/admin/ManageItems";
import PayCashRequests from "./pages/cashRequest/PayCashRequest";
import DispatchItemRequests from "./pages/items/DispatchItemRequest";
import NewServiceRequest from "./pages/serviceRequest/NewServiceRequest";
import AllServiceRequests from "./pages/serviceRequest/AllServiceRequests";
import ApproveServiceRequests from "./pages/serviceRequest/ApproveServiceRequests";
import ApproveServiceRequest from "./pages/serviceRequest/ApproveServiceRequest";
import AddFinancialInfo from './pages/cashRequest/AddFinancialInfo';
import PayPerdiemRequests from "./pages/PayPerdiemRequest";
import PayServiceRequests from "./pages/serviceRequest/PayServiceRequest";
import AddPerdiemFinancialInfoForm from './components/forms/AddFinancialInfoForm';
import AddFinancialInfoForm from './pages/serviceRequest/AddFinancialInfo';
import CashRequestApprovalHistory from './pages/cashRequest/CashRequestApprovalHistory';
import TrackCashRequestApprovals from './pages/cashRequest/TrackCashRequestApprovals';
import PerdiemRequestApprovalDetails from './pages/PerdiemRequestApprovalDetails';


function App() {
  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <CustomLayoutRoute
              exact
              path="/manage_items"
              layout={InsideLayout}
              component={(props) => <ManageItems {...props} />}
          />
          <CustomLayoutRoute
            exact
            path="/"
            layout={InsideLayout}
            component={(props) => <Dashboard {...props} />}
          />
          <CustomLayoutRoute
            path="/new_cash_request"
            layout={InsideLayout}
            component={(props) => <NewCashRequest {...props} />}
          />
          <CustomLayoutRoute
            path="/create_for_worker"
            layout={InsideLayout}
            component={(props) => <CashRequestForWorker {...props} />}
          />
          <CustomLayoutRoute
            path="/all_cash_requests"
            layout={InsideLayout}
            component={(props) => <AllCashRequests {...props} />}
          />
          <CustomLayoutRoute
              path="/new_item_request"
              layout={InsideLayout}
              component={(props) => <NewItemRequest {...props} />}
          />
          <CustomLayoutRoute
              path="/create_item_request_for_worker"
              layout={InsideLayout}
              component={(props) => <ItemRequestForWorker {...props} />}
          />
          <CustomLayoutRoute
              path="/new_service_request"
              layout={InsideLayout}
              component={(props) => <NewServiceRequest {...props} />}
          />
          <CustomLayoutRoute
              path="/all_item_requests"
              layout={InsideLayout}
              component={(props) => <AllItemRequests {...props} />}
          />
          <CustomLayoutRoute
              path="/all_service_requests"
              layout={InsideLayout}
              component={(props) => <AllServiceRequests {...props} />}
          />
          <CustomLayoutRoute
            path="/new_perdiem_request"
            layout={InsideLayout}
            component={(props) => <NewPerdiemRequest {...props} />}
          />
          <CustomLayoutRoute
            path="/all_perdiem_requests"
            layout={InsideLayout}
            component={(props) => <AllPerdiemRequests {...props} />}
          />
          <CustomLayoutRoute
            path="/new_perdiem_rate"
            layout={InsideLayout}
            component={(props) => <NewPerdiemRate {...props} />}
          />
          <CustomLayoutRoute
            path="/all_perdiem_rates"
            layout={InsideLayout}
            component={(props) => <PerdiemRates {...props} />}
          />
          <CustomLayoutRoute
            path="/approve_cash_requests"
            layout={InsideLayout}
            component={(props) => <ApproveCashRequests {...props} />}
          />
          <CustomLayoutRoute
              path="/pay_cash"
              layout={InsideLayout}
              component={(props) => <PayCashRequests {...props} />}
          />
          <CustomLayoutRoute
            path="/approve_cash_request/:id"
            layout={InsideLayout}
            component={(props) => <ApproveCashRequest {...props} />}
          />
          
          <CustomLayoutRoute
            path="/pay_cash_request/:id"
            layout={InsideLayout}
            component={(props) => <AddFinancialInfo {...props} />}
          />

          <CustomLayoutRoute
              path="/approve_item_requests"
              layout={InsideLayout}
              component={(props) => <ApproveItemRequests {...props} />}
          />
          <CustomLayoutRoute
              path="/approve_item_request/:id"
              layout={InsideLayout}
              component={(props) => <ApproveItemRequest {...props} />}
          />
          <CustomLayoutRoute
              path="/dispatch_item_requests"
              layout={InsideLayout}
              component={(props) => <DispatchItemRequests {...props} />}
          />
          <CustomLayoutRoute
              path="/approve_service_requests"
              layout={InsideLayout}
              component={(props) => <ApproveServiceRequests {...props} />}
          />
          <CustomLayoutRoute
              path="/approve_service_request/:id"
              layout={InsideLayout}
              component={(props) => <ApproveServiceRequest {...props} />}
          />
          <CustomLayoutRoute
            path="/approve_perdiem_requests"
            layout={InsideLayout}
            component={(props) => <ApprovePerdiemRequests {...props} />}
          />
          <CustomLayoutRoute
            path="/approve_perdiem_request/:id"
            layout={InsideLayout}
            component={(props) => <ApprovePerdiemRequest {...props} />}
          />
          <CustomLayoutRoute
            path="/perdiem_workplan/:id"
            layout={InsideLayout}
            component={(props) => <PerdiemWorkPlan {...props} />}
          /> 
          <CustomLayoutRoute
              path="/pay_perdiem"
              layout={InsideLayout}
              component={(props) => <PayPerdiemRequests {...props} />}
          /> 
          <CustomLayoutRoute
            path="/pay_perdiem_request/:id"
            layout={InsideLayout}
            component={(props) => <AddPerdiemFinancialInfoForm {...props} />}
          />
          <CustomLayoutRoute
              path="/pay_service"
              layout={InsideLayout}
              component={(props) => <PayServiceRequests {...props} />}
          />    
          <CustomLayoutRoute
            path="/pay_service_request/:id"
            layout={InsideLayout}
            component={(props) => <AddFinancialInfoForm {...props} />}
          />   
          <CustomLayoutRoute
            path="/perdiem_request_approval_details/:id"
            layout={InsideLayout}
            component={(props) => <PerdiemRequestApprovalDetails {...props} />}
          />            
          <CustomLayoutRoute
            path="/cash_request/track_approvals/:id"
            layout={InsideLayout}
            component={(props) => <TrackCashRequestApprovals {...props} />}
          />              
          <CustomLayoutRoute
              path="/cashrequest_approval_history"
              layout={InsideLayout}
              component={(props) => <CashRequestApprovalHistory {...props} />}
          />  
          <Route path="/login" component={(props) => <Login {...props} />} />
          <Route
            path="/oauth/callback"
            component={(props) => <Authenticating {...props} />}
          />
          <Route path="/logout" component={Logout} />
        </Switch>
      </BrowserRouter>
      <NotificationContainer />
    </>
  );
}


export default App;
