import React from "react";
import axiosInstance from "../../../../utils/axiosInstance";
import AsyncSelect from "react-select/async";

const SelectItemToRequestField = ({ optionChange, id }) => {
  const transformItemsToOptions = (items) =>
    items.map((item) => ({ value: item.id, label: item.description, id: id, price: item.price }));

  const promiseOptions = (inputValue) => {
    return axiosInstance
      .get(`/api/integrations/items?search=${inputValue}`)
      .then((res) => transformItemsToOptions(res.data));
  };

  return (
    <div className="form-group col-md-6">
      <label>Item</label>
      <AsyncSelect
        innerRef={{"className": "form-control"}}
        cacheOptions
        defaultOptions
        onChange={optionChange}
        loadOptions={promiseOptions}
      />
    </div>
  );
};

export default SelectItemToRequestField;
