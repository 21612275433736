import TimeAgo from "javascript-time-ago";
import React from "react";
import en from 'javascript-time-ago/locale/en.json';
TimeAgo.addDefaultLocale(en);

const NotificationListItem = ({ notification }) => {
  const { verb, timestamp } = notification;
  
  return (
    <li className="notification">
      <div className="media">
        <div className="media-body">
          <p>
            <strong>{}</strong>
            <span className="n-time text-muted">
              <i className="icon feather icon-clock m-r-10"></i>
              {new TimeAgo('en-US').format(new Date(timestamp))}
            </span>
          </p>

          <p>{verb}</p>
        </div>
      </div>
    </li>
  );
};

export default NotificationListItem;
