export const LOAD_PAID_PERDIEM_REQUESTS = "LOAD_PAID_PERDIEM_REQUESTS"
export const loadPaidPerdiemRequestsAction = perdiemRequestsPagination => ({
   type: LOAD_PAID_PERDIEM_REQUESTS,
   payload: perdiemRequestsPagination
})

export const LOAD_PAID_PERDIEM_REQUESTS_CSV = "LOAD_PAID_PERDIEM_REQUESTS_CSV"
export const loadPaidPerdiemRequestsCsvAction = perdiemRequestsPagination => ({
   type: LOAD_PAID_PERDIEM_REQUESTS_CSV,
   payload: perdiemRequestsPagination
})