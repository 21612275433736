import React from "react";

const PendingIcon = () => {
  return (
    <span className="pcoded-micon">
      <i className="m-0 feather icon-loader"></i>
    </span>
  );
};

export default PendingIcon;
