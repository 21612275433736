export const CREATE_PERDIEM_REQUEST = 'CREATE_PERDIEM_REQUEST';
export const createPerdiemRequestAction = perdiemRequest =>({
    type: CREATE_PERDIEM_REQUEST,
    payload: {perdiemRequest}
});

export const LOAD_USER_PERDIEM_REQUESTS = "LOAD_USER_PERDIEM_REQUESTS"
export const loadUserPerdiemRequestsAction = perdiemRequests => ({
   type: LOAD_USER_PERDIEM_REQUESTS,
   payload: perdiemRequests
})