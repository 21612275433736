import React from "react";
import { useDispatch } from "react-redux";
import Pagination from "../../components/Pagination";
import { loadPerdiemRequestApprovals } from '../../store/perdiemRequestApprovals/thunks';
import { getPerdiemRequestApprovals } from '../../store/perdiemRequestApprovals/selectors';
import useFetching from "../../hooks/useFetching";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PerdiemRequestApprovalsTable = () => {
  useFetching(loadPerdiemRequestApprovals);
  const perdiemRequestApprovals = useSelector(getPerdiemRequestApprovals);
  const resultsCount = useSelector(
    (state) => state.perdiemRequestApprovals.count
  );
  const dispatch = useDispatch()
  const handlePageClick = (e) => {
     dispatch(loadPerdiemRequestApprovals(e.selected));
  };
  return perdiemRequestApprovals?(
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Timestamp</th>
            <th>Beneficiary</th>
            <th>Amount</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {perdiemRequestApprovals.map((perdiemRequestApproval, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{perdiemRequestApproval.perdiem_request_id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(perdiemRequestApproval.date).toLocaleString()}
                </h6>
              </td>
              <td>{perdiemRequestApproval.beneficiary}</td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: perdiemRequestApproval.currency,
                  }).format(parseInt(perdiemRequestApproval.total_amount))}
                </h6>
              </td>
              <td>
                <Link
                    to={"/perdiem_request_approval_details/" + perdiemRequestApproval.perdiem_request_id}
                    className="label theme-bg text-white f-12"
                  >
                    View Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
     <Pagination resultsCount={resultsCount} handlePageClick={handlePageClick}/>
    </div>
  ):(<div>No perdiem request approvals Yet</div>);
};

export default PerdiemRequestApprovalsTable;
