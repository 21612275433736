import axiosInstance from "../../utils/axiosInstance";
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import {loadServiceRequestsForApprovalAction, removeServiceRequest} from "./actions";
import {NotificationManager} from "react-notifications";

export const loadServiceRequestsForApproval = 
(page = 0) =>
(dispatch) => {
  dispatch(loadingStartedAction());
  const uri = "/perdiem_api/service_requests_for_approval";
  const endpoint = page ? `${uri}?page=${page + 1}` : uri;

  axiosInstance
    .get(endpoint)
    .then((res) => {
      if (res.status === 200)
        dispatch(loadServiceRequestsForApprovalAction(res.data));
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
        console.log("Request is denied")
      dispatch(loadingEndedAction());
    });
};

export const approveServiceRequest = (serviceRequest, data) => (dispatch) => {
    dispatch(loadingStartedAction())
    axiosInstance
    .post(`perdiem_api/approve_service_request/${serviceRequest.id}`, data).then(
        (res) => {
            NotificationManager.success(`Successfully reacted the service request from ${serviceRequest.name}`)
            dispatch(removeServiceRequest(serviceRequest))
            dispatch(loadingEndedAction())
        }
    ).catch(
        err => {
            NotificationManager.error("Could not approve service request", err.message)
            dispatch(loadingEndedAction())
        }
    )
}

export const rejectServiceRequest = (serviceRequest) => (dispatch) => {
    dispatch(loadingStartedAction())
    axiosInstance.post(`perdiem_api/reject_service_request/${serviceRequest.id}`).then(
        res => {
            NotificationManager.success(`Successfully rejected the service request from ${serviceRequest.name}`)
            dispatch(removeServiceRequest(serviceRequest))
            dispatch(loadingEndedAction())
        }
    ).catch(
        err => {
            NotificationManager.error("Something went wrong")
            dispatch(loadingEndedAction())
        }
    )

}
