import React from "react";
import { Link } from "react-router-dom";
import ItemRequestTab from "./components/tabs/ItemRequestTab";
import { useSelector } from 'react-redux';
import { getUserItemRequests } from "../../store/itemRequests/selectors";
import useFetching from "../../hooks/useFetching";
import { loadUserItemRequests } from "../../store/itemRequests/thunks";


const AllItemRequests = () => {
  useFetching(loadUserItemRequests);
  const itemRequests = useSelector(getUserItemRequests)
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">All Item Requests</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">All Item Requests</li>
        </ul>
      </div>
      {itemRequests && <ItemRequestTab />}
    </div>
  );
};

export default AllItemRequests;
