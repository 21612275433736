import * as yup from "yup";

export const perdiemRequestSchema = yup.object().shape({
  project: yup.string().trim().required("Project is required"),
  date: yup.date("A valid date type is required").required("Start Date is required"),
  section: yup.string().trim().max(40, "Section requires up to 40 characters").required("Section is required"),
  destination_category: yup.string().trim().required("Destination Category is required"),
  destination: yup.string().trim().required("Destination is required"),
  no_of_days: yup.string().trim().required("Number of days is required"),
  reason: yup.string().trim().required("Reason is required"),
  payment_mode: yup.string().trim().required("Payment mode is required"),
  accomodation_rate: yup.string().trim().optional(),
  transport: yup.string().trim().optional(),
  no_of_nights: yup.string().trim().optional(),
  phone_number: yup
    .string()
    .trim()
    .matches(
      /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      "Phone number is not valid"
    )
});



