import React from "react";
import { Card } from 'react-bootstrap';

const PerdiemRequestApprovalDetailsCards = ({ perdiemRequestApprovalDetails }) => {
  return (
    <>
      {perdiemRequestApprovalDetails &&
        perdiemRequestApprovalDetails.map((perdiemRequestApprovalDetail, key) => (
          <Card key={key}>
            <Card.Header className="d-block text-uppercase">
              Approver({perdiemRequestApprovalDetail.approver_role}): {perdiemRequestApprovalDetail.approver_name}
            </Card.Header>
            <div className="card-block border-bottom">
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                  <i className="feather icon-clock f-30 text-c-green"></i>
                </div>
                <div className="col">
                  <h4 className="f-w-300">
                    {perdiemRequestApprovalDetail.date
                      ? new Date(perdiemRequestApprovalDetail.date).toLocaleString()
                      : "N/A"}
                  </h4>
                  <span className="d-block text-uppercase">TIMESTAMP</span>
                </div>
                <div className="col-auto">
                  {perdiemRequestApprovalDetail.is_approved ? (
                    <i className="feather icon-check f-30 text-c-green"></i>
                  ) : (
                    <i className="feather icon-x f-30 text-c-red"></i>
                  )}
                </div>
                <div className="col">
                  <h4 className="f-w-300">
                    {perdiemRequestApprovalDetail.is_approved ? "APPROVED" : "REJECTED"}
                  </h4>
                  <span>APPROVAL</span>
                </div>
              </div>
            </div>
            <div className="card-block border-bottom">
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                  <i className="feather icon-message-square f-30 text-c-green"></i>
                </div>
                <div className="col">
                  <h4 className="f-w-300">
                    {perdiemRequestApprovalDetail.comment || "N/A"}
                  </h4>
                  <span className="d-block text-uppercase">COMMENT</span>
                </div>
              </div>
            </div>
          </Card>
        ))}
    </>
  );
};

export default PerdiemRequestApprovalDetailsCards;
