import React from "react";

const NavTab = ({name,color,children, show}) => {
  const classes = show ? "active show" : "" 
  const selected = show ? "true" : "false"
  return (
    <li className="nav-item">
      <a
        className={`nav-link ${classes}`}
        id={`${name}-tab`}
        data-toggle="tab"
        href={`#${name}`}
        role="tab"
        aria-controls={`${name}`}
        aria-selected={selected}
        style={{ color }}
      >
        {children}
      </a>
    </li>
  );
};

export default NavTab;
