import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  loadCashRequestDetail,
  removeCashRequestDetail,
} from "../../store/cashRequestDetail/thunks";
import { getCashRequestDetail } from "../../store/cashRequestDetail/selectors";
import { RotatingLines } from "react-loader-spinner";

const CashRequestDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const cashRequest = useSelector(getCashRequestDetail);

  useEffect(() => {
    dispatch(loadCashRequestDetail(id));
    return dispatch(removeCashRequestDetail());
  }, [id, dispatch]);

  return (
    <>
    {cashRequest.id ? (
        <div className="card">
          <div className="card-header">
            <h5>Cash Request ID {id}</h5>
          </div>
          <div className="card-block">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="feather icon-user f-30 text-c-green"></i>
              </div>
              <div className="col">
                <h3 className="f-w-300">{cashRequest.name}</h3>
                <span className="d-block text-uppercase">Beneficiary</span>
              </div>
              <div className="col-auto">
                <i className="feather icon-credit-card f-30 text-c-green"></i>
              </div>
              <div className="col">
                <h3 className="f-w-300">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: cashRequest.currency || "UGX",
                  }).format(parseInt(cashRequest.amount))}
                </h3>
                <span className="d-block text-uppercase">Amount</span>
              </div>
            </div>
          </div>
          <div className="card-block border-bottom">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="feather icon-clipboard f-30 text-c-green"></i>
              </div>
              <div className="col">
                <h4 className="f-w-300">{cashRequest.designation}</h4>
                <span className="d-block text-uppercase">Designation</span>
              </div>
              <div className="col-auto">
                <i className="f-30 text-c-green fa fa-briefcase"></i>
              </div>
              <div className="col">
                <h4 className="f-w-300">{cashRequest.project_name || "N/A"}</h4>
                <span className="d-block text-uppercase">Project</span>
              </div>
              <div className="col">
                <h4 className="f-w-300">{cashRequest.segment || "N/A"}</h4>
                <span className="d-block text-uppercase">Segment</span>
              </div>
            </div>
          </div>
          <div className="card-block border-bottom">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="feather icon-align-justify f-30 text-c-green"></i>
              </div>
              <div className="col">
                <h6 className="f-w-300">{cashRequest.purpose}</h6>
                <span className="d-block text-uppercase">Purpose</span>
              </div>
              {cashRequest.evidence && (
                <>
                  <div className="col-auto">
                    <i className="f-30 text-c-green fa fa-file"></i>
                  </div>
                  <div className="col">
                    <h6 className="f-w-200">
                      <a
                        href={cashRequest.evidence}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view.
                      </a>
                    </h6>
                    <span className="d-block text-uppercase">
                      Evidence File
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="card-block border-bottom">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="feather icon-clock f-30 text-c-green"></i>
              </div>
              <div className="col">
                <h4 className="f-w-300">
                  {cashRequest.date
                    ? new Date(cashRequest.date).toLocaleString()
                    : "N/A"}
                </h4>
                <span className="d-block text-uppercase">SUBMISSION TIME</span>
              </div>
              <div className="col">
                <h4 className="f-w-300">
                  {cashRequest.business_unit_gm_approval_time
                    ? new Date(
                        cashRequest.business_unit_gm_approval_time
                      ).toLocaleString()
                    : "N/A"}
                </h4>
                <span>
                  GM NAME: {cashRequest.business_unit_gm_name || "N/A"}
                </span>
                <span className="d-block text-uppercase">GM APPROVAL TIME</span>
              </div>
            </div>
          </div>
          
        </div>
      ) : (
        <div className="card">
          <div className="card-header">
            <h5>Cash Request ID {id}</h5>
          </div>
          <div className="card-block">
            <div className="row d-flex align-items-center">
              <div style={{ display: "block", width: 100, margin: "auto" }}>
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="100"
                  visible={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CashRequestDetails;
