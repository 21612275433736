import React from "react";
import { Link } from "react-router-dom";
import Pagination from "../../../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getItemRequestsForApprovalCount } from "../../../../store/itemRequestsForApproval/selectors";
import { loadItemRequestsForApproval } from "../../../../store/itemRequestsForApproval/thunks";

const ApproveItemRequestsTable = ({ itemRequests }) => {
  const resultsCount = useSelector(getItemRequestsForApprovalCount);
  const dispatch = useDispatch();
  const dispatchPagination = (e) => {
    dispatch(loadItemRequestsForApproval(e.selected));
  };
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Beneficiary</th>
            <th>Purpose</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {itemRequests.map((itemRequest, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{itemRequest.id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(itemRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>{itemRequest.name}</td>
              <td>
                <h6 className="m-0">{itemRequest.purpose}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: itemRequest.currency,
                  }).format(parseInt(itemRequest.amount))}
                </h6>
              </td>
              <td>
                <Link
                  to={"/approve_item_request/" + itemRequest.id}
                  className="label theme-bg text-white f-12"
                >
                  View to Approve
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        resultsCount={resultsCount}
        handlePageClick={dispatchPagination}
      />
    </div>
  );
};

export default ApproveItemRequestsTable;
