import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const ExpandableMenuListItem = ({ itemData, subMenuItems }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    if (!isExpanded) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  };
  let expandedClass = isExpanded ? "pcoded-trigger" : "";
  let displayValue = isExpanded ? "block" : "none";

  const { pathname } = useLocation();
  

  return (
    itemData.display ? <li className={"nav-item pcoded-hasmenu " + expandedClass}>
      <a href="/" className="nav-link" onClick={handleClick}>
        <span className="pcoded-micon">
          <i className={itemData.icon}></i>
        </span>
        <span className="pcoded-mtext">{itemData.name}</span>
      </a>
      <ul className="pcoded-submenu" style={{ display: displayValue }}>
        {subMenuItems &&
          subMenuItems.map((item, key) => (
            <li key={key} className={pathname === item.path? "active" : ""}>
              <Link to={item.path}>{item.name}</Link>
            </li>
          ))}
      </ul>
    </li>: null
  );
};

export default ExpandableMenuListItem;
