import React from "react";
import { useSelector } from "react-redux";
import PerdiemRatesTable from "../tables/perdiemRatesTable";

import {getPerdiemRates} from "../../../../store/perdiem/selectors";
import useFetching from '../../../../hooks/useFetching';
import { loadPerdiemRates } from '../../../../store/perdiem/thunks';

const PerdiemRatesTab = () => {
  useFetching(loadPerdiemRates)
  const perdiemRates = useSelector(getPerdiemRates);
  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active show"
            id="perdiem-rate-tab"
            data-toggle="tab"
            href="#pediem-rates"
            role="tab"
            aria-controls="pediem-rates"
            aria-selected="true"
            style={{ color: "blue" }}
          >
            Perdiem Rates
          </a>
        </li>        
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade active show"
          id="perdiem-rates"
          role="tabpanel"
          aria-labelledby="perdiem-rates-tab"
        >
          {perdiemRates.length > 0 ? (
            <PerdiemRatesTable perdiemRates={perdiemRates} />
          ) : (
            <div>No Perdiem Rate available</div>
          )}
        </div>        
      </div>
    </>
  );
};

export default PerdiemRatesTab;
