import React from "react";
import { useSelector } from "react-redux";
import ItemRequestsTable from "../tables/ItemRequestsTable";
import PendingItemRequestsTable from "../tables/PendingItemRequestsTable";
import {
  getApprovedUserItemRequests,
  getPendingUserItemRequests,
  getRejectedUserItemRequests,
  getUserItemRequestsCount
} from "../../../../store/itemRequests/selectors";
import Pagination from "../../../../components/Pagination";
import { useDispatch } from "react-redux";
import { loadUserItemRequests } from "../../../../store/itemRequests/thunks";

const ItemRequestTab = () => {
  const pendingItemRequests = useSelector(getPendingUserItemRequests);
  const approvedItemRequests = useSelector(getApprovedUserItemRequests);
  const rejectedItemRequests = useSelector(getRejectedUserItemRequests);
  const resultsCount = useSelector(getUserItemRequestsCount)
  const dispatch = useDispatch()
  const dispatchPagination = (e)=> {
       dispatch(loadUserItemRequests(e.selected))
  }
  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active show"
            id="pending-approval-tab"
            data-toggle="tab"
            href="#pending-approval"
            role="tab"
            aria-controls="pending-approval"
            aria-selected="true"
            style={{ color: "blue" }}
          >
            Pending Approval
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="profile-tab"
            data-toggle="tab"
            href="#profile"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            style={{ color: "green" }}
          >
            Approved
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="contact-tab"
            data-toggle="tab"
            href="#contact"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
            style={{ color: "red" }}
          >
            Rejected
          </a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade active show"
          id="pending-approval"
          role="tabpanel"
          aria-labelledby="pending-approval-tab"
        >
          {pendingItemRequests.length > 0 ? (
            <PendingItemRequestsTable itemRequests={pendingItemRequests} />
          ) : (
            <div>No Item Requests Pending Approval</div>
          )}
        </div>
        <div
          className="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          {approvedItemRequests.length > 0 ? (
            <ItemRequestsTable itemRequests={approvedItemRequests} />
          ) : (
            <div>No Approved Item Requests yet</div>
          )}
        </div>
        <div
          className="tab-pane fade"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          {rejectedItemRequests.length > 0 ? (
            <ItemRequestsTable itemRequests={rejectedItemRequests} />
          ) : (
            <div>No Rejected Item Requests Yet</div>
          )}
        </div>
        <Pagination resultsCount={resultsCount} handlePageClick={dispatchPagination}/>
      </div>
    </>
  );
};

export default ItemRequestTab;
