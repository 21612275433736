import React from 'react';
import {NotificationContainer} from "react-notifications";
import {useForm} from "react-hook-form";
import {UploadItems} from "../../../../store/items/thunks";
import {useDispatch} from "react-redux";


const UploadItemsForm = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();
    const dispatch = useDispatch();
    const onSubmit = ({file}) => {
      dispatch(UploadItems(file))
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
            <div className="form-group col-md-12">
                <label>Upload Excel File</label>
                <input className="form-control" type="file"
                       {...register("file")}
                       accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                {errors.file && (
                    <small className="error">Please select the excel file</small>
                )}
            </div>
            <div className="col-md-12">
                <button type="submit" className="btn btn-primary">
                    Submit
                </button>
            </div>
            <NotificationContainer/>
        </form>
    );
};

export default UploadItemsForm;