export const CREATE_PERDIEM_RATE = 'CREATE_PERDIEM_RATE';
export const createPerdiemRateAction = perdiemRate =>({
    type: CREATE_PERDIEM_RATE,
    payload: {perdiemRate}
});

export const LOAD_PERDIEM_RATES = "LOAD_PERDIEM_RATES"
export const loadPerdiemRatesAction = perdiemRates => ({
   type: LOAD_PERDIEM_RATES,
   payload: perdiemRates
})

export const LOAD_USER_PERDIEM_RATES = "LOAD_USER_PERDIEM_RATES"
export const loadUserPerdiemRatesAction = perdiemRates => ({
   type: LOAD_USER_PERDIEM_RATES,
   payload: perdiemRates
})