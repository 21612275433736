
import { LOAD_SERVICE_REQUEST_DETAIL, REMOVE_SERVICE_REQUEST_DETAIL } from './actions';


export const serviceRequestDetail = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_SERVICE_REQUEST_DETAIL:
            return {...payload};

        case REMOVE_SERVICE_REQUEST_DETAIL: 
           return {};
           
        default:
            return state;
    }
}