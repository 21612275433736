import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../components/Pagination";
import useFetching from "../../hooks/useFetching";
import { loadPerdiemRequestsForApproval } from "../../store/perdiemRequestsForApproval/thunks";
import { loadProjects } from "../../store/projects/thunks";
import { getPerdiemRequestsForApproval } from '../../store/perdiemRequestsForApproval/selectors';

const ApprovePerdiemRequestsTable = () => {
  useFetching(loadPerdiemRequestsForApproval)
  useFetching(loadProjects)
  const perdiemRequests = useSelector(getPerdiemRequestsForApproval);
  
  const resultsCount = useSelector(
    (state) => state.perdiemRequestsForApproval.count
  );
  
  const dispatch = useDispatch()
  const handlePageClick = (e) => {
    dispatch(loadPerdiemRequestsForApproval(e.selected));
 };
  return perdiemRequests ? (
    <div className="table-responsive">
    <table className="table table-hover">
    <thead>
      <tr>
        <th>Date</th>
        <th>Applicant</th>
        <th>Destination</th>
        <th>Reason</th>
        <th>Amount</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {perdiemRequests.map((perdiemRequest, key) => (
        <tr key={key}>
          <td>
            <h6 className="m-0">
              {new Date(perdiemRequest.date).toLocaleDateString("en-UK")}
            </h6>
          </td>
          <td>{perdiemRequest.name}</td>
          <td>{perdiemRequest.destination}</td>
          <td>
            <h6 className="m-0">{perdiemRequest.reason}</h6>
          </td>
          <td>
              <h6 className="m-0">
                {new Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: perdiemRequest.currency ,
                }).format(parseInt(perdiemRequest.total_amount))}
              </h6>
            </td>
          <td><Link to={"/approve_perdiem_request/" + perdiemRequest.id} className="label theme-bg text-white f-12">View to Approve</Link></td>
        </tr>
      ))}
    </tbody>
  </table>
  <Pagination resultsCount={resultsCount} handlePageClick={handlePageClick}/>
  </div>
  ):(<div>No perdiem requests for approvals Available</div>);
}

export default ApprovePerdiemRequestsTable