import React from "react";
import { useForm } from "react-hook-form";
import { NotificationContainer } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGMUsers } from "../../../../store/GMusers/selectors";
import { getProjects } from "../../../../store/projects/selectors";
import { createServiceRequest } from "../../../../store/serviceRequests/thunks";
import { toFormData } from "../../../../utils/toFormData";

const ServiceRequestForm = () => {
  const GMusers = useSelector(getGMUsers);
  const projects = useSelector(getProjects);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  const onSubmit = (data) => {
    const file = data.attachment[0];
    const form_data = toFormData(data);
    file
      ? form_data.append("attachment", file)
      : form_data.delete("attachment");
    dispatch(createServiceRequest(form_data));
    history.push("/all_service_requests");
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
        <div className="form-group col-md-12">
          <label>Service</label>
          <input
            className="form-control"
            placeholder="Enter Service"
            {...register("service")}
          />
        </div>

        <div className="form-group col-md-12">
          <label>Purpose</label>
          <textarea
            className="form-control"
            placeholder="Enter purpose"
            {...register("purpose", { required: true })}
          ></textarea>
          {errors.purpose && (
            <small className="error">Please enter the purpose</small>
          )}
        </div>
        <div className="form-group col-md-4">
          <label>Project</label>
          <select
            className="form-control"
            {...register("project", { required: false })}
          >
            <option value="">-- SELECT PROJECT ---</option>
            {projects.length > 0 &&
              projects.map((item, key) => (
                <option value={item.id} key={key}>
                  {item.name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group col-md-4">
          <label>Segment</label>
          <input
            className="form-control"
            placeholder="Enter Segment"
            {...register("segment")}
          />
        </div>
        <div className="form-group col-md-4">
          <label for="formFile" className="form-label">
            Attachment
          </label>
          <input
            class="form-control"
            accept=".jpg,.pdf,.png, .jpeg"
            type="file"
            {...register("attachment")}
          ></input>
        </div>
        <div className="form-group col-md-4">
          <label>Quantity</label>
          <input
            type={"number"}
            className="form-control"
            {...register("quantity", { required: true, min: 1 })}
          />
          {errors.quantity && (
            <small className="error">Please enter quantity</small>
          )}
        </div>

        <div className="form-group col-md-4">
          <label>Unit Price</label>
          <input
            type={"number"}
            className="form-control"
            {...register("unit_price", { required: true, min: 1 })}
          />
          {errors.unit_price && (
            <small className="error">Please enter Unit Price</small>
          )}
        </div>
        <div className="form-group col-md-4">
          <label>Currency</label>
          <select
            className="form-control"
            {...register("currency", { required: true })}
          >
            <option value="">-- SELECT CURRENCY ---</option>
            <option value="USD">USD</option>
            <option value="UGX">UGX</option>
            <option value="CDF">CDF</option>
          </select>
          {errors.currency && (
            <small className="error">Please select currency</small>
          )}
        </div>

        <div className="form-group col-md-12">
          <label>Business Unit GM</label>
          <select
            className="form-control"
            {...register("business_unit_gm", { required: true })}
          >
            <option value="">-- SELECT GM ---</option>
            {GMusers &&
              GMusers.map((item, key) => (
                <option value={item.id} key={key}>
                  {item.first_name} {item.last_name}
                </option>
              ))}
          </select>
          {errors.business_unit_gm && (
            <small className="error">Please select your business unit GM</small>
          )}
        </div>

        <div className="col-md-12">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>

        <NotificationContainer />
      </form>
    </>
  );
};

export default ServiceRequestForm;
