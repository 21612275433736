export const CREATE_CASH_REQUEST = 'CREATE_CASH_REQUEST';
export const createCashRequestAction = cashRequest =>({
    type: CREATE_CASH_REQUEST,
    payload: {cashRequest}
});

export const LOAD_USER_CASH_REQUESTS = "LOAD_USER_CASH_REQUESTS"
export const loadUserCashRequestsAction = cashRequestsPagination => ({
   type: LOAD_USER_CASH_REQUESTS,
   payload: cashRequestsPagination
})





