import React, {useState } from "react";
import { useDispatch } from 'react-redux';
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { downloadCashRequestsCSV } from "../../../../store/cashRequestsForPayment/thunks";
// import { Button } from "bootstrap";



const FilterForm = () =>  {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const {        
        handleSubmit,
        register,
    } = useForm();

    const dispatch = useDispatch();  
        
    const handleStartDateChange = (date) => {
    setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
   
    const onSubmit = (data) => {
        dispatch(downloadCashRequestsCSV(data));
        // history.push("/pay_cash");
      
    };
    
    return (
        <div className="card">
            <div className="card-header">
            <h5>Filter</h5>
            </div>
            <div className="card-block">
            <form onSubmit={handleSubmit(onSubmit)} className="row g-3">
                <div className="form-group col-md-4">
                    <label>From Date</label>        
                    <DatePicker 
                    name="start_date"
                    className="form-control"
                    placeholder="From Date" 
                    {...register("start_date")}            
                    selected={startDate} 
                    onChange={handleStartDateChange}
                    />               
                    
                </div>
                <div className="form-group col-md-4">
                    <label>To Date</label>        
                    <DatePicker 
                    name="end_date"
                    className="form-control"
                    placeholder="To Date" 
                    {...register("end_date")}            
                    selected={endDate} 
                    onChange={handleEndDateChange}
                    />               
                    
                </div>
                <div className="col-md-12">
                    <button type="submit" className="btn btn-primary">
                        Export CSV
                    </button>
                </div>
            </form>
            </div>
        </div>
    )

}

export default FilterForm