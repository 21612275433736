import {LOAD_ITEMS } from "./actions";

export const items = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_ITEMS:{
            return [...payload]
        }
        default:
            return state;
    }
}