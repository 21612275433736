import { CREATE_PERDIEM_RATE, LOAD_PERDIEM_RATES, LOAD_USER_PERDIEM_RATES } from "./actions";

export const perdiemRates = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case CREATE_PERDIEM_RATE:{
            const {perdiemRate} = payload;
            return [...state, perdiemRate]
        }
        case LOAD_PERDIEM_RATES:{
            return [...payload]
        }
        case LOAD_USER_PERDIEM_RATES:{
            return [...payload]
        }
        default:
            return state;
    }
}