import { LOAD_SERVICE_REQUESTS_FOR_PAYMENT, REMOVE_SERVICE_REQUEST} from "./actions";


export const serviceRequestsForPayment = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_SERVICE_REQUESTS_FOR_PAYMENT:{
            return [...payload]
        }
        case REMOVE_SERVICE_REQUEST: {
            return state.filter(serviceRequest => serviceRequest.id !== payload.id)
        }
        default:
            return state;
    }
}