
import { LOAD_CASH_REQUEST_APPROVALS } from './actions';



export const cashRequestApprovals = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_CASH_REQUEST_APPROVALS:
            return {...payload}
        default: 
            return state;
    }
}