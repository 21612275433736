import axiosInstance from "../../utils/axiosInstance"
import { createPerdiemWorkPlanAction, loadPerdiemWorkPlansAction } from "./actions";
import { loadingStartedAction, loadingEndedAction } from "../isLoading/actions";
import {NotificationManager} from "react-notifications";

export const loadPerdiemWorkPlans = (id) => (dispatch)=>{
  
  dispatch(loadingStartedAction());
   axiosInstance.get(`/perdiem_api/perdiem_workplan/${id}`).then(
       res => {
           if(res.status===200) {
           dispatch(loadPerdiemWorkPlansAction(res.data));
           }
           dispatch(loadingEndedAction())
       }
   )
   .catch((err) => {
    NotificationManager.error(
      "Something went wrong. If the problem persists please contact Soliton BI",
      `${err.response.status} Error`
    );
    dispatch(loadingEndedAction())
  });
}

export const createPerdiemWorkPlan = (perdiemWorkPlan, id)=>(dispatch)=>{
    dispatch(loadingStartedAction());
    axiosInstance
    .post(`/perdiem_api/manage_perdiem_workplan/${id}`, perdiemWorkPlan)
    .then((res) => {
      dispatch(createPerdiemWorkPlanAction(res.data));
      NotificationManager.success(
        `Added successfully`,
        "Perdiem Workplan"
      );
      dispatch(loadingEndedAction())
    })
    .catch((err) => {
      NotificationManager.error(
        "Something went wrong. If the problem persists please contact Soliton BI",
        `${err.response.status} Error`
      );
      dispatch(loadingEndedAction())
    });
  }
