import React from "react";
import { useSelector } from "react-redux";
import ServiceRequestsTable from "../tables/serviceRequestsTable";
import PendingServiceRequestsTable from "../tables/PendingServiceRequestsTable";
import {getApprovedUserServiceRequests,getPendingUserServiceRequests,getRejectedUserServiceRequests} from "../../../../store/serviceRequests/selectors";
import useFetching from '../../../../hooks/useFetching';
import { loadUserServiceRequests } from '../../../../store/serviceRequests/thunks';


const ServiceRequestTab = () => {
  useFetching(loadUserServiceRequests); 
  const pendingServiceRequests = useSelector(getPendingUserServiceRequests);
  const approvedServiceRequests = useSelector(getApprovedUserServiceRequests);
  const rejectedServiceRequests = useSelector(getRejectedUserServiceRequests);
  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link active show"
            id="pending-approval-tab"
            data-toggle="tab"
            href="#pending-approval"
            role="tab"
            aria-controls="pending-approval"
            aria-selected="true"
            style={{ color: "blue" }}
          >
            Pending Approval
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="profile-tab"
            data-toggle="tab"
            href="#profile"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            style={{ color: "green" }}
          >
            Approved
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="contact-tab"
            data-toggle="tab"
            href="#contact"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
            style={{ color: "red" }}
          >
            Rejected
          </a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade active show"
          id="pending-approval"
          role="tabpanel"
          aria-labelledby="pending-approval-tab"
        >
          {pendingServiceRequests.length > 0 ? (
            <PendingServiceRequestsTable serviceRequests={pendingServiceRequests} />
          ) : (
            <div>No Service Requests Pending Approval</div>
          )}
        </div>
        <div
          className="tab-pane fade"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          {approvedServiceRequests.length > 0 ? (
            <ServiceRequestsTable serviceRequests={approvedServiceRequests} />
          ) : (
            <div>No Approved Service Requests yet</div>
          )}
        </div>
        <div
          className="tab-pane fade"
          id="contact"
          role="tabpanel"
          aria-labelledby="contact-tab"
        >
          {rejectedServiceRequests.length > 0 ? (
            <ServiceRequestsTable serviceRequests={rejectedServiceRequests} />
          ) : (
            <div>No Rejected Service Requests Yet</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceRequestTab;
