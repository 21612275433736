import { useEffect } from "react";

export const useOutsideAlerter= (ref, setIsNavBarOpen) => {
    useEffect(() => {
      const handleClickOutside=(event)=> {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsNavBarOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, setIsNavBarOpen]);
  }