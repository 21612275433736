   

export const LOAD_SERVICE_REQUESTS_FOR_PAYMENT = "LOAD_SERVICE_REQUESTS_FOR_PAYMENt"
export const loadServiceRequestsForPaymentAction = serviceRequestsPagination => ({
   type: LOAD_SERVICE_REQUESTS_FOR_PAYMENT,
   payload: serviceRequestsPagination
})

export const REMOVE_SERVICE_REQUEST = "REMOVE_SERVICE_REQUEST"
export const removeServiceRequest = serviceRequest => ({
   type: REMOVE_SERVICE_REQUEST,
   payload: serviceRequest
})