import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ApproveItemRequestsTable from "./components/tables/ApproveItemRequestsTable";
import { getItemRequestsForApproval } from '../../store/itemRequestsForApproval/selectors';
import useFetching from '../../hooks/useFetching';
import { loadItemRequestsForApproval } from '../../store/itemRequestsForApproval/thunks';
import { loadProjects } from "../../store/projects/thunks";



const ApproveItemRequests = () => {
  useFetching(loadItemRequestsForApproval)
  useFetching(loadProjects);
  const itemRequests = useSelector(getItemRequestsForApproval);
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Approve Item Requests</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">Approve Item Requests</li>
        </ul>
      </div>
      <div className="card">
        <div className="card-body"> 
          {itemRequests && itemRequests.length>0?
          <ApproveItemRequestsTable itemRequests={itemRequests} />
        : <div>No Item Requests Pending Approval Yet</div>
        }
        </div>
      </div>
    </div>
  );
};

export default ApproveItemRequests;
