import React from "react";
import { useSelector } from "react-redux";
import PerdiemWorkPlanTable from '../components/tables/PerdiemWorkPlanTable'
import PerdiemWorkPlanForm from '../components/forms/perdiemWorkPlanForm'
import { useParams, } from "react-router-dom";
import { getPerdiemWorkPlans } from "../store/perdiemWorkPlan/selectors";
import { loadPerdiemWorkPlans } from "../store/perdiemWorkPlan/thunks";
import useFetching from "../hooks/useFetching";

const PerdiemWorkPlan = () => {
  const {id} = useParams();
  useFetching(loadPerdiemWorkPlans, id)
  
  const perdiemWorkPlans = useSelector(getPerdiemWorkPlans)
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Add Workplan</h5>
      </div>
      <PerdiemWorkPlanForm id={id} />
      <br />
      <h5 className="m-b-10">Plans</h5>
      {perdiemWorkPlans && <PerdiemWorkPlanTable perdiemWorkPlans={perdiemWorkPlans} />}
      
    </div>
  );
};

export default PerdiemWorkPlan;
