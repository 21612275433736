import { createSelector } from "reselect";

export const getUserCashRequests = (state) => state.cashRequests.results;
export const getUserCashRequestsCount = (state) => state.cashRequests.count;

export const getPendingUserCashRequests = createSelector(
  getUserCashRequests,
  (cashRequests) =>
    cashRequests.filter(
        (cashRequest) => cashRequest.ceo_approval === "Pending")
);

export const getApprovedUserCashRequests = createSelector(
  getUserCashRequests,
  (cashRequests) =>
    cashRequests.filter(
        (cashRequest) => cashRequest.ceo_approval === "Approved")
);

export const getRejectedUserCashRequests = createSelector(
  getUserCashRequests,
  (cashRequests) =>
    cashRequests.filter(
        (cashRequest) => cashRequest.ceo_approval === "Rejected")
);
