import { CREATE_PERDIEM_REQUEST, LOAD_USER_PERDIEM_REQUESTS } from "./actions";

export const perdiemRequests = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case CREATE_PERDIEM_REQUEST:{
            const {perdiemRequest} = payload;
            return [...state, perdiemRequest]
        }
        case LOAD_USER_PERDIEM_REQUESTS:{
            return [...payload]
        }
        default:
            return state;
    }
}