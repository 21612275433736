export const LOAD_SERVICE_REQUEST_DETAIL = "LOAD_SERVICE_REQUEST_DETAIL"
export const loadServiceRequestDetailAction = serviceRequest => ({
    type: LOAD_SERVICE_REQUEST_DETAIL,
    payload: serviceRequest})


export const REMOVE_SERVICE_REQUEST_DETAIL = "REMOVE_SERVICE_REQUEST_DETAIL"
export const removeServiceRequestDetailAction = serviceRequest => ({
    type: LOAD_SERVICE_REQUEST_DETAIL,
    payload: {}
})

