import React from 'react'
import Pagination from '../../../../components/Pagination';
import { loadPaidServiceRequests } from '../../../../store/paidServiceRequests/thunks';
import useFetching from '../../../../hooks/useFetching';
import { useSelector } from 'react-redux';
import { getPaidServiceRequests } from '../../../../store/paidServiceRequests/selectors';
import { useDispatch } from 'react-redux';
import { downloadServiceRequestPDF } from '../../../../store/serviceRequestsForPayment/thunks';
import { Link } from 'react-router-dom';

const PaidServiceRequestsTable = () => {
  useFetching(loadPaidServiceRequests);
  const serviceRequests = useSelector(getPaidServiceRequests);
  const dispatch = useDispatch();
  const resultsCount = useSelector(
    (state) => state.paidServiceRequests.count
  );
  const handlePaginationChange = (e) => {
    dispatch(loadPaidServiceRequests(e.selected));
  };

  const handleExportPDF = (serviceRequest) => {
    dispatch(downloadServiceRequestPDF(serviceRequest));
  };
  return serviceRequests?(
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Beneficiary</th>
            <th>Phone Number</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {serviceRequests.map((serviceRequest, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{serviceRequest.id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(serviceRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>{serviceRequest.name}</td>
              <td>{serviceRequest.phone_number}</td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB").format(parseInt(serviceRequest.amount))}
                </h6>
              </td>
              <td>
                <Link
                  onClick={() => {
                    handleExportPDF(serviceRequest);
                  }}
                  className="label theme-bg text-white f-12"
                >
                  Export PDF
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        resultsCount={resultsCount}
        handlePageClick={handlePaginationChange}
      />
    </div>
  ):(<div>No service requests Available</div>);
}

export default PaidServiceRequestsTable