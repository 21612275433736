import React from 'react'
import Pagination from '../../components/Pagination';
import { loadPaidPerdiemRequests } from '../../store/paidPerdiemRequests/thunks';
import useFetching from '../../hooks/useFetching';
import { useSelector } from 'react-redux';
import { getPaidPerdiemRequests } from '../../store/paidPerdiemRequests/selectors';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { downloadPerdiemRequestPDF } from '../../store/perdiemRequestsForPayment/thunks';

const PaidPerdiemRequestsTable = () => {
  useFetching(loadPaidPerdiemRequests);
  const perdiemRequests = useSelector(getPaidPerdiemRequests);
  const dispatch = useDispatch();
  const resultsCount = useSelector(
    (state) => state.paidPerdiemRequests.count
  );
  const handlePaginationChange = (e) => {
    dispatch(loadPaidPerdiemRequests(e.selected));
  };

  const handleExportPDF = (perdiemRequest) => {
    dispatch(downloadPerdiemRequestPDF(perdiemRequest));
  };
  return perdiemRequests?(
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Beneficiary</th>
            <th>Phone Number</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {perdiemRequests.map((perdiemRequest, key) => (
            <tr key={key}>
              <td>
                <h6 className="m-0">{perdiemRequest.id}</h6>
              </td>
              <td>
                <h6 className="m-0">
                  {new Date(perdiemRequest.date).toLocaleDateString("en-UK")}
                </h6>
              </td>
              <td>{perdiemRequest.name}</td>
              <td>{perdiemRequest.phone_number}</td>
              <td>
                <h6 className="m-0">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: perdiemRequest.currency,
                  }).format(parseInt(perdiemRequest.total_amount))}
                </h6>
              </td>
              <td>
                <Link
                  onClick={() => {
                    handleExportPDF(perdiemRequest);
                  }}
                  className="label theme-bg text-white f-12"
                >
                  Export PDF
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        resultsCount={resultsCount}
        handlePageClick={handlePaginationChange}
      />
    </div>
  ):(<div>No perdiem requests Available</div>);
}

export default PaidPerdiemRequestsTable