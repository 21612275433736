import React, { Suspense } from "react";
import NavTabs from "../../components/tabs/NavTabs";
import NavTab from "../../components/tabs/NavTab";
import TabContent from "../../components/tabs/TabContent";
import TabPane from "../../components/tabs/TabPane";
import PayPerdiemRequestsTable from '../tables/PayPerdiemRequestsTable';
import PaidPerdiemRequestsTable from '../tables/PaidPerdiemRequestsTable';
import FilterForm from '../forms/FilterForm.jsx'

const PerdiemRequestsForPaymentTab = () => {
  return (
    <>
      <NavTabs>
        <NavTab name={"pending-payment"} color="blue" show={true}>
          Pending Payment
        </NavTab>
        <NavTab name={"paid"} color="green">
          Paid
        </NavTab>
      </NavTabs>
      <TabContent>
        <TabPane name={"pending-payment"} show={true}>
           <Suspense fallback={<div>Loading Perdiem Requests ...</div>}>
              <PayPerdiemRequestsTable/>
          </Suspense>
        </TabPane>
        <TabPane name={"paid"}>
            <FilterForm />
           <Suspense fallback={<div>Loading Perdiem Requests ...</div>}>
              <PaidPerdiemRequestsTable/>
          </Suspense>
        </TabPane>
      </TabContent>
    </>
  );
};

export default PerdiemRequestsForPaymentTab;
