import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DispatchItemRequestsTable from "./components/tables/DispatchItemRequestsTable";
import useFetching from '../../hooks/useFetching';
import { loadItemRequestsForDispatch } from '../../store/itemRequestsForDispatch/thunks';
import { getUser } from '../../store/user/selectors';


const DispatchItemRequests = () => {
  const user = useSelector(getUser)
  useFetching(loadItemRequestsForDispatch, user)
  const itemRequests = useSelector((state) => state.itemRequestsForDispatch);
  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Dispatch Item Requests</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">Dispatch Items</li>
        </ul>
      </div>
      <div className="card">
        <div className="card-body"> 
          {itemRequests.length>0?
              <DispatchItemRequestsTable itemRequests={itemRequests} />
        : <div>No Item Requests Pending Dispatch Yet</div>
        }
        </div>
      </div>
    </div>
  );
};

export default DispatchItemRequests;
