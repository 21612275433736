import React from "react";

const ApprovedIcon = () => {
  return (
    <span className="pcoded-micon">
      <i className="m-0 text-c-green feather icon-check"></i>
    </span>
  );
};

export default ApprovedIcon;
