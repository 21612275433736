import React from "react";
import { Link } from "react-router-dom";
import ItemRequestForm from "./components/forms/ItemRequestForm"
import useFetching from '../../hooks/useFetching';
import { loadProjects } from '../../store/projects/thunks';
import { loadGMUsers } from '../../store/GMusers/thunks';


const NewItemRequest = () => {
  useFetching(loadProjects);
  useFetching(loadGMUsers);
  return (
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="page-header-title">
        <h5 className="m-b-10">Create New Item Request</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">Create New Item Request</li>
        </ul>
      </div>
        <div className="card">
          <div className="card-body">
            <ItemRequestForm/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewItemRequest;
