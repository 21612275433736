import axiosInstance from "../../utils/axiosInstance";
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import { clearNotificationsAction, loadNotificationsAction } from "./actions";

export const loadNotifications = () => (dispatch) => {
  dispatch(loadingStartedAction())
  axiosInstance.get("/accounts/unread_notifications").then((res) => {
    if (res.status === 200) dispatch(loadNotificationsAction(res.data));
    dispatch(loadingEndedAction())
  });
};

export const clearNotifications = () => (dispatch) => {
  axiosInstance.post("/accounts/clear_notifications").then((res) => {
    if (res.status === 200) dispatch(clearNotificationsAction());
  });
};
