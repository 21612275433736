import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGMUsers } from "../../../../store/GMusers/selectors";
import { getProjects } from "../../../../store/projects/selectors";
import { createItemRequest } from "../../../../store/itemRequests/thunks";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import SelectItemToRequestField from "./SelectItemToRequestField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CurrencyInput from "react-currency-input-field";
import { currencies } from "../../../../data/currency";

const ItemRequestForm = ({
  itemRequest = {
    item_purchases: [],
  },
}) => {
  const GMusers = useSelector(getGMUsers);
  const projects = useSelector(getProjects);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    unregister,
  } = useForm({
    defaultValues: itemRequest,
  });
  const [itemPurchasesIndices, setItemPurchasesIndices] = useState(
    Object.keys(itemRequest.item_purchases)
  );

  const addPurchaseItem = () => {
    setItemPurchasesIndices([
      ...itemPurchasesIndices,
      itemPurchasesIndices.length,
    ]);
  };

  const removePurchaseItem = (idx) => {
    setItemPurchasesIndices((current) =>
      current.filter((itemPurchaseIndex) => itemPurchaseIndex !== idx)
    );
    unregister(`item_purchases[${idx}]`);
  };

  const history = useHistory();

  const optionChange = (itemOption) => {
    setValue(`item_purchases[${itemOption.id}][item]`, itemOption.value, {
      shouldValidate: true,
    });
    setValue(`item_purchases[${itemOption.id}][price]`, itemOption.price, {
      shouldValidate: true,
    });
  };

  const setPriceEach = (value, name) => {
    setValue(name, value, {
      shouldValidate: true,
    });
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onSubmit = async (data) => {
    const file = data.evidence[0];
    file ? (data["evidence"] = await getBase64(file)) : delete data["evidence"];
    console.log("The data going into the form", data);
    dispatch(createItemRequest(data, history));
  };

  return (
    <Form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="form-group col-md-6">
        <Form.Label>Project</Form.Label>
        <Form.Control as="select" {...register("project")}>
          <option value="">-- SELECT PROJECT ---</option>
          {projects.length > 0 &&
            projects.map((item, key) => (
              <option value={item.id} key={key}>
                {item.name}
              </option>
            ))}
        </Form.Control>
      </Form.Group>

      <Form.Group className="form-group col-md-4">
        <Form.Label>Segment</Form.Label>
        <Form.Control
          placeholder="Enter Segment"
          {...register("segment", {
            maxLength: {
              value: 15,
              message: "Too Many Characters",
            },
          })}
        />
        {errors.segment && (
          <small className="error">{errors.segment.message}</small>
        )}
      </Form.Group>
      <Form.Group className="form-group col-md-2">
        <Form.Label>Currency</Form.Label>
        <Form.Control
          as="select"
          placeholder="Enter Currency"
          {...register("currency", {
            required: "Please provide currerncy",
          })}
        >
          {currencies.map((currency) => (
            <option key={currency}>{currency}</option>
          ))}
        </Form.Control>
      </Form.Group>

      <Form.Group className="form-group col-md-6">
        <Form.Label>Evidence</Form.Label>
        <Form.Control
          class="form-control"
          accept=".jpg,.pdf,.png, .jpeg"
          type="file"
          {...register("evidence")}
        ></Form.Control>
      </Form.Group>
      <Form.Group className="form-group col-md-6">
        <Form.Label>Business Unit GM</Form.Label>
        <Form.Control
          as="select"
          className="form-control"
          {...register("business_unit_gm", { required: true })}
        >
          <option value="">-- SELECT GM ---</option>
          {GMusers &&
            GMusers.map((item, key) => (
              <option value={item.id} key={key}>
                {item.first_name} {item.last_name}
              </option>
            ))}
        </Form.Control>
        {errors.business_unit_gm && (
          <small className="error">Please select your business unit GM</small>
        )}
      </Form.Group>
      <div className="form-group col-md-12">
        <label>Purpose</label>
        <textarea
          className="form-control"
          placeholder="Enter purpose"
          {...register("purpose", { required: true })}
        ></textarea>
        {errors.purpose && (
          <small className="error">Please enter the purpose</small>
        )}
      </div>
      {itemPurchasesIndices.map((itemPurchasesIndex) => (
        <React.Fragment key={itemPurchasesIndex}>
          <SelectItemToRequestField
            optionChange={optionChange}
            id={itemPurchasesIndex}
          />
          <Form.Group className="form-group col-md-2">
            <Form.Label>Quantity</Form.Label>
            <Form.Control
              {...register(`item_purchases[${itemPurchasesIndex}][quantity]`, {
                value: "0",
                required: true,
                valueAsNumber: true,
              })}
            ></Form.Control>
          </Form.Group>
          <Form.Group className="form-group col-md-3">
            <Form.Label>Unit Price</Form.Label>
            <CurrencyInput
              className="form-control"
              {...register(
                `item_purchases[${itemPurchasesIndex}][price_each]`,
                {
                  required: true,
                }
              )}
              onValueChange={setPriceEach}
            ></CurrencyInput>
          </Form.Group>

          <Form.Group className="col-md-1 d-flex align-items-bottom">
            <Button
              variant="outline-danger"
              onClick={() => removePurchaseItem(itemPurchasesIndex)}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="lg" />
            </Button>
          </Form.Group>
        </React.Fragment>
      ))}

      <Form.Group className="col-md-12">
        <Button variant="outline-secondary" onClick={addPurchaseItem}>
          Add Item
        </Button>{" "}
        <Button type="submit">Submit</Button>
      </Form.Group>
    </Form>
  );
};

export default ItemRequestForm;
