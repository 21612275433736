
import { LOAD_SERVICE_REQUEST_APPROVALS } from './actions';



export const serviceRequestApprovals = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_SERVICE_REQUEST_APPROVALS:
            return {...payload}
        default: 
            return state;
    }
}