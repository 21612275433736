import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadCashRequestsForApproval } from "../../store/cashRequestsForApproval/thunks";
import ApproveCashRequestsTable from "./components/tables/ApproveCashRequestsTable";
import useFetching from "../../hooks/useFetching";
import { loadProjects } from "../../store/projects/thunks";

const ApproveCashRequests = () => {
  const cashRequests = useSelector(
    (state) => state.cashRequestsForApproval.results
  );
  const resultsCount = useSelector(
    (state) => state.cashRequestsForApproval.count
  );
  useFetching(loadCashRequestsForApproval);
  useFetching(loadProjects);

  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Approve Cash Requests</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">Approve Cash Requests</li>
        </ul>
      </div>
      <div className="card">
        <div className="card-body">
          <div class="float-right">
            <Link to="/cashrequest_approval_history">
            <button type="button" class="btn btn-outline-info">
              View Approval History
            </button>
            </Link>
          </div>
          <br></br>

          {cashRequests && cashRequests.length > 0 ? (
            <ApproveCashRequestsTable
              cashRequests={cashRequests}
              resultsCount={resultsCount}
            />
          ) : (
            <div>No Cash Requests Pending Approval Yet</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApproveCashRequests;
