import axiosInstance from "../../utils/axiosInstance"
import { createPerdiemRateAction, loadPerdiemRatesAction, loadUserPerdiemRatesAction } from "./actions";
import { loadingStartedAction, loadingEndedAction } from "../isLoading/actions";
import {NotificationManager} from "react-notifications";

export const loadPerdiemRates = () => (dispatch)=>{
   axiosInstance.get("/perdiem_api/all_perdiem_rates").then(
       res => {
           if(res.status===200) dispatch(loadPerdiemRatesAction(res.data));
       }
   )
}

export const loadUserPerdiemRates = () => (dispatch)=>{
  axiosInstance.get("/perdiem_api/user_perdiem_rates").then(
      res => {
          if(res.status===200) dispatch(loadUserPerdiemRatesAction(res.data));
      }
  )
}

export const createPerdiemRate = (perdiemRate)=>(dispatch)=>{
    dispatch(loadingStartedAction());
    axiosInstance
    .post("/perdiem_api/create_perdiem_rate", perdiemRate)
    .then((res) => {
      dispatch(createPerdiemRateAction(res.data));
      NotificationManager.success(
        `Perdiem Rate successfully created`,
        "Perdiem Rate Created"
      );
      dispatch(loadingEndedAction())
    })
    .catch((err) => {
      NotificationManager.error(
        "Something went wrong. If the problem persists please contact Soliton BI",
        `${err.response.status} Error`
      );
      dispatch(loadingEndedAction())
    });
  }
