import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getItemRequestDetail } from "../../store/itemRequestDetail/selectors";
import {
  loadItemRequestDetail,
  removeItemRequestDetail,
} from "../../store/itemRequestDetail/thunks";
import CardWithLoadingSpinner from "../../components/CardWithLoadingSpinner";
import ApprovalForm from "../../components/forms/ApprovalForm";
import { approveItemRequestThunk } from "../../store/itemRequestsForApproval/thunks";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ApproveItemRequest = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const itemRequest = useSelector(getItemRequestDetail);
  const history = useHistory()

  const handleApprove = (data) => {
    dispatch(approveItemRequestThunk(itemRequest, data));
    history.push("/approve_item_requests");
  };

  useEffect(() => {
    dispatch(loadItemRequestDetail(id));
    return dispatch(removeItemRequestDetail());
  }, [id, dispatch]);

  return (
    <div className="col-xl-12 col-md-12 m-b-30">
      <div className="page-header-title">
        <h5 className="m-b-10">Approve Item Request</h5>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="feather icon-home"></i>
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/approve_Item_requests">Approve Item Requests</Link>
          </li>
          <li className="breadcrumb-item">Approve Item Request</li>
        </ul>
      </div>
      {itemRequest && itemRequest.id ? (
        <div className="card">
          <div className="card-header">
            <h5>Item Request</h5>
          </div>
          <div className="card-block">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="feather icon-user f-30 text-c-green"></i>
              </div>
              <div className="col">
                <h3 className="f-w-300">{itemRequest.name}</h3>
                <span className="d-block text-uppercase">Beneficiary</span>
              </div>
              <div className="col-auto">
                <i className="feather icon-credit-card f-30 text-c-green"></i>
              </div>
              <div className="col">
                <h3 className="f-w-300">
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: itemRequest.currency,
                  }).format(parseInt(itemRequest.amount))}
                </h3>
                <span className="d-block text-uppercase">Amount</span>
              </div>
            </div>
          </div>
          <div className="card-block border-bottom">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="feather icon-clipboard f-30 text-c-green"></i>
              </div>
              <div className="col">
                <h4 className="f-w-300">{itemRequest.designation}</h4>
                <span className="d-block text-uppercase">Designation</span>
              </div>
              <div className="col-auto">
                <i className="f-30 text-c-green fa fa-briefcase"></i>
              </div>
              <div className="col">
                <h4 className="f-w-300">{itemRequest.project_name || "N/A"}</h4>
                <span className="d-block text-uppercase">Project</span>
              </div>
              <div className="col">
                <h4 className="f-w-300">{itemRequest.segment || "N/A"}</h4>
                <span className="d-block text-uppercase">Segment</span>
              </div>
            </div>
          </div>
          {itemRequest.item_purchases && (
            <div className="card-block border-bottom">
              <div className="row d-flex align-items-center">
                <div className="col-auto">
                  <i className="feather icon-layers f-30 text-c-green"></i>
                </div>
                <div className="col">
                  <ul>
                    {itemRequest.item_purchases.map((item_purchase) => (
                      <li className="f-w-300">{item_purchase.item_name}</li>
                    ))}
                  </ul>
                  <span className="d-block text-uppercase">Items</span>
                </div>
              </div>
            </div>
          )}
          <div className="card-block border-bottom">
            <div className="row d-flex align-items-center">
              <div className="col-auto">
                <i className="feather icon-align-justify f-30 text-c-green"></i>
              </div>
              <div className="col">
                <h6 className="f-w-300">{itemRequest.purpose}</h6>
                <span className="d-block text-uppercase">Purpose</span>
              </div>
              {itemRequest.evidence && (
                <>
                  <div className="col-auto">
                    <i className="f-30 text-c-green fa fa-file"></i>
                  </div>
                  <div className="col">
                    <h6 className="f-w-200">
                      <a
                        href={itemRequest.evidence}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view.
                      </a>
                    </h6>
                    <span className="d-block text-uppercase">
                      Evidence File
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="card-block">
            <ApprovalForm request={itemRequest} handleApprove={handleApprove} />
          </div>
        </div>
      ) : (
        <CardWithLoadingSpinner />
      )}
    </div>
  );
};

export default ApproveItemRequest;
