import {
    LOAD_ITEM_REQUESTS_FOR_DISPATCH,
    REMOVE_ITEM_REQUEST
} from "./actions";


export const itemRequestsForDispatch = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_ITEM_REQUESTS_FOR_DISPATCH:{
            return [...payload]
        }
        case REMOVE_ITEM_REQUEST: {
            return state.filter(itemRequest => itemRequest.id !== payload.id)
        }
        default:
            return state;
    }
}