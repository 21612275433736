

export const LOAD_ITEM_REQUESTS_FOR_APPROVAL = "LOAD_ITEM_REQUESTS_FOR_APPROVAL"
export const loadItemRequestsForApprovalAction = itemRequestsPagination => ({
   type: LOAD_ITEM_REQUESTS_FOR_APPROVAL,
   payload: itemRequestsPagination
})

export const REMOVE_ITEM_REQUEST_FOR_APPROVAL = "REMOVE_ITEM_REQUEST_FOR_APPROVAL"
export const removeItemRequestForApproval = itemRequest => ({
   type: REMOVE_ITEM_REQUEST_FOR_APPROVAL,
   payload: itemRequest
})