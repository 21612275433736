   

export const LOAD_CASH_REQUESTS_FOR_PAYMENT = "LOAD_CASH_REQUESTS_FOR_PAYMENt"
export const loadCashRequestsForPaymentAction = cashRequestsPagination => ({
   type: LOAD_CASH_REQUESTS_FOR_PAYMENT,
   payload: cashRequestsPagination
})

export const REMOVE_CASH_REQUEST = "REMOVE_CASH_REQUEST"
export const removeCashRequest = cashRequest => ({
   type: REMOVE_CASH_REQUEST,
   payload: cashRequest
})