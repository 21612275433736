import React from "react";

const ThemeLoader = ({ display }) => {
  return display ? (
    <div className="loader-bg">
      <div className="loader-track">
        <div className="loader-fill"></div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ThemeLoader;
