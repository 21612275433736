import { NotificationManager } from "react-notifications";
import axiosInstance from "../../utils/axiosInstance";
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";

export const createFinancialInformation = (financialInformation, id)=>(dispatch)=>{
  dispatch(loadingStartedAction());
  axiosInstance
  .patch(`/api/mark_cash_request_as_paid/${id}`, financialInformation)
  .then((res) => {
    NotificationManager.success(
      `Financial information added successfully`,
      "Financial Information Added"
    );
    dispatch(loadingEndedAction())
  })
  .catch((err) => {
    console.log("The err is ", err.response.data)
    NotificationManager.error(
      "Something went wrong. If the problem persists please contact Soliton BI",
      `${err.response.status} Error`
    );
    dispatch(loadingEndedAction())
  });
}