import { LOAD_WORKERS } from "./actions";

export const workers = (state=[],action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_WORKERS:{
            return [...payload]
        }
        default:
            return state;
    }
}