import axios from "axios";
import jwt_decode from "jwt-decode";
import { NotificationManager } from "react-notifications";

const baseURL = process.env.REACT_APP_BASE_URL;
const refreshTokenURL = `${baseURL}/accounts/refresh_token`;
const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(async (req) => {
  const token = localStorage.getItem("token");
  if (!token) {
    req.headers = {};
    return req;
  }
  req.headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json; charset=UTF-8",
  };
  const user = jwt_decode(token);
  const isExpired = user.exp <= Date.now() / 1000;
  if (!isExpired) return req;
  const refreshToken = localStorage.getItem("refreshToken");
  const { data } = await axios.post(refreshTokenURL, {
    refreshToken,
  });

  const { access_token } = data;
  localStorage.setItem("token", access_token);
  return req;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if (err.response && err.response.status === 403) {
      if (originalRequest.url === refreshTokenURL) {
        localStorage.clear();
        window.location.reload();
      } else if (originalRequest.triedCount === 1) {
        localStorage.clear();
        window.location.reload();
        return Promise.reject(err);
      } else {
        originalRequest.triedCount = 1;
        return axiosInstance(originalRequest);
      }
    } else {
      try {
        if (err.response) {
          const { status, statusText, data } = err.response;
          NotificationManager.error(
            `Error ${status}`,
            `Request failed: ${statusText}`
          );
          if (status === 400 && data) {
            Object.entries(data).forEach(([key, value]) => {
              NotificationManager.error(`Error in ${key}: ${value}`, "Validation Error");
            });
          } else if (status === 404) {
            NotificationManager.error("Resource Not Found", "404 Error");
          } else {
            NotificationManager.error("An unexpected error occurred", "Error");
          }
        } else {
          NotificationManager.error("Network Error", "Failed to connect to server");
        }
        console.error("Error:", err);
      } catch (error) {
        NotificationManager.error("An unexpected error occurred", "Error");
        console.error("Error:", error);
      }

      return Promise.reject(err);
    }
  }
);

export default axiosInstance;

