import axiosInstance from "../../utils/axiosInstance";
import { loadingEndedAction, loadingStartedAction } from "../isLoading/actions";
import { loadServiceRequestsForPaymentAction, removeServiceRequest } from "./actions";
import { NotificationManager } from "react-notifications";
import { concatenatePageToUri } from "../../utils/concatenatePageToUri";
import { saveAs } from "file-saver";



export const loadServiceRequestsForPayment =
  (page = 0) =>
  (dispatch) => {
    const endpoint = concatenatePageToUri(
      "/perdiem_api/service_requests_for_payment",
      page
    );
    dispatch(loadingStartedAction());
    axiosInstance
      .get(endpoint)
      .then((res) => {
        if (res.status === 200){
          console.log("This is the data", res.data);
          dispatch(loadServiceRequestsForPaymentAction(res.data));
        }
        dispatch(loadingEndedAction());
      })
      .catch((err) => {
        dispatch(loadingEndedAction());
      });
  };

export const downloadServiceRequestPDF = (serviceRequest) => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance
    .get(`perdiem_api/service_request_pdf/${serviceRequest.id}`)
    .then((res) => {
      if (res.status === 200) {
        const {baseURL, url} = res.config
        const URL = baseURL+"/"+ url
           saveAs(
            URL,
           `service_request_${serviceRequest.id}.pdf`
    );
        NotificationManager.success(
          `Successfully downloaded service request ID ${serviceRequest.id} pdf for ${serviceRequest.name}`
        );
      }
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      dispatch(loadingEndedAction());
    });
};

export const payServiceRequest = (serviceRequest) => (dispatch) => {
  dispatch(loadingStartedAction());
  axiosInstance
    .get(`perdiem_api/pay_service_request/${serviceRequest.id}`)
    .then((res) => {
      NotificationManager.success(
        `Successfully paid the service request from ${serviceRequest.name}`
      );
      dispatch(removeServiceRequest(serviceRequest));
      dispatch(loadingEndedAction());
    })
    .catch((err) => {
      NotificationManager.error("Could not indicate service request as paid");
      dispatch(loadingEndedAction());
    });
};
