export const CREATE_ITEM_REQUEST = 'CREATE_ITEM_REQUEST';
export const createItemRequestAction = itemRequest =>({
    type: CREATE_ITEM_REQUEST,
    payload: {itemRequest}
});

export const LOAD_USER_ITEM_REQUESTS = "LOAD_USER_ITEM_REQUESTS"
export const loadUserItemRequestsAction = itemRequestsPagination => ({
   type: LOAD_USER_ITEM_REQUESTS,
   payload: itemRequestsPagination
})

