

export const LOAD_PERDIEM_REQUESTS_FOR_APPROVAL = "LOAD_PERDIEM_REQUESTS_FOR_APPROVAL"
export const loadPerdiemRequestsForApprovalAction = perdiemRequestsPagination => ({
   type: LOAD_PERDIEM_REQUESTS_FOR_APPROVAL,
   payload: perdiemRequestsPagination
})

export const REMOVE_PERDIEM_REQUEST_FOR_APPROVAL = "REMOVE_PERDIEM_REQUEST_FOR_APPROVAL"
export const removePerdiemRequest = perdiemRequest => ({
   type: REMOVE_PERDIEM_REQUEST_FOR_APPROVAL,
   payload: perdiemRequest
})