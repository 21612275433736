import {  LOAD_PAID_CASH_REQUESTS } from "./actions";

export const paidCashRequests = (state={},action)=>{
    const {type,payload}=action;
    switch (type) {
        case LOAD_PAID_CASH_REQUESTS:{
            return {...payload}
        }
        default:
            return state;
    }
}