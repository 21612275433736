import axios from "axios";
import React, { useEffect, useState } from "react";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import OutsideLayout from "../layouts/OutsideLayout";
import Loader from "react-promise-loader";
import { NotificationManager } from "react-notifications";

const Login = () => {
  const [oauthURL, setOauthURL] = useState("");

  useEffect(() => {
    const getUrl = async () =>
      await axios.get(`${process.env.REACT_APP_BASE_URL}/accounts/url`)

     trackPromise(getUrl()).then(({ data }) => {
      const { url } = data
      setOauthURL(url);
    }).catch(
      err=> NotificationManager.error("Failed to connect", "Error")
    )
  }, []);

  return (
    <OutsideLayout>
      <div className="card">
        <div className="card-body text-center">
          <div className="mb-4">
            <img src="assets/images/logo.png" 
            height={80}
            alt="logo"/>
          </div>
          <h3 className="mb-4">{process.env.REACT_APP_NAME}</h3>
          { oauthURL?
            <button
              disabled={!oauthURL}
              className="btn btn-primary shadow-2 mb-4"
              onClick={() => (window.location.href = oauthURL)}
            >
              Login With Bakozi
            </button>:
            <p>Connecting...</p>
    
}
        </div>
      </div>
      <Loader promiseTracker={usePromiseTracker} />
    </OutsideLayout>
  );
};

export default Login;
