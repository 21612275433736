import React from "react";
import { useDispatch } from "react-redux";
import {Controller, useForm } from "react-hook-form";
import {
  NotificationContainer,
} from "react-notifications";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import useFetching from "../../hooks/useFetching";
import { loadUserPerdiemRequests } from "../../store/perdiemRequests/thunks";
import { createPerdiemWorkPlan } from "../../store/perdiemWorkPlan/thunks";


const PerdiemWorkPlanForm = ({id}) => {
  const dispatch = useDispatch()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  useFetching(loadUserPerdiemRequests);  
  
      
  const onSubmit = (formData) => {
    dispatch(createPerdiemWorkPlan(formData, id))
    history.push(`/perdiem_workplan/${id}`);
    // axiosInstance
    //   .post(`/perdiem_api/manage_perdiem_workplan/${id}`, formData)
    //   .then((res) => {
    //     history.push(`/perdiem_workplan/${id}`);
    //     NotificationManager.success(
    //       "Perdiem Workplan Successfully created",
    //       "Perdiem Workplan Created"
    //     );
    //   })
    //   .catch((err) => {
    //     NotificationManager.error(
    //       "Something went wrong. If the problem persists please re-login and try again",
    //       `${err.response.status} Error`
    //     );
    //   });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="row g-3">        
      
      <div className="form-group col-md-6">
        <label>Date</label> 
               
        <Controller
          control={control}
          name='date'
          render={({ field }) => (
            <DatePicker 
              className="form-control"
              placeholder="Select Date"          
              selected={field.value} 
              dateFormat="yyyy-MM-dd"
              onChange={(date) => field.onChange(date)} 
            />
          )}
        />
        {errors.date && (
          <small className="error">Please select Activity date</small>
        )}
      </div>
      <div className="form-group col-md-6">
        <label>Activity</label>
        <input
          className="form-control"
          placeholder="Activity Description"
          {...register("activity", { required: true })}
        />
        {errors.activity && (
          <small className="error">Please enter the Activity</small>
        )}
      </div>
      <div className="form-group col-md-12">
        <label>Remarks</label>
        <textarea
          className="form-control"
          placeholder="Enter Remarks"
          {...register("remarks", { required: true })}
        ></textarea>
        {errors.remarks && (
          <small className="error">Please enter a Remark</small>
        )}
      </div>
          
      <div className="col-md-12">
        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </div>
      <NotificationContainer />
    </form>
  );
};



export default PerdiemWorkPlanForm;
